import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';

import DateRangePicker, {
  DateRangePickerDateSelectFunction,
  NullableDate,
} from '~components/Filters/DateRangePicker';
import { useStores } from '~store';

import { NewDispatchView } from '../NewDispatch';

interface SharedDatesFilterProps {
  view: `${Exclude<NewDispatchView, NewDispatchView.CALENDAR>}`;
}

const SharedDatesFilter = observer(({ view }: SharedDatesFilterProps) => {
  const { ordersDispatchStore, driverSchedulerStore } = useStores();

  const isOrdersView = view === 'orders';
  const [initialStartDate, initialEndDate]: NullableDate[] = useMemo(() => {
    if (isOrdersView) {
      const dateRange = [
        ordersDispatchStore.filters.startDate
          ? dayjs(ordersDispatchStore.filters.startDate)
          : null,
        ordersDispatchStore.filters.endDate
          ? dayjs(ordersDispatchStore.filters.endDate)
          : null,
      ];

      return dateRange;
    }

    if (driverSchedulerStore.dateFilters.startDate) {
      const dateRange = [
        dayjs(driverSchedulerStore.dateFilters.startDate),
        dayjs(driverSchedulerStore.dateFilters.startDate),
      ];

      return dateRange;
    }

    const storedFilters = ordersDispatchStore.retrieveStoredFilters();

    if (storedFilters.startDate) {
      const date = dayjs(storedFilters.startDate);
      const formattedDate = date.format('YYYY-MM-DD');
      const dateRage = [date, date];

      driverSchedulerStore.setDateFilters({
        startDate: formattedDate,
        endDate: formattedDate,
      });

      return dateRage;
    }

    const date = dayjs();
    const formattedDate = date.format('YYYY-MM-DD');
    const dateRange = [date, date];

    driverSchedulerStore.setDateFilters({
      startDate: formattedDate,
      endDate: formattedDate,
    });

    return dateRange;
  }, [
    isOrdersView,
    ordersDispatchStore.filters.startDate,
    ordersDispatchStore.filters.endDate,
    driverSchedulerStore.dateFilters.startDate,
  ]);

  const [dateRange, setDateRange] = useState({
    start: initialStartDate,
    end: initialEndDate,
  });

  const handleDateFilterChange = useCallback<DateRangePickerDateSelectFunction>(
    (startDate, endDate) => {
      const start = startDate;
      const end = endDate ?? startDate;

      setDateRange({ start, end });
    },
    [],
  );

  const handleDateFilterClose = useCallback(() => {
    const startDate = dateRange.start?.format('YYYY-MM-DD');
    const endDate = dateRange.end?.format('YYYY-MM-DD');

    let startDateDidChange = false;
    let endDateDidChange = false;

    if (isOrdersView) {
      startDateDidChange = ordersDispatchStore.filters.startDate !== startDate;
      endDateDidChange = ordersDispatchStore.filters.endDate !== endDate;
    } else {
      startDateDidChange = driverSchedulerStore.dateFilters.startDate !== startDate;
    }

    if (startDateDidChange || endDateDidChange) {
      ordersDispatchStore.setFilters({ startDate, endDate }, true);
      const driverStartDate = startDate ?? dayjs().format('YYYY-MM-DD');
      driverSchedulerStore.setDateFilters({
        startDate: driverStartDate,
        endDate: driverStartDate,
      });
    }
  }, [
    dateRange.start,
    dateRange.end,
    ordersDispatchStore.filters.startDate,
    ordersDispatchStore.filters.endDate,
    driverSchedulerStore.dateFilters.startDate,
  ]);

  return (
    <DateRangePicker
      allowAllDates={isOrdersView}
      endDate={dateRange.end}
      onAnchorClose={handleDateFilterClose}
      onDateSelect={handleDateFilterChange}
      showShortcuts
      startDate={dateRange.start}
      variant={isOrdersView ? 'date_range' : 'single_date'}
    />
  );
});

export default SharedDatesFilter;
