import {
  getV1CompaniesCompanyIdOrdersDispatch,
  GetV1CompaniesCompanyIdOrdersDispatchData,
  getV1OrdersDispatch,
  GetV1OrdersDispatchData,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';

import connection from '~services/connectionModule';
import { extractPagination } from '~services/pagination';
import { useStores } from '~store';

import { Order } from '../useOrders';

export const useCalendarDispatch = () => {
  const { calendarDispatchStore } = useStores();

  const getCompanyOrders = async () => {
    calendarDispatchStore.ordersFetchStart();

    const { pagination, filters } = calendarDispatchStore;

    const query: GetV1OrdersDispatchData['query'] = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      query['page[before]'] = pagination.before;
    } else if (pagination.after) {
      query['page[after]'] = pagination.after;
    }

    if (filters?.searchName?.trim().length) {
      query['search[job][datagrid]'] = filters.searchName.trim();
    }

    if (filters?.customerAccountIds?.length) {
      query['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.dispatchNumbers?.length) {
      query['filter[job][dispatch_numbers]'] = filters.dispatchNumbers;
    }

    if (filters?.driverIds?.length) {
      query['filter[job][driver_ids]'] = filters.driverIds;
    }

    if (filters?.projectsExternalIds?.length) {
      query['filter[job][external_ids]'] = filters.projectsExternalIds;
    }

    if (filters?.projectIds?.length) {
      query['filter[job][project_ids]'] = filters.projectIds;
    }

    if (filters?.vendorAccountIds?.length) {
      query['filter[job][vendor_account_ids]'] = filters.vendorAccountIds;
    }

    if (filters?.orderStates?.length) {
      query['filter[states]'] = filters.orderStates;
    }

    if (filters?.startDate?.length) {
      query['filter[job][start_date]'] = filters.startDate;
    }

    if (filters?.endDate?.length) {
      query['filter[job][end_date]'] = filters.endDate;
    }

    if (filters?.jobStates?.length) {
      query['filter[job][states]'] = filters.jobStates;
    }

    if (filters?.pickUpSiteIds?.length) {
      query['filter[job][pickup_site_ids]'] = filters.pickUpSiteIds;
    }

    if (filters?.dropOffSiteIds?.length) {
      query['filter[job][dropoff_site_ids]'] = filters.dropOffSiteIds;
    }

    if (filters?.requesterIds?.length) {
      query['filter[requester_ids]'] = filters.requesterIds;
    }

    try {
      const response = await getV1OrdersDispatch({ query });
      const orders = response.data.data.map((order) => Order.parse(order));
      const pagination = extractPagination(response);
      calendarDispatchStore.ordersFetchEnd(orders, pagination);
    } catch (error) {
      calendarDispatchStore.ordersFetchEnd([]);
      console.error(error);
      connection.handleRequestError(
        error,
        t('error_messages.orders.failed_to_fetch') as string,
      );
    }
  };

  const getCompanyOrdersByCompanyId = async ({ companyId }: { companyId: string }) => {
    calendarDispatchStore.ordersFetchStart();

    const { pagination, filters } = calendarDispatchStore;

    const query: GetV1CompaniesCompanyIdOrdersDispatchData['query'] = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      query['page[before]'] = pagination.before;
    } else if (pagination.after) {
      query['page[after]'] = pagination.after;
    }

    if (filters?.searchName?.trim().length) {
      query['search[job][datagrid]'] = filters.searchName.trim();
    }

    if (filters?.customerAccountIds?.length) {
      query['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.dispatchNumbers?.length) {
      query['filter[job][dispatch_numbers]'] = filters.dispatchNumbers;
    }

    if (filters?.driverIds?.length) {
      query['filter[job][driver_ids]'] = filters.driverIds;
    }

    if (filters?.projectsExternalIds?.length) {
      query['filter[job][external_ids]'] = filters.projectsExternalIds;
    }

    if (filters?.projectIds?.length) {
      query['filter[job][project_ids]'] = filters.projectIds;
    }

    if (filters?.vendorAccountIds?.length) {
      query['filter[job][vendor_account_ids]'] = filters.vendorAccountIds;
    }

    if (filters?.orderStates?.length) {
      query['filter[states]'] = filters.orderStates;
    }

    if (filters?.startDate?.length) {
      query['filter[job][start_date]'] = filters.startDate;
    }

    if (filters?.endDate?.length) {
      query['filter[job][end_date]'] = filters.endDate;
    }

    if (filters?.jobStates?.length) {
      query['filter[job][states]'] = filters.jobStates;
    }

    if (filters?.pickUpSiteIds?.length) {
      query['filter[job][pickup_site_ids]'] = filters.pickUpSiteIds;
    }

    if (filters?.dropOffSiteIds?.length) {
      query['filter[job][dropoff_site_ids]'] = filters.dropOffSiteIds;
    }

    if (filters?.requesterIds?.length) {
      query['filter[requester_ids]'] = filters.requesterIds;
    }

    try {
      const response = await getV1CompaniesCompanyIdOrdersDispatch({
        path: { 'company-id': companyId },
        query,
      });
      const orders = response.data.data.map((order) => Order.parse(order));
      const pagination = extractPagination(response);
      calendarDispatchStore.ordersFetchEnd(orders, pagination);
    } catch (error) {
      calendarDispatchStore.ordersFetchEnd([]);
      console.error(error);
      connection.handleRequestError(
        error,
        t('error_messages.orders.failed_to_fetch') as string,
      );
    }
  };

  return {
    getCompanyOrders,
    getCompanyOrdersByCompanyId,
  };
};
