import {
  Agave_LinkedAccount_Read,
  Agave_LinkToken_Read,
  AgaveItemizableType,
  AgaveLedgerAccountType,
  getV1AgaveLinkedAccountsLinkedAccountIdCustomers,
  getV1AgaveLinkedAccountsLinkedAccountIdItems,
  getV1AgaveLinkedAccountsLinkedAccountIdItemsAddOnsTypeahead,
  getV1AgaveLinkedAccountsLinkedAccountIdItemsServicesTypeahead,
  getV1AgaveLinkedAccountsLinkedAccountIdLedgerAccounts,
  getV1AgaveLinkedAccountsLinkedAccountIdVendors,
  getV1LinkedAccountsLinkedAccountIdCustomersAccountsTypeahead,
  getV1LinkedAccountsLinkedAccountIdVendorsAccountsTypeahead,
  patchV1AgaveCustomersId,
  patchV1AgaveItemsId,
  patchV1AgaveLedgerAccountsId,
  patchV1AgaveVendorsId,
  putV1AgaveLinkedAccountsLinkedAccountIdImport,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { extractPagination, Pagination, PaginationQuery } from '~services/pagination';

import { AccountTypeahead } from '../useAccount';
import {
  AgaveCustomer,
  AgaveItem,
  AgaveLedgerAccount,
  AgaveLinkedAccount,
  AgaveVendor,
} from './models';

export interface TypeaheadOptions {
  linkedAccountId: string;
  query: string;
  pagination: Pagination;
}

export default function useAgave() {
  const createLinkedAccount = async (publicToken: string) => {
    try {
      const response = await connection.post<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts`,
        { public_token: publicToken },
        {},
        t('error_messages.agave.failed_to_create_linked_account') as string,
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const deleteLinkedAccount = async (
    linkedAccountId: string,
    errorCallback?: (error: AxiosError) => void,
  ) => {
    try {
      await connection.delete<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts/${linkedAccountId}`,
        {},
        t('error_messages.agave.failed_to_delete_linked_account') as string,
        [500],
      );
    } catch (error) {
      console.error(error);
      errorCallback?.(error as AxiosError);
      throw error;
    }
  };

  const getLinkToken = async () => {
    try {
      const response = await connection.post<Agave_LinkToken_Read>(
        `${API_VERSION}/agave/link_tokens`,
        {},
        {},
        t('error_messages.agave.failed_to_fetch_link_token') as string,
      );

      return response.link_token;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getLinkedAccounts = async (pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await connection.getPaginated<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts`,
        { params },
        t('error_messages.agave.failed_to_fetch_linked_accounts') as string,
      );
      const linkedAccounts = response.data.map((account) => {
        return AgaveLinkedAccount.parse(account);
      });

      return { data: linkedAccounts, pagination: response.pagination };
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const enqueueImport = async (linkedAccountId: string) => {
    try {
      const response = await putV1AgaveLinkedAccountsLinkedAccountIdImport({
        path: { 'linked-account-id': linkedAccountId },
      });
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_enqueue_import') as string,
      );
    }
  };

  const getCustomers = async (linkedAccountId: string, pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await getV1AgaveLinkedAccountsLinkedAccountIdCustomers({
        path: { 'linked-account-id': linkedAccountId },
        query: params,
      });
      const customers = response.data.data.map((customer) => {
        return AgaveCustomer.parse(customer);
      });
      return { data: customers, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_customers') as string,
      );
      return null;
    }
  };

  const getCustomerAccountsTypeahead = async (options: TypeaheadOptions) => {
    if (!options.linkedAccountId) {
      return null;
    }
    try {
      const params: PaginationQuery = {
        'page[limit]': options.pagination.limit,
      };

      if (options.query) {
        params['search[query]'] = options.query;
      }

      const response = await getV1LinkedAccountsLinkedAccountIdCustomersAccountsTypeahead(
        {
          path: { 'linked-account-id': options.linkedAccountId },
          query: params,
        },
      );

      const formattedAccounts = response.data.data.map((account) => {
        return AccountTypeahead.parse(account);
      });

      return { data: formattedAccounts, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_accounts') as string,
      );
      return null;
    }
  };
  const updateCustomer = async (
    agaveCustomerId: string,
    customerAccountId: string | null,
  ) => {
    try {
      const response = await patchV1AgaveCustomersId({
        path: { id: agaveCustomerId },
        body: { customer_account_id: customerAccountId },
      });

      return AgaveCustomer.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_update_customer') as string,
      );
      return null;
    }
  };

  const getVendors = async (linkedAccountId: string, pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await getV1AgaveLinkedAccountsLinkedAccountIdVendors({
        path: { 'linked-account-id': linkedAccountId },
        query: params,
      });
      const vendors = response.data.data.map((vendor) => {
        return AgaveVendor.parse(vendor);
      });

      return { data: vendors, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_vendors') as string,
      );
      return null;
    }
  };
  const getVendorAccountsTypeahead = async (options: TypeaheadOptions) => {
    if (!options.linkedAccountId) {
      return null;
    }
    try {
      const params: PaginationQuery = {
        'page[limit]': options.pagination.limit,
      };

      if (options.query) {
        params['search[query]'] = options.query;
      }

      const response = await getV1LinkedAccountsLinkedAccountIdVendorsAccountsTypeahead({
        path: { 'linked-account-id': options.linkedAccountId },
        query: params,
      });

      const formattedAccounts = response.data.data.map((account) => {
        return AccountTypeahead.parse(account);
      });

      return { data: formattedAccounts, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_vendors') as string,
      );
      return null;
    }
  };

  const updateVendor = async (agaveVendorId: string, vendorAccountId: string | null) => {
    try {
      const response = await patchV1AgaveVendorsId({
        path: { id: agaveVendorId },
        body: { vendor_account_id: vendorAccountId },
      });

      return AgaveVendor.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_update_vendor') as string,
      );
      return null;
    }
  };

  // Items
  const getItems = async (linkedAccountId: string, pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await getV1AgaveLinkedAccountsLinkedAccountIdItems({
        path: { 'linked-account-id': linkedAccountId },
        query: params,
      });
      const items = response.data.data.map((item) => {
        return AgaveItem.parse(item);
      });

      return { data: items, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_items') as string,
      );
      return null;
    }
  };
  const getItemsAddOnsTypeahead = async (options: TypeaheadOptions) => {
    if (!options.linkedAccountId) {
      return null;
    }
    try {
      const params: PaginationQuery = {
        'page[limit]': options.pagination.limit,
      };

      if (options.query) {
        params['search[query]'] = options.query;
      }

      const response = await getV1AgaveLinkedAccountsLinkedAccountIdItemsAddOnsTypeahead({
        path: { 'linked-account-id': options.linkedAccountId },
        query: params,
      });

      const formattedAccounts = response.data.data.map((account) => {
        return AccountTypeahead.parse(account);
      });

      return { data: formattedAccounts, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_items_add_ons') as string,
      );
      return null;
    }
  };
  const getItemsServicesTypeahead = async (options: TypeaheadOptions) => {
    if (!options.linkedAccountId) {
      return null;
    }
    try {
      const params: PaginationQuery = {
        'page[limit]': options.pagination.limit,
      };

      if (options.query) {
        params['search[query]'] = options.query;
      }

      const response =
        await getV1AgaveLinkedAccountsLinkedAccountIdItemsServicesTypeahead({
          path: { 'linked-account-id': options.linkedAccountId },
          query: params,
        });

      const formattedAccounts = response.data.data.map((account) => {
        return AccountTypeahead.parse(account);
      });

      return { data: formattedAccounts, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_items_services') as string,
      );
      return null;
    }
  };

  const updateItem = async (
    agaveItemId: string,
    itemAccountId: string | null,
    type: AgaveItemizableType | null,
  ) => {
    try {
      const response = await patchV1AgaveItemsId({
        path: { id: agaveItemId },
        body: {
          agave_itemizable_id: itemAccountId,
          agave_itemizable_type: type,
        },
      });

      return AgaveItem.parse(response.data.data);
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_update_item') as string,
      );
      return null;
    }
  };

  // Ledger
  const getLedgerAccounts = async (linkedAccountId: string, pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await getV1AgaveLinkedAccountsLinkedAccountIdLedgerAccounts({
        path: { 'linked-account-id': linkedAccountId },
        query: params,
      });
      const items = response.data.data.map((item) => {
        return AgaveLedgerAccount.parse(item);
      });

      return { data: items, pagination: extractPagination(response) };
    } catch (error) {
      connection.handleRequestError(
        error,
        t('error_messages.agave.failed_to_fetch_ledger') as string,
      );
      return null;
    }
  };

  const updateLedgerAccount = async (
    agaveLedgerAccountId: string,
    type: AgaveLedgerAccountType | null,
  ) => {
    const response = await patchV1AgaveLedgerAccountsId({
      path: { id: agaveLedgerAccountId },
      body: {
        type: type,
      },
    });

    return AgaveLedgerAccount.parse(response.data.data);
  };

  return {
    createLinkedAccount,
    deleteLinkedAccount,
    getLinkToken,
    getLinkedAccounts,
    enqueueImport,
    getCustomers,
    getCustomerAccountsTypeahead,
    updateCustomer,
    getVendors,
    getVendorAccountsTypeahead,
    updateVendor,
    getItems,
    getItemsAddOnsTypeahead,
    getItemsServicesTypeahead,
    updateItem,
    getLedgerAccounts,
    updateLedgerAccount,
  };
}
