import { AddOnRateType } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import * as yup from 'yup';

import { AddOnCharge } from '~hooks/useAddOns';
import { ResourceUsageLog } from '~src/hooks/useResourceUsageLogs/models';

/**
 * Utils
 */
const SEPARATOR = ' - ';
const RATE_FOR_EACH_DESCRIPTION = t('administration.rates.add_ons.rate_for_each');
const RATE_PERCENT_DESCRIPTION = t('administration.rates.add_ons.rate_percent_of_total');

export const appendAddOnRateTypeToName = (name: string, rateType: AddOnRateType) => {
  const isRateForEach = rateType === AddOnRateType.RATE_FOR_EACH;
  const rateTypeDescription = isRateForEach
    ? RATE_FOR_EACH_DESCRIPTION
    : RATE_PERCENT_DESCRIPTION;

  return `${name}${SEPARATOR}${rateTypeDescription}`;
};

export const removeAddOnRateTypeFromName = (name: string) => {
  if (name.endsWith(`${SEPARATOR}${RATE_FOR_EACH_DESCRIPTION}`)) {
    return name.slice(
      0,
      name.length - RATE_FOR_EACH_DESCRIPTION.length - SEPARATOR.length,
    );
  }

  if (name.endsWith(`${SEPARATOR}${RATE_PERCENT_DESCRIPTION}`)) {
    return name.slice(
      0,
      name.length - RATE_PERCENT_DESCRIPTION.length - SEPARATOR.length,
    );
  }

  return name;
};

/**
 * Schema helpers
 */
const dateSchema = yup
  .object()
  .required('')
  .test('is-valid-date', '', (val) => {
    const hasValue = Boolean(val);
    const isValid = dayjs.isDayjs(val) && val.isValid();

    return hasValue && isValid;
  });

const addOnChargeToAddOnChargeDTO = (addOnCharge: AddOnCharge) => {
  const dto: AddOnChargeDTO = {
    id: addOnCharge.id,
    addOnRateType: addOnCharge.addOnRateType,
    addOn: {
      id: addOnCharge.addOnId,
      name: addOnCharge.addOnId
        ? appendAddOnRateTypeToName(addOnCharge.name, addOnCharge.addOnRateType)
        : addOnCharge.name,
    },
    quantity: addOnCharge.quantity,
    value:
      addOnCharge.addOnRateType === AddOnRateType.RATE_FOR_EACH
        ? addOnCharge.rate
        : addOnCharge.percentage,
  };

  return {
    ...dto,
    quantity: dto.quantity.toString(),
    value: dto.value.toString(),
  } as unknown as AddOnChargeDTO;
};

const resourceUsageLogToResourceUsageLogDTO = (resourceUsageLog: ResourceUsageLog) => {
  const dto: ResourceUsageLogDTO = {
    id: resourceUsageLog.id,
    equipment: {
      id: resourceUsageLog.equipment?.id,
      name: resourceUsageLog.equipment?.name,
    },
    quantity: resourceUsageLog.usageMinutes / 60,
    phase: {
      id: resourceUsageLog.phase?.id,
      name: resourceUsageLog.phase?.name,
      code: resourceUsageLog.phase?.code,
    },
  };

  return {
    ...dto,
    quantity: dto.quantity.toString(),
  } as unknown as ResourceUsageLogDTO;
};

export const schemaHelpers = {
  addOnChargeToAddOnChargeDTO,
  resourceUsageLogToResourceUsageLogDTO,
};
/**
 * Hourly rate
 */
export const hourlylLineItemSchema = yup.object().shape({
  id: yup.string().uuid().notRequired(),
  billingAdjustmentMinutes: yup.number().integer('').required('').typeError(''),
  payStartTime: dateSchema.clone(),
  payEndTime: dateSchema.clone(),
  rate: yup.number().required(''),
});

type GenericNormalized<T> = Partial<T> & { id: string };

export type HourlyLineItemDTO = yup.InferType<typeof hourlylLineItemSchema>;
export type NormalizedHourlyLineItem = GenericNormalized<
  HourlyLineItemDTO & { quantity?: number }
>;

/**
 * Load rate
 */
export const loadlLineItemSchema = yup.object().shape({
  id: yup.string().uuid().notRequired(),
  rate: yup.number().required('').typeError(''),
});

export type LoadLineItemDTO = yup.InferType<typeof loadlLineItemSchema>;
export type NormalizedLoadLineItem = GenericNormalized<LoadLineItemDTO>;

/**
 * Ton rate
 */
export const tonlLineItemSchema = yup.object().shape({
  id: yup.string().uuid().notRequired(),
  quantity: yup.number().min(0, '').required('').typeError(''),
  rate: yup.number().required('').typeError(''),
});

export type TonLineItemDTO = yup.InferType<typeof tonlLineItemSchema>;
export type NormalizedTonLineItem = GenericNormalized<TonLineItemDTO>;

/**
 * Add-on charges
 */
export const addOnChargeSchema = yup.object().shape({
  id: yup.string().uuid().notRequired(),
  _destroy: yup.mixed().oneOf([1]).notRequired(),
  addOnRateType: yup
    .mixed()
    .oneOf([AddOnRateType.RATE_FOR_EACH, AddOnRateType.RATE_PERCENT_OF_TOTAL])
    .required(),
  addOn: yup
    .object()
    .shape({
      id: yup.string().required(''),
      name: yup.string().required(''),
    })
    .required(''),
  quantity: yup.number().required('').typeError(''),
  // "value" will be either "rate" for rate-per-each based add-ons or "percentage" otherwise
  value: yup
    .number()
    .required('')
    .typeError('')
    .when(['addOnRateType'], ([addOnRateType], schema) => {
      if (addOnRateType === AddOnRateType.RATE_PERCENT_OF_TOTAL) {
        return schema.min(-100, '').max(100, '');
      }

      return schema;
    }),
});

export const resourceAddOnChargeSchema = yup.object().shape({
  id: yup.string().uuid(),

  equipment: yup.object().shape({
    id: yup.string(),
    name: yup.string(),
  }),
  quantity: yup.number().required('').typeError(''),
  phase: yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    code: yup.string(),
  }),
  _destroy: yup.mixed().oneOf([1]).notRequired(),
});

export type AddOnChargeDTO = yup.InferType<typeof addOnChargeSchema>;

export type ResourceUsageLogDTO = yup.InferType<typeof resourceAddOnChargeSchema>;
/**
 * Date/Time helpers
 */
const getDurationInMinutes = (date: Dayjs) => {
  const formatted = dayjs.tz(date as Dayjs).format('HH:mm');
  const [hours, minutes] = formatted.split(':').map((i) => Number(i));

  return hours * 60 + minutes;
};

const diffDatesInHours = (payStart: Dayjs | null, payEnd: Dayjs | null) => {
  if (payStart && payEnd) {
    const today = dayjs.tz();
    const startDate = payStart
      .clone()
      .set('date', today.get('date'))
      .set('month', today.get('month'))
      .set('year', today.get('year'))
      .startOf('minutes');
    const endDate = payEnd
      .clone()
      .set('date', today.get('date'))
      .set('month', today.get('month'))
      .set('year', today.get('year'))
      .startOf('minutes');

    let diff = endDate.diff(startDate, 'hours', true);

    // If the given start time is after the given end time then assume the job started a day and
    // Finished the next day
    if (diff < 0) {
      diff += 24;
    }

    return parseFloat(diff.toFixed(2));
  }

  return 0;
};

export const dateTimeHelpers = { getDurationInMinutes, diffDatesInHours };

/**
 * Invoice helpers
 */
const calculateAddOnChargeTotal = (
  rateType: AddOnRateType | null,
  quantity: number = 0,
  rate: number = 0,
  percentage: number = 0,
  lineItemsSubtotal: number,
) => {
  if (!rateType) {
    return 0;
  }

  if (rateType === AddOnRateType.RATE_FOR_EACH) {
    return quantity * rate;
  }

  return lineItemsSubtotal * (percentage / 100);
};

const calculateInvoiceTotal = (invoiceSubtotal: number, addOnsSum: number) => {
  return invoiceSubtotal + addOnsSum;
};

export const invoiceHelpers = { calculateAddOnChargeTotal, calculateInvoiceTotal };
