import * as Sentry from '@sentry/react';
import { Driver_Read, Driver_Read_Typeahead } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Driver, DriverBasic } from '~hooks/useDrivers/models';
import connection from '~services/connectionModule';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';

interface GetDriversByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  limit?: number;
  dispatchable?: boolean;
  shared?: boolean;
  searchQuery?: string;
  callback?: (drivers: Driver[]) => void;
}

interface FetchDriversByCompanyIdProps {
  companyId: string;
  dispatchable?: boolean;
  shared?: boolean;
  searchQuery?: string;
  pagination: { link?: NonNullable<PaginationLink>; cursor?: string; limit: number };
}

interface GetDriversByCompanyIdParams {
  'page[limit]'?: number;
  'page[after]'?: string;
  'page[before]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
  'search[datagrid]'?: string;
}

export interface GetDriversTypeaheadParams {
  companyId: string;
  shared?: boolean;
  dispatchable?: boolean;
  searchParams?: {
    limit?: number;
    query?: string;
    link?: {
      type: PaginationLink;
      cursor: string;
    };
  };
}

interface GetDriversTypeaheadQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'search[query]'?: string;
  'filter[dispatchable]'?: boolean;
  'filter[shared]'?: boolean;
}

export const useDrivers = () => {
  const { companyAssetsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const getDriverById = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await connection.get<Driver_Read>(
        `${API_VERSION}/drivers/${id}`,
        {},
        $t('error_messages.drivers.failed_to_fetch_driver') as string,
      );
      return Driver.parse(response);
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_driver') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriversByCompanyId = async ({
    companyId,
    link,
    shared,
    limit,
    dispatchable,
    searchQuery,
    callback,
  }: GetDriversByCompanyIdProps) => {
    const params: GetDriversByCompanyIdParams = {
      'page[limit]': limit || companyAssetsStore.driversPagination.limit,
    };
    if (searchQuery) {
      params['search[datagrid]'] = searchQuery;
    }
    if (link && companyAssetsStore.driversPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.driversPagination[link];
    }
    if (!_.isNil(shared)) {
      params['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      params['filter[dispatchable]'] = dispatchable;
    }

    try {
      setIsLoading(true);
      const response = await connection.getPaginated<Driver_Read>(
        `${API_VERSION}/companies/${companyId}/drivers`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      const parsedDrivers = data.map(Driver.parse);
      companyAssetsStore.setDrivers(parsedDrivers);
      companyAssetsStore.setDriversPagination(pagination);
      companyAssetsStore.updateDriversPageNumber(link);
      callback?.(parsedDrivers);
      return parsedDrivers;
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_drivers') as string);
    } finally {
      setIsLoading(false);
    }
  };

  // same as "getDriversByCompanyId", but store agnostic
  const fetchDriversByCompanyId = async ({
    companyId,
    dispatchable,
    shared,
    searchQuery,
    pagination,
  }: FetchDriversByCompanyIdProps) => {
    const params: GetDriversByCompanyIdParams = {
      'page[limit]': pagination.limit,
    };

    if (pagination.link && pagination.cursor) {
      params[`page[${pagination.link}]`] = pagination.cursor;
    }

    if (!_.isNil(shared)) {
      params['filter[shared]'] = shared;
    }

    if (!_.isNil(dispatchable)) {
      params['filter[dispatchable]'] = dispatchable;
    }

    if (!_.isNil(searchQuery)) {
      params['search[datagrid]'] = searchQuery;
    }

    try {
      setIsLoading(true);

      const response = await connection.getPaginated<Driver_Read>(
        `${API_VERSION}/companies/${companyId}/drivers`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      const parsedDrivers = data.map((driver) => Driver.parse(driver));

      return { pagination, data: parsedDrivers };
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_drivers') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriversByCompanyIdTypeahead = async (options: GetDriversTypeaheadParams) => {
    const params: GetDriversTypeaheadQueryProps = {
      'page[limit]':
        options.searchParams?.limit || companyAssetsStore.driversPagination.limit,
    };
    if (options.searchParams?.query) {
      params['search[query]'] = options.searchParams.query.trim();
    }
    if (!_.isNil(options.shared)) {
      params['filter[shared]'] = options.shared;
    }
    if (!_.isNil(options.dispatchable)) {
      params['filter[dispatchable]'] = options.dispatchable;
    }
    if (
      options?.searchParams?.link &&
      options?.searchParams?.link.type &&
      options?.searchParams?.link.cursor
    ) {
      params[`page[${options?.searchParams?.link.type}]`] =
        options?.searchParams?.link.cursor;
    }

    try {
      setIsLoading(true);
      const response = await connection.getPaginated<Driver_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/drivers/typeahead`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      return { data: (data as Driver_Read[]).map(DriverBasic.parse), pagination };
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      throw new Error($t('error_messages.drivers.failed_to_fetch_drivers') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getDriverByID = async (driverId: string) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<Driver_Read>(
        `${API_VERSION}/drivers/${driverId}`,
        {},
        $t('error_messages.drivers.failed_to_fetch_driver') as string,
      );
      const formatted = Driver.parse(resp);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchDriversByCompanyId,
    getDriverByID,
    getDriverById,
    getDriversByCompanyId,
    getDriversByCompanyIdTypeahead,
    isLoading,
  };
};
