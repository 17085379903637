import Check from '@mui/icons-material/Check';
import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ShiftAdjustmentType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useAddOns } from '~hooks/useAddOns';
import { DriverDay } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import useResourceUsageLogs from '~hooks/useResourceUsageLogs/useResourceUsageLogs';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { dateFormat } from '~utils/dateTime';

import {
  AddOnChargeDTO,
  ResourceUsageLogDTO,
  schemaHelpers,
} from '../../DriverPay/helpers';
import ApprovalActionsMenu from './ApprovalActionsMenu';
import ChargesTable from './ChargesTable';

const emDash = '-';

// We assume hours could be 0.01 and need to round this to an integer
const convertHoursToMinutes = (hours: number): number => {
  return Math.round(hours * 60);
};
const convertMinutesToHours = (minutes: number): number => {
  return minutes / 60;
};
const formatMinutesToHoursString = (minutes: number): string =>
  `${convertMinutesToHours(minutes).toFixed(2)}h`;

const calculateAdjustmentMinutes = (
  currentTotalShiftMinutes: number,
  currentShiftAdjustmentMinutes: number,
  newTotalShiftMinutes: number,
) => {
  const baseMinutes = currentTotalShiftMinutes - currentShiftAdjustmentMinutes;
  return newTotalShiftMinutes - baseMinutes;
};

type FormData = {
  shiftTimeInHours: number;
  externalId: string | null;
  overrideReason: ShiftAdjustmentType | null;
  addOnCharges: AddOnChargeDTO[];
  resourceUsageLogs: ResourceUsageLogDTO[];
};

export function ShiftDetails({ driverDay }: { driverDay: DriverDay }) {
  const { addOnsStore, toasterStore, driverDayStore } = useStores();
  const { getAllAddOnChargesByDriverDay } = useAddOns();
  const { getResourceUsageLogsByDriverDay, batchUpdateResourceUsageLogsByDriverDay } =
    useResourceUsageLogs();
  const [isEditing, setIsEditing] = useState(false);
  const {
    updateDriverDay,
    approveDriverDay,
    unapproveDriverDay,
    getDriverDayPhasesTypeahead,
  } = useDriverDays();
  const { batchUpdateAddOnChargesByDriverDay } = useAddOns();

  useEffect(() => {
    getAllAddOnChargesByDriverDay(driverDay.id);
    getResourceUsageLogsByDriverDay(driverDay.id);
    getDriverDayPhasesTypeahead({ driverDayId: driverDay.id });
  }, [driverDay.id]);

  const addOns = useMemo(() => {
    return addOnsStore.addOnChargesByDriverDayId[driverDay.id] ?? [];
  }, [driverDay.id, addOnsStore.addOnChargesByDriverDayId]);

  const resourceUsageLogs = useMemo(() => {
    return addOnsStore.resourceUsageLogsByDriverDayId[driverDay.id] ?? [];
  }, [driverDay.id, addOnsStore.resourceUsageLogsByDriverDayId]);

  const phasesOptions = useMemo(() => {
    return driverDayStore.phaseTypeheadsByDriverDayID[driverDay.id] ?? [];
  }, [driverDay.id, driverDayStore.phaseTypeheadsByDriverDayID]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2),
      ),
      overrideReason: null,
      externalId: driverDay.externalId ?? null,
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    },
    mode: 'all',
    delayError: 500,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = formMethods;

  useEffect(() => {
    setValue(
      'shiftTimeInHours',
      parseFloat(convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2)),
    );
    driverDay.shiftAdjustmentType &&
      setValue('overrideReason', driverDay.shiftAdjustmentType);
  }, [driverDay.totalShiftMinutes, driverDay.shiftAdjustmentType]);

  useEffect(() => {
    setValue(
      'addOnCharges',
      addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
    );
  }, [addOns]);

  useEffect(() => {
    setValue(
      'resourceUsageLogs',
      resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    );
  }, [resourceUsageLogs]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    Promise.all([
      batchUpdateResourceUsageLogsByDriverDay(driverDay.id, data.resourceUsageLogs),
      batchUpdateAddOnChargesByDriverDay(driverDay.id, data.addOnCharges),
      updateDriverDay(
        driverDay.id,
        calculateAdjustmentMinutes(
          driverDay.totalShiftMinutes ?? 0,
          driverDay.shiftAdjustmentMinutes,
          convertHoursToMinutes(data.shiftTimeInHours),
        ),
        data.externalId,
        data.overrideReason ?? undefined,
      ),
    ]).then(() => {
      setIsEditing(false);
      toasterStore.push(
        alert(
          t('approvals.driver_pay.settlements.add_ons_updated_successfully'),
          AlertTypes.success,
        ),
      );
      reset();
    });
  };

  const handleApprove = () => {
    approveDriverDay(driverDay.id);
  };

  const handleUnapprove = () => {
    if (!driverDay.unapprovable) return undefined;
    unapproveDriverDay(driverDay.id);
  };

  const ApprovalCTA = () => {
    const approved = driverDay.approved;
    const approvable = driverDay.approvable;

    if (approved) {
      return (
        <ApprovalActionsMenu onMenuItemClick={handleUnapprove} approved={approved} />
      );
    }
    if (approvable) {
      return (
        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="success"
          onClick={() => {
            handleApprove();
          }}
          sx={{
            backgroundColor: theme.brandV2.colors.treadGreen,
          }}
          size="small"
          disabled={isEditing}
        >
          {t('actions.approve')}
        </Button>
      );
    }

    return null;
  };

  const resetForm = () => {
    reset({
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2),
      ),
      overrideReason: driverDay.shiftAdjustmentType,
      externalId: driverDay.externalId ?? null,
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    });
  };

  const EditingButton = () => {
    if (!driverDay.editable) {
      return null;
    }

    return isEditing ? (
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
        }}
      >
        <Button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          variant="outlined"
          color="secondary"
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
          startIcon={<Close />}
        >
          {t('actions.cancel')}
        </Button>

        <Button
          type="submit"
          variant={'contained'}
          color={'brandV2Green'}
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
          startIcon={<Check />}
          disabled={!isValid || !isDirty}
        >
          {t('actions.save_changes')}
        </Button>
      </Box>
    ) : (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setIsEditing(!isEditing);
        }}
        sx={{
          fontWeight: 600,
          '&.MuiButton-root': {
            borderColor: theme.brandV2.colors.treadGray7,
          },
        }}
        startIcon={<Edit />}
      >
        {t('actions.edit')}
      </Button>
    );
  };

  const ActionsSection = () => {
    if (isEditing) return <EditingButton />;
    else {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
          }}
        >
          <EditingButton />
          <ApprovalCTA />
        </Box>
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        id="shift-details-form"
        onSubmit={handleSubmit(onSubmit)}
        aria-label="shift details"
        sx={{
          backgroundColor: 'white',

          borderRadius: 2,
          border: `1px solid ${theme.brandV2.colors.treadGray7}`,
          height: 'fit-content',
          gap: 1.5,
        }}
      >
        <Box
          aria-label="shift summary"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 3,
            p: 1.5,
          }}
        >
          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.shift_start_details')}
            </Typography>
            <Typography variant="h6">
              {driverDay?.startedShiftAt
                ? dateFormat(driverDay.startedShiftAt, 'hh:mm A')
                : emDash}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.first_geofence_entry')} :
              {driverDay?.firstGeofenceEntryAt
                ? dateFormat(driverDay?.firstGeofenceEntryAt, 'hh:mm A')
                : emDash}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.first_ticket')} :{' '}
              {driverDay?.firstTicketAt
                ? dateFormat(driverDay?.firstTicketAt, 'hh:mm A')
                : emDash}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.shift_end_details')}
            </Typography>
            <Typography variant="h6">
              {driverDay?.endedShiftAt
                ? dateFormat(driverDay.endedShiftAt, 'hh:mm A')
                : emDash}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.last_geofence_entry')} :{' '}
              {driverDay?.lastGeofenceExitAt
                ? dateFormat(driverDay?.lastGeofenceExitAt, 'hh:mm A')
                : emDash}
            </Typography>

            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {t('approvals.driver_day.last_ticket')} :{' '}
              {driverDay?.lastTicketAt
                ? dateFormat(driverDay?.lastTicketAt, 'hh:mm A')
                : emDash}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.total_hours')}
            </Typography>
            {isEditing ? (
              <TextField
                {...register('shiftTimeInHours', {
                  min: 0,
                  setValueAs: (v) =>
                    !isNaN(v) ? parseFloat(parseFloat(v).toFixed(2)) : v,
                  validate: {
                    number: (v) => !isNaN(v),
                    positive: (v) => v >= 0,
                  },
                })}
                error={!!errors.shiftTimeInHours}
              />
            ) : (
              <Typography variant="h6">
                {driverDay.totalShiftMinutes
                  ? formatMinutesToHoursString(driverDay.totalShiftMinutes)
                  : emDash}
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
            >
              {`${t('approvals.driver_day.breaks')}: ${driverDay?.totalBreakMinutes ? `${driverDay?.totalBreakMinutes}min` : emDash}`}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
            >
              {t('approvals.driver_day.timesheet_id')}
            </Typography>
            {isEditing ? (
              <TextField {...register('externalId')} error={!!errors.externalId} />
            ) : (
              <Typography variant="h6">{driverDay.externalId ?? emDash}</Typography>
            )}
          </Box>
          <ActionsSection />
        </Box>

        <ChargesTable
          isEditing={isEditing}
          addOns={addOns}
          resourceUsageLogs={resourceUsageLogs}
          phasesOptions={phasesOptions}
        />
      </Box>
    </FormProvider>
  );
}
