import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { DataGridBottomBarButton } from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job, useJob } from '~hooks/useJob';
import { useSettlements } from '~hooks/useSettlements';
import DriverPayDataGridExpandedBlock from '~pages/Approvals/DriverPay/DriverPayDataGridExpandedBlock';
import * as colsDef from '~pages/Approvals/DriverPay/driversPayDataGridColumnsDefinition';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import {
  SettlementForm,
  SettlementFormHandler,
  useSettlementForm,
} from './components/SettlementForm';
import { receivableInvoiceCategories, TabQSParam } from './DriverPay';

type JobsById = Record<string, Job>;

const groupJobsById = (invoices: Invoice[], jobsInStore: Job[]) => {
  return invoices.reduce((obj, invoice) => {
    const jobId = invoice.job?.id;

    if (jobId) {
      const storedJob = jobsInStore.find((job) => job.id === jobId);

      if (storedJob) {
        obj[jobId] = storedJob;
      }
    }

    return obj;
  }, {} as JobsById);
};

const getIdsOfMissingJobs = (invoices: Invoice[], jobsGroupedById: JobsById) => {
  const missingJobIds = invoices.reduce((acc, invoice) => {
    const jobId = invoice.job?.id;

    if (jobId && !jobsGroupedById[jobId]) {
      acc.push(jobId);
    }

    return acc;
  }, [] as string[]);

  // Sorted! so it remains the same afer being stringified
  return missingJobIds.sort();
};

interface DriverPayDataGridProps {
  sx?: SxProps;
}

const DriverPayDataGrid = observer(({ sx }: DriverPayDataGridProps) => {
  const { invoicesStore, jobStore, userStore, toasterStore } = useStores();
  const {
    approveInvoice,
    getInvoices,
    isLoading: isLoadingInvoices,
    isUpdating: isUpdatingInvoices,
    sendInvoice,
    subscribeToInvoiceRTU,
  } = useInvoices();
  const { currencyFormatter } = useCompanyCurrency();
  const { getJobsByJobIds } = useJob();
  const [searchParams] = useSearchParams();

  const currentCategory = searchParams.get('category') as InvoiceCategoryFilter;
  const shouldAllowBulkActions = searchParams.get('tab') === TabQSParam.APPROVED;

  const jobsById = useMemo(() => {
    return groupJobsById(invoicesStore.invoices, jobStore.jobs);
  }, [invoicesStore.invoices, jobStore.jobs]);

  const missingJobIds = useMemo(() => {
    return getIdsOfMissingJobs(invoicesStore.invoices, jobsById);
  }, [invoicesStore.invoices, jobsById]);

  const isLoadingJobs = useMemo(() => missingJobIds.length > 0, [missingJobIds.length]);
  const isLoading = isLoadingInvoices || isUpdatingInvoices || isLoadingJobs;

  const settlementsFormRef = useRef<SettlementFormHandler>(null);
  const { selectedInvoices, updateSelectedInvoices, dialogHandler } = useSettlementForm();
  const { isCreating: isCreatingSettlement, createSettlement } = useSettlements();

  const rows = useMemo(() => {
    return invoicesStore.invoices.map((invoice) => {
      const job = invoice.job?.id ? jobsById[invoice.job.id] : null;

      const row: colsDef.DriverPayRowDef['row'] = {
        currentCategory,
        id: String(invoice.id),
        invoice,
        job,
        userCompanyName: userStore.userCompany.legalName,
        utils: {
          currencyFormatter,
          approveInvoice: async (invoice: Invoice) => {
            await approveInvoice(invoice);
          },
        },
      };

      if (receivableInvoiceCategories.includes(currentCategory)) {
        row.utils.sendInvoice = async (invoice: Invoice) => {
          await sendInvoice(invoice);
        };
      }
      return row;
    });
  }, [invoicesStore.invoices, jobsById, currentCategory]);

  const columns = useMemo(() => {
    return [
      colsDef.scheduledTimeColDef,
      colsDef.jobIdTimeColDef,
      colsDef.vendorColDef,
      colsDef.customerColDef,
      colsDef.dispatchNoColDef,
      colsDef.driverNameColDef,
      colsDef.equipmentTypeColDef,
      colsDef.pickupColDef,
      colsDef.dropoffColDef,
      colsDef.driverJobStartColDef,
      colsDef.geofenceEntryColDef,
      colsDef.payStartColDef,
      colsDef.driverJobEndColDef,
      colsDef.geofenceExitColDef,
      colsDef.payEndColDef,
      colsDef.truckIdColDef,
      colsDef.totalBreakTimeColDef,
      colsDef.totalHoursColDef,
      colsDef.loadsColDef,
      colsDef.materialColDef,
      colsDef.unitsColDef,
      colsDef.quantityColDef,
      colsDef.rateTypeColDef,
      colsDef.rateColDef,
      colsDef.totalQtyColDef,
      colsDef.totalPayColDef,
      colsDef.statusColDef,
      colsDef.actionsColDef(() => (
        <HeaderNavigation
          count={invoicesStore.invoices.length}
          loading={isLoading}
          pagination={invoicesStore.pagination}
          callback={handlePageChange}
        />
      )),
    ];
  }, [
    isLoading,
    invoicesStore.invoices,
    invoicesStore.pagination,
    invoicesStore.searchParams,
  ]);

  const gridInitialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          [colsDef.geofenceEntryColDef.field]: false,
          [colsDef.geofenceExitColDef.field]: false,
        },
      },
    }),
    [],
  );

  const handleSettlementsFormSubmit = useCallback(() => {
    settlementsFormRef.current?.onSubmit?.((data) => {
      createSettlement(data).then((response) => {
        toasterStore.push(
          alert(
            t('approvals.driver_pay.settlements.settlement_created', {
              name: response.settlementId,
            }),
            AlertTypes.success,
          ),
        );
        dialogHandler.close();
        updateSelectedInvoices([]);
      });
    });
  }, []);

  const handlePageChange = useCallback((pagination: PaginationLink) => {
    getInvoices(pagination);
  }, []);

  useEffect(() => {
    dialogHandler.updateBusyState(isCreatingSettlement);
  }, [isCreatingSettlement]);

  useEffect(() => {
    if (missingJobIds.length) {
      getJobsByJobIds(missingJobIds).then((jobs) => {
        jobStore.addJobs(jobs);
      });
    }
  }, [missingJobIds.toString()]);

  useEffect(() => {
    getInvoices();
  }, [JSON.stringify(invoicesStore.searchParams)]);

  useEffect(() => {
    let subscription: ReturnType<typeof subscribeToInvoiceRTU> | null = null;

    if (userStore.userCompany.id) {
      subscription = subscribeToInvoiceRTU(userStore.userCompany.id);
    }

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [userStore.userCompany.id]);

  return (
    <>
      <Box className="dispatch-datagrid">
        <DataGrid
          id="driver-pay-approvals-grid"
          sx={{
            "& .MuiDataGrid-cell[data-field='actions']": { px: '5px' },
            '& .MuiDataGrid-columnHeaderTitle': { fontSize: '10px' },
            ...sx,
          }}
          columns={columns}
          rows={rows}
          loading={isLoading}
          initialState={gridInitialState}
          getDetailPanelContent={(args) => {
            const { row } = args as unknown as colsDef.DriverPayRowDef;

            if (row.job) {
              return (
                <DriverPayDataGridExpandedBlock
                  invoice={row.invoice}
                  job={row.job}
                  utils={row.utils}
                />
              );
            }
          }}
          disableColumnFilter
          selectedRowIDs={shouldAllowBulkActions ? selectedInvoices : undefined}
          onSelectedRowIDsChange={
            shouldAllowBulkActions ? updateSelectedInvoices : undefined
          }
          bottomBarProps={
            shouldAllowBulkActions
              ? {
                  elements: (
                    <DataGridBottomBarButton
                      color="success"
                      onClick={() => dialogHandler.open()}
                      startIcon={<Check />}
                    >
                      {t('approvals.driver_pay.create_settlement')}
                    </DataGridBottomBarButton>
                  ),
                }
              : undefined
          }
          hideExport
        />
      </Box>

      <SettlementForm
        ref={settlementsFormRef}
        invoiceIds={selectedInvoices}
        isBusy={dialogHandler.isBusy}
        isOpen={dialogHandler.isOpen}
        onClose={dialogHandler.close}
        onSubmit={handleSettlementsFormSubmit}
      />
    </>
  );
});

export default DriverPayDataGrid;
