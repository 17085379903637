import {
  Account_Read as AccountProto,
  Account_Read_Typeahead,
  AccountType,
  getV1Accounts,
  getV1CompaniesCompanyIdAccounts,
  postV1CompaniesCompanyIdAccountsInvite,
  RateOwnerType,
} from '@treadinc/horizon-api-spec';
import type { GetV1AccountsData } from '@treadinc/horizon-api-spec/types/types.gen';
import { AxiosError } from 'axios';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { AccountTypesFilter } from '~constants/enums';
import { Account, AccountTypeahead } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

interface PaginateOnScrollParams {
  cursor?: string;
  query?: string;
}
interface CreateAccountParams {
  account: Account;
  callBack?: (account: Account) => void;
}
interface DeleteAccountParams {
  id: string;
  callBack?: () => void;
  errorCallback?: (error: AxiosError) => void;
}
interface GetAccountsParams {
  companyId?: string;
  accountTypes?: AccountTypesFilter[] | RateOwnerType[];
  link?: PaginationLink;
  limit?: number;
  searchQuery?: string;
  callback?: (accounts: Account[]) => void;
}
interface GetAccountsByCompanyIdParams {
  companyId: string;
  callBack?: (accounts: Account[]) => void;
}
interface GetAccountsByCompanyIdPaginatedParams extends GetAccountsByCompanyIdParams {
  link?: PaginationLink;
  limit?: number;
}
interface GetAccountByIdParams {
  account: Account;
  callBack?: (account: Account) => void;
}
export interface ConnectAccountByTreadIdParams {
  senderCompanyId: string;
  receiverTreadId: string;
  accountTypes: AccountType[];
  callBack?: (account: Account) => void;
}
interface GetAccountsTypeaheadProps extends PaginateOnScrollParams {
  callback?: (accounts: AccountTypeahead[]) => void;
  companyId: string;
  accountTypes?: AccountType[];
  searchParams?: {
    limit?: number;
    query?: string;
    accountTypes?: AccountType[];
    isConnected?: boolean;
    link?: {
      type: PaginationLink;
      cursor: string;
    };
  };
}

interface GetAccountsTypeaheadQueryProps {
  'filter[account_types]': AccountType[];
  'filter[connected]': boolean;
  'page[limit]': number;
  'page[before]': string;
  'page[after]': string;
  'search[query]': string;
}

export const useAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { companyAssetsStore, jobStore, companyAdminStore } = useStores();
  const updateAccount = async ({ account, callBack }: any) => {
    const connectedPrimaryContactName = `${account.primaryContact.firstName} ${account.primaryContact.lastName}`;
    const connectedBillingContactName = `${account.billingContact.firstName} ${account.billingContact.lastName}`;
    const isConnectedAccount = !!account.connectedCompany;

    const primaryContactName = isConnectedAccount
      ? connectedPrimaryContactName
      : account.primaryContact.name;
    const billingContactName = isConnectedAccount
      ? connectedBillingContactName
      : account.billingContact.name;

    const primaryContact = {
      ...account.primaryContact,
      name: primaryContactName || ' ',
      email: account.primaryContact.email || ' ',
      phone: account.primaryContact.phone || ' ',
    };
    const billingContact = {
      ...account.billingContact,
      name: billingContactName || ' ',
      email: account.billingContact.email || ' ',
      phone: account.billingContact.phone || ' ',
    };

    const updatedAccount = {
      ...account,
      primaryContact,
      billingContact,
      autoAcceptOrders:
        account.autoAcceptOrders?.value ?? account.autoAcceptOrders ?? false,
    };
    setIsLoading(true);
    try {
      const resp = await connection.patch<AccountProto>(
        `${API_VERSION}/accounts/${account?.id}`,
        Account.deparseUpdate(updatedAccount),
        {},
        $t('error_messages.account.failed_to_update') as string,
        [422],
      );
      const parsedAccount = Account.parse(resp);
      companyAssetsStore.updateAccount(parsedAccount);
      callBack?.(parsedAccount);
      return parsedAccount;
    } finally {
      setIsLoading(false);
    }
  };
  const getAccountsByCompanyId = async ({
    companyId,
    callBack,
  }: GetAccountsByCompanyIdParams) => {
    setIsLoading(true);
    await connection
      .get<AccountProto[]>(
        `${API_VERSION}/companies/${companyId}/accounts`,
        {},
        $t('error_messages.account.failed_to_fetch_company_accounts') as string,
      )
      .then((resp: AccountProto[]) => {
        const formatted = resp.map(Account.parse);
        jobStore.setCompanyAccounts(companyId, formatted);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAccountsByCompanyIdPaginated = async ({
    companyId,
    callBack,
    link,
    limit,
  }: GetAccountsByCompanyIdPaginatedParams) => {
    const params: PaginationQuery = {
      'page[limit]': limit ?? companyAdminStore.accountsPagination.limit,
    };

    if (link && companyAdminStore.accountsPagination[link]) {
      params[`page[${link}]`] = companyAdminStore.accountsPagination[link];
    }

    setIsLoading(true);
    await connection
      .getPaginated<AccountProto>(
        `${API_VERSION}/companies/${companyId}/accounts`,
        { params },
        $t('error_messages.account.failed_to_fetch_company_accounts') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Account.parse);
        companyAdminStore.setAccounts(formatted);
        companyAdminStore.setAccountsPagination(pagination);
        companyAdminStore.updateAccountsPageNumber(link);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAccountsByCompanyIdTypeahead = async ({
    companyId,
    callback,
    cursor,
    query,
    accountTypes,
    searchParams = {
      limit: 50,
      isConnected: false,
    },
  }: GetAccountsTypeaheadProps) => {
    const params: Partial<GetAccountsTypeaheadQueryProps> = {
      'page[limit]': searchParams?.limit ?? 30,
    };

    if (searchParams?.query || query) {
      params['search[query]'] = searchParams?.query || query;
    }

    if (searchParams?.accountTypes) {
      params['filter[account_types]'] = searchParams?.accountTypes;
    }

    if (searchParams?.isConnected) {
      params['filter[connected]'] = searchParams?.isConnected;
    }

    if (accountTypes) {
      params['filter[account_types]'] = accountTypes;
    }

    if (cursor) {
      params['page[after]'] = cursor;
    } else if (
      searchParams?.link &&
      searchParams?.link.type &&
      searchParams?.link.cursor
    ) {
      params[`page[${searchParams?.link.type}]`] = searchParams?.link.cursor;
    }

    try {
      setIsLoading(true);
      const response = await connection
        .getPaginated<Account_Read_Typeahead>(
          `${API_VERSION}/companies/${companyId}/accounts/typeahead`,
          { params },
          $t('error_messages.account.failed_to_fetch_company_accounts') as string,
        )
        .then(({ data, pagination }) => {
          const formattedAccounts = data.map((account) => {
            return AccountTypeahead.parse(account);
          });
          callback?.(formattedAccounts);

          return { data: formattedAccounts, pagination };
        });

      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllCompanyAccounts = async ({
    companyId,
    link,
    limit,
    accountTypes,
    searchQuery,
    callback,
  }: GetAccountsParams = {}) => {
    setIsLoading(true);
    const query: GetV1AccountsData['query'] = {
      'page[limit]': limit || companyAssetsStore.accountsPagination.limit,
    };
    if (searchQuery) {
      query['search[datagrid]'] = searchQuery;
    }
    if (link && companyAssetsStore.accountsPagination[link]) {
      query[`page[${link}]`] = companyAssetsStore.accountsPagination[link];
    }
    if (accountTypes && accountTypes.length) {
      query['filter[account_types]'] = accountTypes;
    }

    try {
      const response = companyId
        ? await getV1CompaniesCompanyIdAccounts({
            path: { 'company-id': companyId },
            query,
          })
        : await getV1Accounts({ query });
      const formatted = response.data.data.map(Account.parse);
      companyAssetsStore.setUserCompanyAccounts(formatted);
      const pagination = extractPagination(response);
      companyAssetsStore.setAccountsPagination(pagination);
      companyAssetsStore.updateAccountsPageNumber(link);
      callback?.(formatted);
    } catch (e) {
      console.error(e);
      connection.handleRequestError(
        e,
        $t('error_messages.account.failed_to_fetch_company_accounts') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAccountById = async ({ account, callBack }: GetAccountByIdParams) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<AccountProto>(
        `${API_VERSION}/accounts/${account.id}`,
        {},
        $t('error_messages.account.failed_to_fetch_selected') as string,
      );
      const formatted = Account.parse(resp);
      callBack?.(formatted);
    } finally {
      setIsLoading(false);
    }
  };

  const getAccountByIdString = async (id: string) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<AccountProto>(
        `${API_VERSION}/accounts/${id}`,
        {},
        $t('error_messages.account.failed_to_fetch_selected') as string,
      );
      const formatted = Account.parse(resp);
      return formatted;
    } finally {
      setIsLoading(false);
    }
  };

  const createAccount = async ({ account, callBack }: CreateAccountParams) => {
    setIsLoading(true);
    try {
      const autoAcceptOrders =
        typeof account.autoAcceptOrders === 'object' &&
        'value' in account.autoAcceptOrders
          ? account.autoAcceptOrders.value
          : account.autoAcceptOrders || false;

      const parsedAccount = {
        ...account,
        autoAcceptOrders,
      };

      await connection
        .post<AccountProto>(
          `${API_VERSION}/accounts`,
          Account.deparse(parsedAccount as Account),
          {},
          $t('error_messages.account.failed_to_create') as string,
          [422],
        )
        .then((resp) => {
          const accountSaved = Account.parse(resp);
          companyAssetsStore.addAccount(accountSaved);
          callBack?.(accountSaved);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const createConnectedAccount = async ({ account }: { account: any }) => {
    const fullName = `${account.primaryContact.firstName} ${account.primaryContact.lastName}`;
    const invitesToSend = {
      email: account.inviteTypes.includes('email'),
      sms: account.inviteTypes.includes('sms'),
    };
    const updatedAccount = {
      ...account,
      primaryContact: {
        ...account.primaryContact,
        name: fullName,
      },
      invitesToSend,
      autoAcceptOrders:
        account.autoAcceptOrders?.value ?? account.autoAcceptOrders ?? false,
    };

    setIsLoading(true);
    const url = `${API_VERSION}/accounts/invite`;
    return await connection
      .post<AccountProto>(
        url,
        Account.deparseConnectedAccount(updatedAccount),
        {},
        $t('error_messages.account.failed_to_create_connected') as string,
        [422],
      )
      .then((resp) => {
        const accountSaved = Account.parse(resp);
        companyAssetsStore.addAccount(accountSaved);
        return accountSaved;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createConnectedAccountByCompanyId = async ({
    account,
  }: {
    account: Account & {
      primaryContact: { firstName: string; lastName: string };
      inviteTypes: string[];
      autoAcceptOrders: boolean | { value: boolean };
    };
  }) => {
    const fullName = `${account.primaryContact.firstName} ${account.primaryContact.lastName}`;
    const invitesToSend = {
      email: account.inviteTypes.includes('email'),
      sms: account.inviteTypes.includes('sms'),
    };
    const autoAcceptOrders =
      typeof account.autoAcceptOrders === 'object'
        ? account.autoAcceptOrders.value
        : account.autoAcceptOrders;
    const companyId = (account.company as CompanyBasic).id;
    const updatedAccount = {
      ...account,
      primaryContact: {
        ...account.primaryContact,
        name: fullName,
      },
      invitesToSend,
      autoAcceptOrders: autoAcceptOrders ?? false,
    };

    try {
      setIsLoading(true);
      const response = await postV1CompaniesCompanyIdAccountsInvite({
        path: { 'company-id': companyId },
        body: Account.deparseConnectedAccount(updatedAccount),
      });
      const accountSaved = Account.parse(response.data.data);
      companyAssetsStore.addAccount(accountSaved);
      return accountSaved;
    } catch (error) {
      console.error(error);
      connection.handleRequestError(
        error,
        $t('error_messages.account.failed_to_create_connected') as string,
        [422],
      );
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccount = async ({ id, callBack, errorCallback }: DeleteAccountParams) => {
    setIsLoading(true);
    try {
      await connection.delete<AccountProto>(
        `${API_VERSION}/accounts/${id}`,
        {},
        $t('error_messages.account.failed_to_delete') as string,
        [422],
      );
      companyAssetsStore.deleteAccount(id);
      callBack?.();
    } catch (e) {
      errorCallback?.(e as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };
  const createConnectedAccountByTreadId = async ({
    senderCompanyId,
    receiverTreadId,
    accountTypes,
    callBack,
  }: ConnectAccountByTreadIdParams) => {
    setIsLoading(true);
    try {
      const resp = await connection.post<AccountProto>(
        `${API_VERSION}/companies/${senderCompanyId}/accounts/connected`,
        {
          tread_id: receiverTreadId,
          account_types: accountTypes,
        },
        {},
        $t('error_messages.account.failed_to_create_connected_by_tread_id') as string,
      );
      const account = Account.parse(resp);
      callBack?.(account);
      return account;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateAccount,
    getAllCompanyAccounts,
    getAccountById,
    getAccountByIdString,
    getAccountsByCompanyId,
    getAccountsByCompanyIdPaginated,
    getAccountsByCompanyIdTypeahead,
    createAccount,
    createConnectedAccount,
    createConnectedAccountByCompanyId,
    createConnectedAccountByTreadId,
    deleteAccount,
    isLoading,
  } as const;
};
