import {
  Account_Connected_Read_Nested,
  Account_Create,
  Account_Invite,
  Account_Read,
  Account_Read as AccountProto,
  Account_Read_Nested as AccountNestedProto,
  Account_Read_Typeahead,
  Account_Update,
  AccountType,
} from '@treadinc/horizon-api-spec';

import { AddressItem } from '~hooks/useAddress';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { Nullable } from '~types/Nullable';
import { compareValues } from '~utils/utilFunctions';

export class AccountTypeahead {
  constructor(
    private _id: string,
    private _name: string,
    private _connected_company: { id: string } | null,
  ) {}

  public static parse(proto: Account_Read_Typeahead) {
    return new AccountTypeahead(proto.id, proto.name, proto.connected_company ?? null);
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get connectedCompanyId() {
    return this._connected_company?.id ?? null;
  }
}

export class AccountBasic {
  public get id(): string {
    return this._id;
  }
  public get name(): string {
    return this._name;
  }
  public get accountBasicCompanyId() {
    return this._account_read_nested_company?.id;
  }
  public static parse(proto: AccountNestedProto): AccountBasic {
    return new AccountBasic(proto.name ?? '', proto.id ?? '', proto.company);
  }
  constructor(
    private _name: string,
    private _id: string,
    // @syntaxbliss: needed to name this to something different from '_company' to avoid conflicts with the 'Account'
    // Class, which is wrongly mixing 'Account_Read' and 'Account_Read_Nested' types. for that same reason there is
    // No 'deparse' method in this class.
    private _account_read_nested_company?: { id: string },
  ) {}
}

class ConnectedAccountBasic {
  public get id(): string {
    return this._id;
  }

  public get accountTypes() {
    return this._account_types;
  }

  public get truckCount() {
    return this._truck_count;
  }

  public get autoAcceptOrders() {
    return this._auto_accept_orders;
  }

  public static parse(proto: Account_Connected_Read_Nested): ConnectedAccountBasic {
    return new ConnectedAccountBasic(
      proto.id,
      proto.account_types,
      proto.truck_count,
      proto.auto_accept_orders,
    );
  }

  constructor(
    private _id: string,
    private _account_types: AccountType[],
    private _truck_count: number,
    private _auto_accept_orders: boolean,
  ) {}
}

export class Account extends AccountBasic {
  public static parse(proto: AccountProto | any): Account {
    return new Account(
      proto,
      proto.connected_company ? CompanyBasic.parse(proto.connected_company) : null,
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto?.created_at ?? null,
      proto?.updated_at ?? null,
      proto?.address ? AddressItem.parse(proto.address) : null,
      proto?.billing_address ? AddressItem.parse(proto.billing_address) : null,
      proto.account_types?.length ? [...proto.account_types] : [],
      proto?.department ?? '',
      proto?.primary_contact ? ContactItem.parse(proto.primary_contact) : null,
      proto?.billing_contact ? ContactItem.parse(proto.billing_contact) : null,
      proto?.notes ?? '',
      proto?.external_id ?? '',
      compareValues(proto.billing_address, proto.address, [
        'id',
        'place_id',
        'created_at',
        'updated_at',
      ]),
      compareValues(proto.billing_contact, proto.primary_contact, ['id']),
      proto?.auto_accept_orders || false,
      proto?.truck_count || null,
      proto?.connected_account || null,
    );
  }

  public static deparseUpdate(proto: Account): Account_Update {
    const data = {
      // Parent_company_id: null,
      name: proto.name,
      // Company_id: proto.connectedCompany?.id || undefined,
      department: proto.department,
      primary_contact: proto.primaryContact
        ? ContactItem.deparse(proto.primaryContact as ContactItem)
        : undefined,
      billing_contact: proto.isBillingContactSame
        ? ContactItem.deparse(proto.primaryContact as ContactItem)
        : proto.billingContact
          ? ContactItem.deparse(proto.billingContact as ContactItem)
          : undefined,
      address: AddressItem.deparse(proto.address as AddressItem),
      billing_address: proto?.isBillingAddressSame
        ? AddressItem.deparse(proto.address as AddressItem)
        : AddressItem.deparse(proto.billingAddress) || undefined,
      notes: proto.notes,
      account_types: proto.accountTypes,
      external_id: proto.externalId,
    } as any;

    // Update these fields if account is connected and it's a CUSTOMER
    if (proto.connectedCompany && proto.accountTypes.includes(AccountType.CUSTOMER)) {
      data.truck_count = proto.truckCount || 0;
      data.auto_accept_orders = proto.autoAcceptOrders;
    }

    return data;
  }
  public static deparse(proto: Account): Account_Create {
    const data = {
      // Parent_company_id: null,
      name: proto.name,
      company_id: proto.company?.id || proto.connectedCompany?.id || undefined,
      department: proto.department || '',
      primary_contact: proto.primaryContact
        ? ContactItem.deparse(proto.primaryContact as ContactItem)
        : undefined,
      billing_contact: proto.isBillingContactSame
        ? ContactItem.deparse(proto.primaryContact as ContactItem)
        : proto.billingContact
          ? ContactItem.deparse(proto.billingContact as ContactItem)
          : undefined,
      address: AddressItem.deparse(proto.address as AddressItem),
      billing_address: proto?.isBillingAddressSame
        ? AddressItem.deparse(proto.address as AddressItem)
        : AddressItem.deparse(proto.billingAddress) || undefined,
      notes: proto.notes,
      account_types: proto.accountTypes,
      external_id: proto.externalId,
      auto_accept_orders: proto.autoAcceptOrders ? proto.autoAcceptOrders : false,
    } as any;

    // ?? maybe ID is required in case of edit company
    return data as Account_Create;
  }

  // Todo: specify interface as far BE be ready
  public static deparseConnectedAccount(proto: any): Account_Invite {
    const data = {
      name: proto.name,
      contact: proto.primaryContact
        ? ContactItem.deparse(proto.primaryContact)
        : undefined,
      address: AddressItem.deparse(proto.address),

      // New fields
      company_id: proto.connectedCompany?.id || undefined,
      department: proto.department || '',
      notes: proto.notes,
      account_types: proto.accountTypes,
      external_id: proto.externalId,
      auto_accept_orders: proto.autoAcceptOrders || false,
      truck_count: proto.truckCount === null ? 0 : proto.truckCount,
      send_invite: proto.invitesToSend ?? { email: false, sms: false },
      company_type: proto.companyType || undefined,
    };

    return data as Account_Invite;
  }

  public get createdAt(): string {
    return this._created_at;
  }
  public get updatedAt(): string {
    return this._updated_at;
  }
  public get connectedCompany(): Nullable<CompanyBasic> {
    return this._connected_company;
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }
  public get department(): string {
    return this._department;
  }

  public get address(): Nullable<AddressItem> {
    return this._address;
  }
  public get billingAddress(): Nullable<AddressItem> {
    return this._billing_address;
  }
  public get primaryContact(): Nullable<ContactItem> {
    return this._primary_contact;
  }

  public get billingContact(): Nullable<ContactItem> {
    return this._billing_contact;
  }

  public get notes(): string {
    return this._notes;
  }
  public get externalId(): string {
    return this._externalId;
  }
  public get accountTypes(): string[] {
    return this._account_types;
  }
  public get isBillingAddressSame(): boolean {
    return this._isBillingAddressSame;
  }
  public get isBillingContactSame(): boolean {
    return this._isBillingContactSame;
  }
  public get isCustomerAccount(): boolean {
    return this._account_types.includes(AccountType.CUSTOMER);
  }
  public get isVendorAccount(): boolean {
    return this._account_types.includes(AccountType.VENDOR);
  }
  public get autoAcceptOrders(): boolean | { value: boolean } {
    return this._auto_accept_orders || false;
  }
  public get truckCount(): number | null {
    return this._truck_count;
  }

  public get allowToSetTruckCount(): boolean {
    // @ts-ignore
    return this.isCustomerAccount && this.truckCount > 0;
  }

  public get connectedAccount(): Nullable<ConnectedAccountBasic> {
    return this._connected_account;
  }

  constructor(
    proto: AccountProto,
    private _connected_company: Nullable<CompanyBasic>,
    private _company: Nullable<CompanyBasic>,
    private _created_at: string,
    private _updated_at: string,
    private _address: Nullable<AddressItem>,
    private _billing_address: Nullable<AddressItem>,
    private _account_types: string[],
    private _department: string,
    private _primary_contact: Nullable<ContactItem>,
    private _billing_contact: Nullable<ContactItem>,
    private _notes: string,
    private _externalId: string,
    private _isBillingAddressSame: boolean,
    private _isBillingContactSame: boolean,
    private _auto_accept_orders: boolean,
    private _truck_count: number | null,
    private _connected_account?: Nullable<ConnectedAccountBasic>,
  ) {
    super(proto.name, proto.id as string); //As string due to BE conditional id?
  }
}
