import * as yup from 'yup';

import { nameIdNonRequiredSchema, nameIdSchemaRequired } from '~constants/regexConst';
import { Ticket } from '~hooks/useTickets';

export const ticketFormSchema = yup.object().shape({
  id: yup.string().notRequired(),
  ticketNumber: yup.string().trim().min(1).max(15).required(),
  quantity: yup.number().required(),
  unitOfMeasure: nameIdSchemaRequired,
  imageUrl: yup.string(),
  jobId: yup.string().trim().uuid().required(),
  material: nameIdNonRequiredSchema,
});
export const setDefaultTicketValues = (ticket: Ticket | undefined, jobId: string) => {
  const defaultValues: TicketFormDTO = {
    id: ticket?.id ?? '',
    ticketNumber: ticket?.ticketNumber ?? '',
    imageUrl: ticket?.imageUrl ?? '',
    jobId: jobId,
    unitOfMeasure: {
      id: ticket?.unitOfMeasure?.id ?? '',
      name: ticket?.unitOfMeasure?.name ?? '',
    },
    quantity: (ticket?.quantity ?? '') as unknown as number,
    material: {
      id: ticket?.material?.id ?? '',
      name: ticket?.material?.name ?? '',
    },
  };

  return defaultValues;
};
export type TicketFormDTO = yup.InferType<typeof ticketFormSchema>;
