import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import {
  FileAttachment_Read,
  RateOwnerType,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import _ from 'lodash';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  ControllerProps,
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import FileAttachmentsReadOnly from '~components/Files/FileAttachmentsReadOnly';
import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { Contacts } from '~components/Order/Contacts';
import { OrderCustomerDetails } from '~components/Order/OrderCustomerDetails';
import { OrderDetails } from '~components/Order/OrderDetails';
import { ProjectDetails } from '~components/Order/ProjectDetails';
import { SiteSelection } from '~components/Order/SiteSelection';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { NoteTypes } from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { Notes } from '~formsShared/components/Notes';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { Order } from '~hooks/useOrders';
import { Project, useProjects } from '~hooks/useProjects';
import { Phase, ProjectBasic, ProjectMaterialTotal } from '~hooks/useProjects/models';
import { RateBasicWithValue, useRates } from '~hooks/useRates';
import { Site, SiteBasic, useSites, WayPoint } from '~hooks/useSites';
import { User, useUsers } from '~hooks/useUsers';
import {
  newOrderFormSchema,
  setDefaultOrderValues,
} from '~pages/Sales/Orders/newOrderFormSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

import FileAttachments, {
  FileAttachmentsHandler,
} from '../../../formsShared/components/FileAttachments';
import RateDetails, { RateDetailsHandler } from './NewOrderFormComponents/RateDetails';
import { RateDetailsDTO } from './NewOrderFormComponents/schema';
import { rateToRateBasicWithValue } from './NewOrderFormComponents/utils';

interface AddDefaultSiteParams {
  siteId: string;
  waypointType: WaypointType;
}

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteParams>;

interface CreateUpdateOrderFormFormProps {
  defaultOrder: Order | null;
  onFormStateChange: ({
    isValid,
    isDirty,
    siteId,
    waypointType,
  }: NewOrderFormStateChange) => void;
  company?: Nullable<CompanyBasic>;
}

//Keep till we have way points in UI and not sites only wrapped into way point
export const createWaypoint = (
  site: SiteBasic | Site,
  type: string,
  ordinality: number,
  waypointID?: string,
  contact?: ContactItem,
) => {
  return {
    id: waypointID,
    type,
    ordinality,
    contact: {
      name: contact?.name ?? '',
      phone: contact?.phone ?? '',
    } as unknown as Nullable<ContactItem>,
    site: {
      id: site.id,
      name: site.name,
      lat: site.lat,
      lon: site.lon,
      address: (site as Site).address,
    },
  } as unknown as WayPoint;
};

export interface NewOrderFormHandler {
  submit: (callback: () => void) => void;
  updateProjectDefaultSites: () => void;
  resetForm: (callback?: () => void) => void;
  fileAttachmentsOnSubmit: (orderId: string) => void;
  fileAttachmentsOnClose: () => void;
}

const NewOrderForm = forwardRef(function ProjectForm(
  { defaultOrder, onFormStateChange }: CreateUpdateOrderFormFormProps,
  ref: Ref<NewOrderFormHandler>,
) {
  const fileAttachmentsRef = useRef<FileAttachmentsHandler>(null);
  const { userStore } = useStores();
  const { addSiteToProjectDefaultSites, getProjectDefaultSites, getOrderDefaultSites } =
    useSites();
  const { getProjectById } = useProjects();
  const { getCompanySites } = useSites();
  const { getAccountRatesUsingOwnerType } = useRates();
  const { getCompanyUsersTypeahead } = useUsers();

  const [primaryRates, setPrimaryRates] = useState<
    Partial<Record<RateOwnerType, RateBasicWithValue | undefined>>
  >({});
  const [projectData, setProjectData] = useState<Project | null>(null);
  const [showPickUpContact, setShowPickUpContact] = useState<boolean>(false);
  const [showDropOffContact, setShowDropOffContact] = useState<boolean>(false);
  const currentCompany = userStore.currentCompanies[0] || userStore.user.company;
  const isEditingOrder = Boolean(defaultOrder?.id);

  const formMethods = useForm<any>({
    resolver: yupResolver(newOrderFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...setDefaultOrderValues(defaultOrder as Order, currentCompany),
      jobStartSameAsOrderStart: _.isNil(defaultOrder?.jobStartAt),
      projectMaterialTotals: [] as ProjectMaterialTotal[],
      allPhaseCodes: [] as Phase[],
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors, isValid, isDirty, isSubmitted },
  } = formMethods;
  const { fields: projectMaterialTotalsFields } = useFieldArray({
    control,
    name: 'projectMaterialTotals',
  });
  const { project, company, service, account, phase } = watch();
  const watchedJobStartSameAsOrderStart = watch('jobStartSameAsOrderStart');
  const watchedLoadAt = watch('loadAt');
  const watchedPickUpWaypointSite = watch('pickUpWayPoint.site');
  const watchedDropOffWaypointSite = watch('dropOffWayPoint.site');

  const projectPhases = projectData?.phases;
  const projectMaterialTotals = projectData?.projectMaterialTotals;
  const projectNotes = projectData?.notes;
  const projectOrderNotes = projectData?.orderNotes;
  const projectJobNotes = projectData?.jobNotes;
  const projectInternalNotes = projectData?.internalNotes;
  const projectAccount = projectData?.account;
  const projectDepartment = projectData?.department;
  const projectPickUpWayPoints = projectData?.pickUpWayPoints;
  const projectDropOffWayPoints = projectData?.dropOffWayPoints;
  const defaultPickUpWaypoint =
    defaultOrder?.pickUpWayPoints?.[0] || projectPickUpWayPoints?.[0];
  const defaultDropOffWaypoint =
    defaultOrder?.dropOffWayPoints?.[0] || projectDropOffWayPoints?.[0];

  const [pickUpWaypoint, setPickUpWaypoint] =
    useState<Nullable<WayPoint>>(defaultPickUpWaypoint);
  const [dropOffWaypoint, setDropOffWaypoint] =
    useState<Nullable<WayPoint>>(defaultDropOffWaypoint);

  const customerDetailsRef = useRef<RateDetailsHandler>(null);
  const vendorDetailsRef = useRef<RateDetailsHandler>(null);
  const driverDetailsRef = useRef<RateDetailsHandler>(null);

  const fetchRates = useCallback(
    (ownerType: RateOwnerType, args?: { query: string; cursor: string }) => {
      if (company?.id) {
        return getAccountRatesUsingOwnerType({
          ownerType,
          includeDefaultRates: true,
          accountId: account?.id,
          projectId: project?.id,
          serviceId: service?.id,
          query: args?.query,
          cursor: args?.cursor,
          limit: 15,
        }).then((response) => {
          const { basicRates, primaryRate } = response.data.reduce(
            (obj, rate) => {
              obj.basicRates.push(rateToRateBasicWithValue(rate));

              if (rate.isPrimary) {
                obj.primaryRate = rateToRateBasicWithValue(rate);
              }

              return obj;
            },
            { basicRates: [] } as {
              basicRates: RateBasicWithValue[];
              primaryRate?: RateBasicWithValue;
            },
          );

          setPrimaryRates((state) => ({ ...state, [ownerType]: primaryRate }));

          return { ...response, data: basicRates };
        });
      }
    },
    [company?.id, account?.id, project?.id, service?.id],
  );

  const fetchCustomerRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.CUSTOMER, args);
    },
    [fetchRates],
  );

  const fetchVendorRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.VENDOR, args);
    },
    [fetchRates],
  );

  const fetchDriverRates = useCallback(
    (args?: { query: string; cursor: string }) => {
      return fetchRates(RateOwnerType.DRIVER, args);
    },
    [fetchRates],
  );

  useEffect(() => {
    if (!(RateOwnerType.CUSTOMER in primaryRates)) {
      fetchCustomerRates();
    }

    if (!(RateOwnerType.VENDOR in primaryRates)) {
      fetchVendorRates();
    }

    if (!(RateOwnerType.DRIVER in primaryRates)) {
      fetchDriverRates();
    }
  }, [
    fetchCustomerRates,
    fetchVendorRates,
    fetchDriverRates,
    RateOwnerType.CUSTOMER in primaryRates,
    RateOwnerType.VENDOR in primaryRates,
    RateOwnerType.DRIVER in primaryRates,
  ]);

  useEffect(() => {
    if (!isEditingOrder) {
      setValue('requester', userStore.user);
    }
  }, []);

  // Set 'company' value to enable project, sites, accounts selection etc.//
  useEffect(() => {
    if (defaultOrder?.company) {
      setValue('company', defaultOrder?.company);
      trigger('company');
    }
  }, [defaultOrder?.company]);

  // Necessary for mimicking 'onChange' revalidation
  useEffect(() => {
    if (isSubmitted) {
      trigger('pickUpWayPoint');
      trigger('dropOffWayPoint');
    }
  }, [watchedPickUpWaypointSite?.id, watchedDropOffWaypointSite?.id, isSubmitted]);

  useEffect(() => {
    getProjectById(project?.id || '').then((project) => {
      setProjectData(project);
    });
  }, [project]);

  useEffect(() => {
    if (watchedJobStartSameAsOrderStart) {
      setValue('jobStartAt', watchedLoadAt);
    }
  }, [watchedLoadAt, watchedJobStartSameAsOrderStart]);

  //Will request on create order all the info based on selected company
  useEffect(() => {
    if (company?.id) {
      // Set default account value if it's not specified
      if (!defaultOrder?.account?.id) {
        setValue('account', { name: '', id: '' });
      }

      // Set default project value if it's not specified
      if (!defaultOrder?.project?.id) {
        setValue('project', { name: '', id: '', externalId: '' } as ProjectBasic);
      }
      getCompanySites({ companyId: company.id });
    }
  }, [company]);

  useEffect(() => {
    if (defaultOrder?.orderId) {
      if (
        defaultOrder?.pickUpWayPoints?.[0]?.site?.id !== watchedPickUpWaypointSite?.id
      ) {
        setValue('pickUpWayPoint.id', `${defaultOrder?.pickUpWayPoints?.[0]?.id}`);
      }
      if (
        defaultOrder?.dropOffWayPoints?.[0]?.site?.id !== watchedDropOffWaypointSite?.id
      ) {
        setValue('dropOffWayPoint.id', `${defaultOrder?.dropOffWayPoints?.[0]?.id}`);
      }
    }
  }, [watchedPickUpWaypointSite, watchedDropOffWaypointSite]);

  useEffect(() => {
    setValue('projectExternalId', project?.externalId || '');
    !defaultOrder?.account?.id?.length && projectAccount?.id?.length
      ? setValue('account', {
          id: projectAccount.id,
          name: projectAccount.name,
        })
      : null;

    // If there is a new order, then we can preload the department from the selected project if none was previously selected
    !defaultOrder?.orderId &&
    !defaultOrder?.department?.id?.length &&
    projectDepartment?.id?.length
      ? setValue('department', {
          id: projectDepartment.id,
          name: projectDepartment.name,
        })
      : null;

    setValue('projectNotes', projectNotes || '');
    !defaultOrder?.notes && setValue('orderNotes', projectOrderNotes || '');
    !defaultOrder?.jobNotes && setValue('jobNotes', projectJobNotes || '');
    !defaultOrder?.internalNotes && setValue('internalNotes', projectInternalNotes || '');
  }, [projectNotes, projectAccount, projectDepartment]);

  useEffect(() => {
    setValue('phaseCode', phase?.code || '');
  }, [phase]);

  useEffect(() => {
    setValue('projectMaterialTotals', projectMaterialTotals);
  }, [projectMaterialTotals]);

  useEffect(() => {
    setValue('allPhaseCodes', projectPhases);
  }, [projectPhases]);

  useEffect(() => {
    onFormStateChange({ isDirty });
  }, [isValid, isDirty]);

  useEffect(() => {
    if (defaultPickUpWaypoint?.contact?.name) {
      setShowPickUpContact(true);
    }
    if (defaultDropOffWaypoint?.contact?.name) {
      setShowDropOffContact(true);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    submit: async (callBack?: (args: unknown) => void) => {
      const customerRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (customerDetailsRef.current?.onSubmit) {
            customerDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      const vendorRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (vendorDetailsRef.current?.onSubmit) {
            vendorDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      const driverRateDetails = await new Promise<RateDetailsDTO | undefined>(
        (resolve) => {
          if (driverDetailsRef.current?.onSubmit) {
            driverDetailsRef.current.onSubmit((data) => {
              resolve(data);
            });
          } else {
            resolve(undefined);
          }
        },
      );

      if (customerRateDetails && vendorRateDetails && driverRateDetails) {
        handleSubmit((formData) => {
          callBack?.({
            ...formData,
            customerRate: customerRateDetails.targetRate,
            customerRateType: customerRateDetails.targetRateType,
            customerRateValue: customerRateDetails.targetRateValue,
            vendorRate: vendorRateDetails.targetRate,
            vendorRateType: vendorRateDetails.targetRateType,
            vendorRateValue: vendorRateDetails.targetRateValue,
            driverRate: driverRateDetails.targetRate,
            driverRateType: driverRateDetails.targetRateType,
            driverRateValue: driverRateDetails.targetRateValue,
          });
        })();
      }
    },
    updateProjectDefaultSites,
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
    fileAttachmentsOnSubmit: async (orderId: string) => {
      await fileAttachmentsRef.current?.onSubmit(orderId);
    },
    fileAttachmentsOnClose: async () => {
      await fileAttachmentsRef.current?.onCancel();
    },
  }));

  // Update project default sites list for each waypoint type
  const updateProjectDefaultSitesList = useCallback(
    async ({ waypointType }: { waypointType: WaypointType }) => {
      // Only proceed if a project is selected since we're
      // Updating only the project's default sites list
      // This should only be done for existing orders
      if (project?.id && defaultOrder) {
        // Get project default sites list
        const projectSitesIds = await getProjectDefaultSites({
          projectId: project.id,
          waypointType,
        }).then((sites) => sites.map((site: Site) => site.id));
        // Get orders default sites list
        const orderSitesIds = await getOrderDefaultSites({
          orderId: defaultOrder?.id,
          waypointType,
        }).then((sites) => sites.map((site: Site) => site.id));

        // Find the new sites to add
        const newSiteIds = orderSitesIds.filter(
          (siteId) => !projectSitesIds.includes(siteId),
        );

        // Update the default project sites list if there
        // Are any new sites to add
        if (newSiteIds.length > 0) {
          return await Promise.all(
            newSiteIds.map(
              async (siteId) =>
                await addSiteToProjectDefaultSites({
                  projectId: project.id,
                  siteId,
                  waypointType,
                }),
            ),
          );
        }
      }
    },
    [defaultOrder, getOrderDefaultSites, getProjectDefaultSites, project?.id],
  );

  const updateProjectDefaultSites = async () => {
    await updateProjectDefaultSitesList({ waypointType: WaypointType.PICKUP });
    await updateProjectDefaultSitesList({ waypointType: WaypointType.DROP_OFF });
  };

  const toggleOnPickUpContact = () => {
    setShowPickUpContact(true);
  };

  const toggleOffPickUpContact = () => {
    setValue('pickUpWayPoint.contact', {
      name: '',
      phone: '',
    });

    trigger('pickUpWayPoint.contact');
    setShowPickUpContact(false);
  };

  const toggleOnDropOffContact = () => {
    setShowDropOffContact(true);
  };

  const toggleOffDropOffContact = () => {
    setValue('dropOffWayPoint.contact', {
      name: '',
      phone: '',
    });
    trigger('dropOffWayPoint.contact');
    setShowDropOffContact(false);
  };

  return (
    <Box component="div" sx={{ mt: 0 }} data-test-id="order-form">
      <FormProvider {...formMethods}>
        <Grid container spacing={2} sx={{ my: 1 }}>
          {/*Left side*/}
          <Grid item xs={6}>
            {/*Project details*/}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 1 }} title={t('order.form.project_details')} />
              <Grid container spacing={2}>
                {!defaultOrder?.orderId && (
                  <Grid item xs={12}>
                    <CompanySelectorFormField
                      name={'company'}
                      defaultValue={defaultOrder?.company}
                      errors={errors}
                      control={control}
                      isRequired={true}
                    />
                  </Grid>
                )}
                <ProjectDetails
                  control={control}
                  errors={errors}
                  companyId={company?.id}
                />
                {projectMaterialTotalsFields.map((materialTotal, index) => {
                  return (
                    <React.Fragment key={`${index}--${materialTotal.id}`}>
                      <Grid item xs={6}>
                        <TextFormField
                          control={control}
                          errors={errors}
                          name={`projectMaterialTotals[${index}].material.name`}
                          label={`${$t('form_fields.project_materials')}`}
                          isRequired={false}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFormField
                          control={control}
                          errors={errors}
                          name={`projectMaterialTotals[${index}].totalQuantity`}
                          label={`${$t('form_fields.project_quantity')}`}
                          isRequired={false}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextFormField
                          control={control}
                          errors={errors}
                          name={`projectMaterialTotals[${index}].unitOfMeasure.name`}
                          label={`${$t('form_fields.project_units')}`}
                          isRequired={false}
                          disabled
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Card>

            {/* Order customer details */}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle
                title={$t('order.form.order_customer_details')}
                sx={{ mb: 1 }}
              />
              <OrderCustomerDetails control={control} errors={errors} />

              <Grid container spacing={2} sx={{ pt: 2 }} alignItems={'center'}>
                <Grid item xs={12}>
                  <SingleDateTimeFormField
                    dateLabel={$t('order.form.start_date') as string}
                    timeLabel={$t('order.form.start_time') as string}
                    control={control}
                    errors={errors}
                    name={'loadAt'}
                    isRequired={true}
                  />
                </Grid>
                <Grid padding={0} paddingX={2} item xs={12}>
                  <CheckBoxFormField
                    errors={errors}
                    control={control}
                    name="jobStartSameAsOrderStart"
                    value={true}
                    label={`${$t('order.form.same_start_time')}`}
                  />
                </Grid>
                <Grid padding={0} paddingLeft={2} item xs={12}>
                  <SingleDateTimeFormField
                    dateLabel={$t('order.form.job_start_date') || ''}
                    timeLabel={$t('order.form.job_start_time') || ''}
                    control={control}
                    errors={errors}
                    name={'jobStartAt'}
                    isRequired={!watchedJobStartSameAsOrderStart}
                    disabled={watchedJobStartSameAsOrderStart}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="truckDelay"
                    label={`${$t('form_fields.truck_stagger')}`}
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="trucksPerDelay"
                    label={`${$t('form_fields.trucks_per_stagger')}`}
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Card>

            {/*Order details*/}
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 1 }} title={$t('order.form.order_details')} />
              <OrderDetails parentData={projectData} defaultData={defaultOrder} />
            </Card>

            <Card sx={{ p: 2 }}>
              <Box display="flex" flexDirection="column" gap={3}>
                <RateDetails
                  ref={customerDetailsRef}
                  disableTargetRateControls={!company?.id}
                  fetchRates={fetchCustomerRates}
                  isEditing={isEditingOrder}
                  rateCardControlLabel={`${t('order.form.customer_rate_card')}`}
                  targetRate={
                    defaultOrder?.customerRate ?? projectData?.customerRate ?? null
                  }
                  targetRateType={
                    defaultOrder?.customerRateType ??
                    projectData?.customerRateType ??
                    null
                  }
                  targetRateValue={
                    defaultOrder?.customerRateValue ??
                    projectData?.customerRateValue ??
                    null
                  }
                  title={`${t('order.form.rate_details')}`}
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.project'), isMatched: Boolean(project?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />

                <RateDetails
                  ref={vendorDetailsRef}
                  disableTargetRateControls={!company?.id}
                  fetchRates={fetchVendorRates}
                  isEditing={isEditingOrder}
                  rateCardControlLabel={`${t('order.form.vendor_rate_card')}`}
                  targetRate={defaultOrder?.vendorRate ?? projectData?.vendorRate ?? null}
                  targetRateType={
                    defaultOrder?.vendorRateType ?? projectData?.vendorRateType ?? null
                  }
                  targetRateValue={
                    defaultOrder?.vendorRateValue ?? projectData?.vendorRateValue ?? null
                  }
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.project'), isMatched: Boolean(project?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />

                <RateDetails
                  ref={driverDetailsRef}
                  disableTargetRateControls={!company?.id}
                  fetchRates={fetchDriverRates}
                  isEditing={isEditingOrder}
                  rateCardControlLabel={`${t('order.form.internal_driver_rate_card')}`}
                  targetRate={defaultOrder?.driverRate ?? projectData?.driverRate ?? null}
                  targetRateType={
                    defaultOrder?.driverRateType ?? projectData?.driverRateType ?? null
                  }
                  targetRateValue={
                    defaultOrder?.driverRateValue ?? projectData?.driverRateValue ?? null
                  }
                  matchedAttributes={[
                    { label: t('form_fields.account'), isMatched: Boolean(account?.id) },
                    { label: t('form_fields.project'), isMatched: Boolean(project?.id) },
                    { label: t('form_fields.service'), isMatched: Boolean(service?.id) },
                  ]}
                />
              </Box>
            </Card>
          </Grid>

          {/* Right side */}
          <Grid item xs={6}>
            {/*Locations*/}
            <Card sx={{ p: 2, mb: 2, overflow: 'visible' }}>
              <FormSectionTitle
                sx={{ mb: 2 }}
                title={$t('order.form.location_details')}
              />
              <SiteSelection
                errors={errors as { pickUpWayPoint?: object; dropOffWayPoint?: object }}
                companyId={company?.id}
                allowSuggestions={true}
                isRequired={true}
                pickUpSite={pickUpWaypoint?.site}
                dropOffSite={dropOffWaypoint?.site}
                projectId={project?.id}
                orderId={defaultOrder?.id}
                onDefaultSiteAdded={({ siteId, waypointType }: AddDefaultSiteParams) =>
                  onFormStateChange({ siteId, waypointType, isDirty: true })
                }
                onPickUpSiteChange={(site) => {
                  let newPickUpWaypoint = null;

                  if (site) {
                    newPickUpWaypoint = createWaypoint(
                      site,
                      WaypointType.PICKUP,
                      10,
                      defaultPickUpWaypoint?.id,
                      defaultPickUpWaypoint?.contact ?? undefined,
                    ) as any;
                  }

                  setValue('pickUpWayPoint', newPickUpWaypoint, {
                    shouldDirty: true,
                  });
                  setPickUpWaypoint(newPickUpWaypoint);
                }}
                onDropOffSiteChange={(site) => {
                  let newDropOffWaypoint = null;

                  if (site) {
                    newDropOffWaypoint = createWaypoint(
                      site,
                      WaypointType.DROP_OFF,
                      20,
                      defaultDropOffWaypoint?.id,
                      defaultDropOffWaypoint?.contact ?? undefined,
                    ) as any;
                  }

                  setValue('dropOffWayPoint', newDropOffWaypoint, { shouldDirty: true });
                  setDropOffWaypoint(newDropOffWaypoint);
                }}
              />
              {!showPickUpContact && (
                <PseudoLink
                  title={`+ ${$t('order.form.add_pick_up_site_contact')}`}
                  action={toggleOnPickUpContact}
                  sx={{ display: 'inline-block' }}
                />
              )}
              <Collapse in={showPickUpContact}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFormField
                      control={control}
                      errors={errors}
                      name="pickUpWayPoint.contact.name"
                      label={`${$t('form_fields.name')}`}
                      disabled={!company?.id}
                      isRequired={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneCodeFlagInput
                      key={`pickup-contact-phone-${showPickUpContact}`}
                      control={control as unknown as ControllerProps['control']}
                      errors={errors as unknown as FieldErrors}
                      name="pickUpWayPoint.contact.phone"
                      label={`${$t('form_fields.phone')}`}
                      disabled={!company?.id}
                      isRequired={false}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ '&.MuiGrid-item': { pt: '0 !important' } }}>
                    <PseudoLink
                      title={$t('order.form.remove_pick_up_site_contact')}
                      sx={{ display: 'inline-block' }}
                      action={toggleOffPickUpContact}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              {!showDropOffContact && (
                <PseudoLink
                  title={`+ ${$t('order.form.add_drop_off_site_contact')}`}
                  action={toggleOnDropOffContact}
                  sx={{ display: 'inline-block' }}
                />
              )}
              <Collapse in={showDropOffContact}>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={6}>
                    <TextFormField
                      control={control}
                      errors={errors}
                      name="dropOffWayPoint.contact.name"
                      label={`${$t('form_fields.name')}`}
                      isRequired={false}
                      disabled={!company?.id}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneCodeFlagInput
                      key={`dropoff-contact-phone-${showPickUpContact}`}
                      control={control as unknown as ControllerProps['control']}
                      errors={errors as unknown as FieldErrors}
                      name="dropOffWayPoint.contact.phone"
                      label={`${$t('form_fields.phone')}`}
                      isRequired={false}
                      disabled={!company?.id}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ '&.MuiGrid-item': { pt: '0 !important' } }}>
                    <PseudoLink
                      title={$t('order.form.remove_drop_off_site_contact')}
                      sx={{ display: 'inline-block' }}
                      action={toggleOffDropOffContact}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Card>
            {/* Contacts */}
            <Contacts parentData={projectData} defaultData={defaultOrder} />
            <Card sx={{ p: 2, mb: 2 }}>
              <FileAttachments
                fileAttachableId={defaultOrder?.id}
                fileAttachableType={FileAttachment_Read.file_attachable_type.ORDER}
                control={control}
                errors={errors}
                form={formMethods}
                name="fileAttachments"
                ref={fileAttachmentsRef}
              />
            </Card>
            {defaultOrder?.project?.id && (
              <Card sx={{ p: 2, pt: 0, mb: 2 }}>
                <FileAttachmentsReadOnly
                  label={$t('form_fields.project_attachments') as string}
                  fileAttachableId={defaultOrder?.project.id}
                  fileAttachableType={FileAttachment_Read.file_attachable_type.PROJECT}
                />
              </Card>
            )}

            <Notes defaultTab={NoteTypes.ORDER} />

            {/* Requester */}
            <Card sx={{ p: 2, my: 2 }}>
              <FormSectionTitle title={$t('order.form.requester')} sx={{ mb: 1 }} />
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                name={'requester'}
                isRequired={false}
                clearable={true}
                getValue={(user: User) => user.id}
                getLabel={(user: User) => user.fullName ?? ''}
                asyncCallback={getCompanyUsersTypeahead}
                extraRequestOptions={{
                  companyId: company?.id,
                }}
                hideLabel
              />
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
});
export { NewOrderForm };
