import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  Circle,
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';

import { GeofenceType, MarkerType } from './constants';

const apiKey = import.meta.env.TREAD__GOOGLE_MAPS_API_KEY;

type LngLat = {
  lat: number;
  lng: number;
};

interface BaseMarker {
  id: string;
  lat: number;
  lng: number;
}
interface TruckMarker extends BaseMarker {
  type: MarkerType.truck;
  label: string;
}
interface MovingSiteMarker extends BaseMarker {
  type: MarkerType.moving_site;
  radii: number[];
}

interface BaseSiteMarker extends BaseMarker {
  type: MarkerType.site;
}
interface NormalSiteMarker extends BaseSiteMarker {
  geofenceType: null;
}
interface CircleSiteMarker extends BaseSiteMarker {
  geofenceType: GeofenceType.circle;
  radius: number;
}
interface PolygonSiteMarker extends BaseSiteMarker {
  geofenceType: GeofenceType.polygon;
  // ordered as lng, lat
  coordinates: [number, number][][];
}
type SiteMarker = NormalSiteMarker | CircleSiteMarker | PolygonSiteMarker;

type TreadMarker = TruckMarker | MovingSiteMarker | SiteMarker;

type MapV2Props = {
  center?: LngLat;
  drawingMode?: boolean;
  drawingCoordinates?: LngLat[];
  emptyText?: string;
  // Callback to publish the new coordinates as a polygon is edited
  onPolygonEdit?: (coordinates: LngLat[]) => void;
  markers?: TreadMarker[];
  sx?: SxProps<Theme>;
};

type MapTypeId = 'hybrid' | 'satellite' | 'terrain' | 'roadmap';
type FillColor = 'black' | 'blue';

export function MapV2({
  center,
  drawingMode,
  drawingCoordinates = [],
  emptyText = t('navigation.choose_address') as string,
  markers = [],
  onPolygonEdit,
}: MapV2Props) {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [mapTypeId, setMapTypeId] = useState<MapTypeId>('roadmap');
  const drawableRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  const fitBounds = useCallback(
    (bounds: google.maps.LatLngBounds) => {
      if (mapInstance) {
        mapInstance.fitBounds(bounds);
        const fittedZoom = mapInstance.getZoom() ?? Infinity;
        if (Number.isInteger(fittedZoom) && fittedZoom > 17) {
          mapInstance.setZoom(17);
        }
      }
    },
    [mapInstance],
  );

  // Changing to drawing mode triggers coordinate hydration and map fit
  useEffect(() => {
    if (mapInstance && drawingMode) {
      if (drawingCoordinates.length === 0) {
        const initialCoordinates = [
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) + 0.001,
            lng: markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng,
          },
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) - 0.001,
            lng:
              (markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng) + 0.001,
          },
          {
            lat:
              (markers[0]?.lat ?? center?.lat ?? TORONTO_OFFICE_COORDINATES.lat) - 0.001,
            lng:
              (markers[0]?.lng ?? center?.lng ?? TORONTO_OFFICE_COORDINATES.lng) - 0.001,
          },
        ];
        const bounds = new window.google.maps.LatLngBounds();
        initialCoordinates.forEach((coordinate) => bounds.extend(coordinate));
        fitBounds(bounds);
        onPolygonEdit?.(initialCoordinates);
      } else {
        // This is the case where we are editing an existing polygon
        const bounds = new window.google.maps.LatLngBounds();
        drawingCoordinates.forEach((coordinate) => bounds.extend(coordinate));
        const formattedMarkers = markers.map(({ lat, lng }) => ({
          lat,
          lng,
        }));
        formattedMarkers.forEach((marker) => bounds.extend(marker));
        fitBounds(bounds);
      }
    }
  }, [drawingMode, mapInstance]);

  // Fit map to markers
  useEffect(() => {
    if (mapInstance && markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      if (
        markers.length === 1 &&
        markers[0].type === MarkerType.site &&
        markers[0].geofenceType === GeofenceType.circle &&
        markers[0].radius
      ) {
        const circle = new google.maps.Circle({
          center: {
            lat: markers[0].lat,
            lng: markers[0].lng,
          },
          radius: markers[0].radius,
        });
        const circleBounds = circle.getBounds();
        if (circleBounds) {
          fitBounds(circleBounds);
        }
      } else {
        drawingCoordinates.forEach((coordinate) => bounds.extend(coordinate));
        const formattedMarkers = markers.map(({ lat, lng }) => ({
          lat,
          lng,
        }));
        formattedMarkers.forEach((marker) => bounds.extend(marker));
        fitBounds(bounds);
      }
    }
  }, [mapInstance, markers]);

  const onMapLoad = useCallback(
    (map: google.maps.Map) => {
      setMapInstance(map);
    },
    [setMapInstance],
  );

  const onMapUnmount = useCallback(() => {
    setMapInstance(null);
  }, [setMapInstance]);

  const onMapTypeIdChanged = useCallback(() => {
    const newMapTypeId = mapInstance?.getMapTypeId() as MapTypeId;
    if (newMapTypeId) {
      setMapTypeId(newMapTypeId);
    }
  }, [mapInstance, setMapTypeId]);

  const onEdit = useCallback(() => {
    if (drawableRef.current) {
      const nextPath = drawableRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      onPolygonEdit?.(nextPath);
    }
  }, [onPolygonEdit]);

  const onPolygonLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      drawableRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        // @ts-ignore - types are wrong. These are valid events and needed
        path?.addListener('set_at', onEdit),
        path?.addListener('insert_at', onEdit),
        path?.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  const cleanupEditablePolygon = useCallback(() => {
    // Please see here for more: https://codesandbox.io/p/sandbox/reactgooglemapsapi-editing-a-polygon-popr2
    // @ts-ignore - types are wrong. This is found in examples and needed
    listenersRef.current.forEach((lis) => lis?.remove());
    drawableRef.current = null;
  }, []);

  const markerTypeToIcon: Record<
    MarkerType,
    google.maps.Icon | google.maps.Symbol
  > | null = useMemo(() => {
    if (!isLoaded) return null;
    return {
      [MarkerType.truck]: {
        url: 'map-markers/truck_marker.png',
        scaledSize: new window.google.maps.Size(16, 18),
        anchor: new window.google.maps.Point(8, 9),
      },
      [MarkerType.site]: {
        // https://www.svgrepo.com/show/1276/map-pin.svg
        path: 'M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878    c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212    c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084    c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358    c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307    C198.98,120.938,175.559,144.358,146.667,144.358z',
        fillColor: 'blue',
        fillOpacity: 1,
        strokeWeight: 2,
        scale: 0.15,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(150, 275),
      },
      [MarkerType.moving_site]: {
        path: 'M10,2 A8,8 0 1,0 10,18 A8,8 0 1,0 10,2',
        fillColor: 'black',
        fillOpacity: 1,
        scale: 0.9,
        strokeWeight: 2,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(9, 9),
      },
    };
  }, [isLoaded]);

  const circles = markers.reduce(
    (acc, marker) => {
      if (marker.type === MarkerType.moving_site) {
        const newCircles = marker.radii.map((radius) => ({
          center: {
            lng: marker.lng,
            lat: marker.lat,
          },
          radius,
          fillColor: 'black' as FillColor,
        }));
        return [...acc, ...newCircles];
      }
      if (
        marker.type === MarkerType.site &&
        marker.geofenceType === GeofenceType.circle
      ) {
        const newCircle = {
          center: {
            lng: marker.lng,
            lat: marker.lat,
          },
          radius: marker.radius,
          fillColor: 'blue' as FillColor,
        };
        return [...acc, newCircle];
      }
      return acc;
    },
    [] as {
      center: { lng: number; lat: number };
      radius: number;
      fillColor: FillColor;
    }[],
  );

  // so every marker that has a polygon has coordinates
  // we want a list of coordinates. Any other info?
  const polygons = markers.reduce((acc, marker) => {
    if (marker.type === MarkerType.site && marker.geofenceType === GeofenceType.polygon) {
      const formattedCoordinates = marker.coordinates[0].map(([lng, lat]) => ({
        lat,
        lng,
      }));
      return [...acc, formattedCoordinates];
    }
    return acc;
  }, [] as LngLat[][]);

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={
            markers.length > 0 || drawingCoordinates.length > 0
              ? undefined
              : {
                  lng: center?.lng ?? TORONTO_OFFICE_COORDINATES.lng,
                  lat: center?.lat ?? TORONTO_OFFICE_COORDINATES.lat,
                }
          }
          zoom={11}
          onLoad={onMapLoad}
          onUnmount={onMapUnmount}
          onMapTypeIdChanged={onMapTypeIdChanged}
          options={{
            mapTypeId,
            zoomControl: true,
            streetViewControl: false,
            rotateControl: true,
            scaleControl: true,
          }}
        >
          {drawingMode && (
            <Polygon
              editable
              draggable
              key={`drawing-polygon`}
              paths={drawingCoordinates}
              onLoad={onPolygonLoad}
              onUnmount={cleanupEditablePolygon}
              onMouseUp={onEdit}
              onDragEnd={onEdit}
              options={{
                fillColor: 'orange',
                fillOpacity: 0.35,
                strokeColor: 'orange',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          )}
          {markers.map((marker) => {
            return (
              <Marker
                key={marker.id}
                position={{
                  lng: marker.lng,
                  lat: marker.lat,
                }}
                label={marker.type === MarkerType.truck ? marker.label : undefined}
                icon={markerTypeToIcon?.[marker.type]}
              />
            );
          })}
          {circles.map((circle, index) => (
            <Circle
              key={index}
              center={circle.center}
              radius={circle.radius}
              options={{
                fillOpacity: 0.25 - index * 0.05,
                fillColor: circle.fillColor,
                strokeOpacity: 0.5,
                strokeWeight: 0,
              }}
            />
          ))}
          {polygons.map((coordinates, index) => (
            <Polygon
              key={`${index}-${coordinates[0].lat}${coordinates[0].lng}`}
              paths={coordinates}
              options={{
                fillColor: 'blue',
                fillOpacity: 0.35,
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          ))}
          {markers.length === 0 && drawingCoordinates.length === 0 && (
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                background: 'rgba(255,255,255,0.8)',
                display: 'grid',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography variant="h5">{emptyText}</Typography>
            </Box>
          )}
        </GoogleMap>
      )}
    </>
  );
}
