import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import Loads from '~components/Job/Loads';
import { RouteMap } from '~components/Job/RouteMap';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useAddOns } from '~hooks/useAddOns';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { Job, useJob } from '~hooks/useJob';
import { useOrders } from '~hooks/useOrders';
import useResourceUsageLogs from '~hooks/useResourceUsageLogs/useResourceUsageLogs';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

import {
  AddOnChargeDTO,
  ResourceUsageLogDTO,
  schemaHelpers,
} from '../../DriverPay/helpers';
import ApprovalActionsMenu from './ApprovalActionsMenu';
import ChargesTable from './ChargesTable';

const emDash = '-';

const getFromToLocationString = (from: string, to: string) => `${from} to: ${to}`;

export const JobHeader = ({
  job,
  hideMapToggle = false,
  toggledJob,
  onToggleJob,
}: {
  job: Job;
  hideMapToggle?: boolean;
  toggledJob: Job | null;
  onToggleJob: (job: Job) => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        bgcolor: theme.brandV2.colors.treadGray10,
        py: 1,
        px: 1.5,
        borderRadius: 2,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      }}
    >
      <Typography noWrap paragraph variant="subtitle1" sx={{ m: 0 }}>
        {' '}
        {job.jobId}
      </Typography>
      <div>•</div>
      <Typography noWrap sx={{ maxWidth: '300px' }}>
        {getFromToLocationString(
          job.waypoints[0].site?.name ?? emDash,
          job.waypoints[1].site?.name ?? emDash,
        )}
      </Typography>
      {job.driverRateType && (
        <>
          <div>•</div>
          <Typography>{job.driverRateType}</Typography>
        </>
      )}
      {!hideMapToggle && (
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={toggledJob?.id === job.id}
              onChange={() => {
                onToggleJob(job);
              }}
            />
          }
          label={t('approvals.driver_day.show_map_toggle')}
          sx={{ ml: 'auto' }}
        />
      )}
    </Box>
  );
};

export const JobDetailsTabs = ({ toggledJob }: { toggledJob: Job }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        pb: 1.5,
        borderRadius: 2,
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
      }}
    >
      <Tabs
        value={'map'}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tab
          label="Map"
          value={'map'}
          sx={{
            color: theme.brandV2.colors.treadBlack,
            pb: 1,
            mb: 1,
            ml: 1,
            fontSize: '12px',
            width: 'fit-content',
            '&.Mui-selected': {
              borderBottom: `4px solid ${theme.brandV2.colors.treadYellow}`,
            },
          }}
        />
      </Tabs>
      <div role="tabpanel" id={`simple-tabpanel-0`} aria-labelledby={`simple-tab-0`}>
        <RouteMap job={toggledJob} />
      </div>
    </Box>
  );
};

type JobDetailsFormData = {
  addOnCharges: AddOnChargeDTO[];
  resourceUsageLogs: ResourceUsageLogDTO[];
};

const JobDetails = ({
  driverDayId,
  job,
  toggledJob,
  onToggleJob,
  editable,
}: {
  driverDayId: string;
  job: Job;
  toggledJob: Job | null;
  onToggleJob: (job: Job) => void;
  editable: boolean;
}) => {
  const { addOnsStore, toasterStore, ordersStore } = useStores();
  const { approveJob, unapproveJob } = useJob();
  const { batchUpdateAddOnChargesByJob, getAllAddOnChargesByJob } = useAddOns();
  const { getOrderPhasesTypeahead } = useOrders();
  const { fetchJobsForDriverDay } = useDriverDays();
  const [isEditing, setIsEditing] = useState(false);
  const { getResourceUsageLogsByJob, batchUpdateResourceUsageLogsByJob } =
    useResourceUsageLogs();

  const addOns = useMemo(() => {
    return addOnsStore.addOnChargesByJobId[job.id] ?? [];
  }, [job.id, addOnsStore.addOnChargesByJobId]);

  const resourceUsageLogs = useMemo(() => {
    return addOnsStore.resourceUsageLogsByJobId[job.id] ?? [];
  }, [job.id, addOnsStore.resourceUsageLogsByJobId]);

  const jobHasCharges = useMemo(
    () => addOns.length + resourceUsageLogs.length > 0,
    [addOns, resourceUsageLogs],
  );
  const phasesOptions = useMemo(
    () => ordersStore.phaseTypeheadsByOrderID[job.order?.id ?? ''] ?? [],
    [ordersStore.phaseTypeheadsByOrderID, job.order?.id],
  );

  useEffect(() => {
    getAllAddOnChargesByJob(job.id);
    getResourceUsageLogsByJob(job.id);
    getOrderPhasesTypeahead({ orderId: job.order?.id ?? '' });
  }, [job.id]);

  const formMethods = useForm<JobDetailsFormData>({
    defaultValues: {
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    },
  });

  useEffect(() => {
    setValue(
      'addOnCharges',
      addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
    );
  }, [addOns]);

  useEffect(() => {
    setValue(
      'resourceUsageLogs',
      resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    );
  }, [resourceUsageLogs]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmit: SubmitHandler<JobDetailsFormData> = (data) => {
    Promise.all([
      batchUpdateResourceUsageLogsByJob(job.id, data.resourceUsageLogs),
      batchUpdateAddOnChargesByJob(job.id, data.addOnCharges),
    ]).then(() => {
      setIsEditing(false);
      toasterStore.push(
        alert(
          t('approvals.driver_pay.settlements.add_ons_updated_successfully'),
          AlertTypes.success,
        ),
      );
      reset();
    });
  };

  const resetForm = () => {
    reset({
      addOnCharges: addOns.map((addOnCharge) => {
        return schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge);
      }),
      resourceUsageLogs: resourceUsageLogs.map((resourceUsageLog) => {
        return schemaHelpers.resourceUsageLogToResourceUsageLogDTO(resourceUsageLog);
      }),
    });
  };
  const ApprovalCTA = () => {
    return job.approved ? (
      <ApprovalActionsMenu
        onMenuItemClick={
          job.unapprovable
            ? () => {
                unapproveJob(job.id);
                fetchJobsForDriverDay(driverDayId, true);
              }
            : undefined
        }
        approved={job.approved}
      />
    ) : job.approvable ? (
      <SmallButton
        color="brandV2Yellow"
        sx={{
          '&.MuiButtonBase-root': {
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          },
        }}
        onClick={() => {
          approveJob(job.id);
          fetchJobsForDriverDay(driverDayId, true);
        }}
        disabled={isEditing}
      >
        {t('approvals.driver_day.approve_job')}
      </SmallButton>
    ) : null;
  };

  const EditingButton = () => {
    if (!editable) {
      return null;
    }

    return isEditing ? (
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
        }}
      >
        <Button
          onClick={() => {
            setIsEditing(false);
            resetForm();
          }}
          variant="outlined"
          color="secondary"
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
              height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
            },
          }}
          startIcon={<Close />}
        >
          {t('actions.cancel')}
        </Button>

        <Button
          type="submit"
          variant={'contained'}
          color={'brandV2Green'}
          sx={{
            fontWeight: 600,
            '&.MuiButton-root': {
              borderColor: theme.brandV2.colors.treadGray7,
              height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
            },
          }}
          startIcon={<Check />}
          disabled={!isValid || !isDirty}
        >
          {t('actions.save_changes')}
        </Button>
      </Box>
    ) : (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setIsEditing(!isEditing);
        }}
        sx={{
          fontWeight: 600,
          '&.MuiButton-root': {
            borderColor: theme.brandV2.colors.treadGray7,
            height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
          },
        }}
        startIcon={<Edit />}
      >
        {t('actions.edit')}
      </Button>
    );
  };

  const ActionsSection = () => {
    if (isEditing) return <EditingButton />;
    else {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
          }}
        >
          <EditingButton />

          <ApprovalCTA />
        </Box>
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        key={job.id}
        component="form"
        id="job-details-form"
        onSubmit={handleSubmit(onSubmit)}
        aria-label="job details"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,

          backgroundColor: 'white',
          border: `1px solid ${theme.brandV2.colors.treadGray7}`,
          borderRadius: 2,
          pb: 1,
        }}
      >
        <JobHeader job={job} toggledJob={toggledJob} onToggleJob={onToggleJob} />
        <Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: jobHasCharges ? 'space-between' : 'flex-end',
                px: 1.5,
              }}
            >
              {jobHasCharges ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {t('approvals.summary')}
                </Typography>
              ) : null}
              <ActionsSection />
            </Box>
          </Box>

          <ChargesTable
            isEditing={isEditing}
            resourceUsageLogs={resourceUsageLogs}
            addOns={addOns}
            phasesOptions={phasesOptions}
          />
        </Box>

        <Box sx={{ pl: 1 }}>
          <Loads
            key={job.id}
            details={job}
            reload={() => {
              fetchJobsForDriverDay(driverDayId, true);
            }}
            isEditing={isEditing}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default JobDetails;
