import CancelOutlined from '@mui/icons-material/CancelOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import SendOutlined from '@mui/icons-material/SendOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import React from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { extractWaypointData } from '~components/Order/ordersDispatchColumns';
import {
  FlexColumn,
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Job } from '~hooks/useJob';
import { JobEventType, useJob } from '~hooks/useJob/useJob';
import { useOrdersDispatch } from '~hooks/useOrders';
import { GeofenceStartAdornment } from '~pages/Settings/Administration/Sites/components/GeofenceStartAdornment';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

import { allJobStates } from '../constants/jobStates';
import { canSendInvitation } from '../JobsOrderDataGrid';
import { canAcceptJob, getJobNextEvents } from '../utils';
import EquipmentSelector from './EquipmentSelector';
import JobAssigneeHandler, { hasUnassignCTA } from './JobAssigneeHandler';
import { Column } from './jobsTableStyledComponents';
import JobStatusTransitioner from './JobStatusTransitioner';

export enum JobsTableColumnKey {
  CHECKBOX = 'checkbox',
  VENDOR_AND_DRIVER = 'vendor_and_driver',
  STATUS = 'status',
  START = 'start',
  SERVICE_TYPE = 'service_type',
  CYCLE_TIME = 'cycle_time',
  DELIVERED = 'delivered',
  TRUCK_AND_TRAILER = 'truck_and_trailer',
  MATERIALS = 'materials',
  WAYPOINTS = 'waypoints',
  JOB_ID = 'job_id',
  ACTIONS = 'actions',
}

export const jobsTableColumns: Record<
  JobsTableColumnKey,
  { title: ReactNode; size: string; sx?: SxProps }
> = {
  [JobsTableColumnKey.CHECKBOX]: {
    title: '',
    size: '20px',
    sx: { px: 0 },
  },
  [JobsTableColumnKey.VENDOR_AND_DRIVER]: {
    title: t('dispatch.dispatch_v2.vendor_and_driver'),
    size: '4fr',
  },
  [JobsTableColumnKey.TRUCK_AND_TRAILER]: {
    title: t('dispatch.dispatch_v2.truck_and_trailer'),
    size: '2fr',
  },
  [JobsTableColumnKey.STATUS]: {
    title: t('form_fields.status'),
    size: '105px',
    sx: { display: 'flex', gap: theme.spacing(0.5), px: 0 },
  },
  [JobsTableColumnKey.START]: {
    title: t('dispatch.dispatch_v2.start'),
    size: '110px',
  },
  [JobsTableColumnKey.SERVICE_TYPE]: {
    title: t('form_fields.service_type'),
    size: '2fr',
  },
  [JobsTableColumnKey.CYCLE_TIME]: {
    title: t('dispatch.dispatch_v2.cycle_time'),
    size: '80px',
    sx: { px: 0 },
  },
  [JobsTableColumnKey.DELIVERED]: {
    title: t('dispatch.dispatch_v2.delivered'),
    size: '2fr',
  },
  [JobsTableColumnKey.MATERIALS]: {
    title: t('form_fields.materials'),
    size: '2fr',
  },
  [JobsTableColumnKey.WAYPOINTS]: {
    title: t('dispatch.dispatch_v2.pick_up_and_drop_off'),
    size: '4fr',
  },
  [JobsTableColumnKey.JOB_ID]: {
    title: t('form_fields.job_id'),
    size: '80px',
    sx: { px: 0 },
  },
  [JobsTableColumnKey.ACTIONS]: {
    title: '',
    size: '90px',
    sx: { display: 'flex', justifyContent: 'flex-end', pr: 0 },
  },
};

export const JOBS_TABLE_TEMPLATE_COLUMNS = Object.values(jobsTableColumns)
  .map(({ size }) => size)
  .join(' ');

interface JobsTableColumnProps {
  filterDate?: string;
  isHovered?: boolean;
  job: Job;
}

interface CheckboxColumnProps extends JobsTableColumnProps {
  isChecked?: boolean;
  onCheckedStateChange?: () => void;
}

export function CheckboxColumn({ isChecked, onCheckedStateChange }: CheckboxColumnProps) {
  return (
    <Column columnKey={JobsTableColumnKey.CHECKBOX}>
      <Checkbox
        size="small"
        sx={{
          '&.MuiCheckbox-root': {
            color: theme.brandV2.colors.treadGray6,
            mt: '-10px',
            p: 0,
          },
          '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
        }}
        checked={Boolean(isChecked)}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onCheckedStateChange?.();
        }}
      />
    </Column>
  );
}

export const VendorAndDriverColumn = observer(
  ({ isHovered, job, filterDate }: JobsTableColumnProps) => {
    const { userStore, ordersDispatchStore } = useStores();
    const { canEdtJob: canEditJob } = userStore.getPermissions();

    const { driver, vendorJobAssignment } = job;

    const vendorName = vendorJobAssignment?.vendorAccount?.name;
    const driverName = driver?.fullName;
    const hasVendorOrDriver = Boolean(vendorName || driverName);
    const isAcceptable = canAcceptJob(job);

    let content: ReactNode = null;

    const handleAssigneeChange = useCallback((updatedJob: Job) => {
      ordersDispatchStore.upsertJob(updatedJob);
    }, []);

    if (hasVendorOrDriver) {
      const isUnassignable = !isAcceptable && hasUnassignCTA(job, canEditJob);

      content = (
        <Box
          alignSelf="flex-start"
          display="flex"
          gap={1}
          height="100%"
          borderRight={`solid 1px transparent`}
          sx={
            isUnassignable && isHovered
              ? {
                  borderRightColor: theme.brandV2.colors.treadGray7,
                  '& .action-box': { display: 'flex' },
                }
              : undefined
          }
        >
          <FlexColumn sx={{ flex: 1, overflow: 'hidden' }}>
            <OverflowAwareText
              component="span"
              data-sentry-mask
              fontSize="inherit"
              fontWeight={700}
            >
              {driverName ?? '-'}
            </OverflowAwareText>
            <OverflowAwareText fontSize="inherit" component="span">
              {vendorName}
            </OverflowAwareText>
          </FlexColumn>

          {isUnassignable && (
            <Box
              alignSelf="center"
              className="action-box"
              display="none"
              flex={0}
              pr="2px"
            >
              <JobAssigneeHandler
                canEditJob={canEditJob}
                job={job}
                onAssigneeChange={handleAssigneeChange}
                filterDate={filterDate}
              />
            </Box>
          )}
        </Box>
      );
    } else if (!isAcceptable) {
      content = (
        <JobAssigneeHandler
          canEditJob={canEditJob}
          job={job}
          onAssigneeChange={handleAssigneeChange}
          filterDate={filterDate}
        />
      );
    }

    return <Column columnKey={JobsTableColumnKey.VENDOR_AND_DRIVER}>{content}</Column>;
  },
);

interface StatusColumnProps extends JobsTableColumnProps {
  onAccept: () => void;
  onReject: () => void;
  onSend: () => void;
}

type AvailableAction = {
  color: ButtonProps['color'];
  icon?: ReactNode;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

export const StatusColumn = observer(
  ({ job, onAccept, onReject, onSend }: StatusColumnProps) => {
    const { userStore, toasterStore, ordersDispatchStore } = useStores();
    const { canEdtJob: canEditJob, canDeleteJob } = userStore.getPermissions();
    const { doEvent } = useJob();
    const [isTransitioningToStatus, setIsTransitioningToStatus] =
      useState<JobEventType>();

    const jobCTAs = useMemo(() => {
      const isAcceptable = canAcceptJob(job);
      const isOptionsDisabled =
        ordersDispatchStore.isAcceptingOrRejectingJobs.get(job.id) ?? false;

      // Vendor actions
      if (isAcceptable) {
        return [
          {
            color: 'brandV2Green',
            isDisabled: isOptionsDisabled,
            label: t('actions.accept'),
            onClick: onAccept,
          } as AvailableAction,
          {
            color: 'brandV2Red',
            isDisabled: isOptionsDisabled,
            label: t('actions.decline'),
            onClick: onReject,
          } as AvailableAction,
        ];
      }

      // Driver actions
      const canInvite = canSendInvitation(job);
      const isCreatedOrReassigned = [JobState.CREATED, JobState.REASSIGN].includes(
        job.status,
      );
      const isSendable =
        canInvite || !allJobStates.includes(job.status) || isCreatedOrReassigned;
      const isSendButtonDisabled =
        isCreatedOrReassigned ||
        job.status === JobState.SENT ||
        (ordersDispatchStore.isSendingJobs.get(job.id) ?? false);

      if (isSendable) {
        return [
          {
            color: 'brandV2Green',
            icon: <SendOutlined />,
            isDisabled: isSendButtonDisabled,
            label: t('actions.send'),
            onClick: onSend,
          } as AvailableAction,
        ];
      }

      return null;
    }, [
      job,
      ordersDispatchStore.isSendingJobs.get(job.id),
      ordersDispatchStore.isAcceptingOrRejectingJobs.get(job.id),
    ]);

    const transitionableStates = getJobNextEvents(job, canEditJob, canDeleteJob);

    const handleTransitionToStatus = useCallback(
      async (status: JobEventType) => {
        setIsTransitioningToStatus(status);

        try {
          const updatedJob = await doEvent(job.id, status);
          ordersDispatchStore.upsertJob(updatedJob);

          toasterStore.push(
            alert(t('dispatch.job.updated', { name: job.jobId }), AlertTypes.success),
          );
        } catch (error) {
          console.error(error);
          throw new Error('Unable to update job status.');
        } finally {
          setIsTransitioningToStatus(undefined);
        }
      },
      [job.id, job.jobId],
    );

    return (
      <Column columnKey={JobsTableColumnKey.STATUS}>
        {jobCTAs ? (
          <>
            {jobCTAs.map((cta) => (
              <SmallButton
                key={cta.label}
                color={cta.color}
                disabled={cta.isDisabled}
                onClick={(event) => {
                  event.stopPropagation();
                  cta.onClick();
                }}
                startIcon={cta.icon}
                sx={{
                  '&.MuiButtonBase-root': {
                    height: '24px',
                    px: theme.spacing(0.5),
                    '&.Mui-disabled': { pointerEvents: 'auto' },
                    '.MuiSvgIcon-root': { fontSize: '16px' },
                  },
                }}
              >
                {cta.label}
              </SmallButton>
            ))}
          </>
        ) : (
          <Box>
            <JobStatusTransitioner
              closeOnTransitionComplete
              isTransitioningToStatus={isTransitioningToStatus}
              job={job}
              onTransitionToStatus={handleTransitionToStatus}
              transitionableStates={transitionableStates}
            />
          </Box>
        )}
      </Column>
    );
  },
);

export function StartColumn({ job }: JobsTableColumnProps) {
  let startTime = null;
  let startDate = null;

  if (job.jobStartAt) {
    startTime = dayjs.tz(job.jobStartAt).format('hh:mm A');
    startDate = dayjs.tz(job.jobStartAt).format('DD-MMM-YYYY');
  }

  return (
    <Column columnKey={JobsTableColumnKey.START}>
      {(startTime || startDate) && (
        <FlexColumn>
          {startTime && <OverflowAwareText>{startTime}</OverflowAwareText>}
          {startDate && <OverflowAwareText>{startDate}</OverflowAwareText>}
        </FlexColumn>
      )}
    </Column>
  );
}

export function ServiceTypeColumn({ job }: JobsTableColumnProps) {
  const name = job.service?.name;

  return (
    <Column columnKey={JobsTableColumnKey.START}>
      {name && (
        <FlexColumn>
          <OverflowAwareText>{name}</OverflowAwareText>
        </FlexColumn>
      )}
    </Column>
  );
}

export function CycleTimeColumn({ job }: JobsTableColumnProps) {
  const hours = Math.floor(
    dayjs.duration({ minutes: job.loadCycleAvg }).asHours(),
  ).toString();
  const minutes = dayjs
    .duration({ minutes: job.loadCycleAvg % 60 })
    .asMinutes()
    .toString();
  const formatted = `${hours}hr ${minutes}m`;

  return (
    <Column columnKey={JobsTableColumnKey.CYCLE_TIME}>
      {Boolean(job.loadCycleAvg) && <OverflowAwareText>{formatted}</OverflowAwareText>}
    </Column>
  );
}

export function DeliveredColumn({ job }: JobsTableColumnProps) {
  const deliveredQuantity = job.deliveredQuantity?.toFixed(2);
  const totalQuantity = Number(job.quantity).toFixed(2);
  const deliveredQuantityString = `${deliveredQuantity} / ${totalQuantity}`;

  const deliveredLoads = job.completedLoadsCount;
  const totalLoads = job.loadsCount;
  const deliveredLoadsString = `${deliveredLoads} / ${totalLoads}`;

  return (
    <Column columnKey={JobsTableColumnKey.DELIVERED}>
      <FlexColumn>
        <OverflowAwareText>{`${deliveredQuantityString} ${job.unitOfMeasure?.name ?? ''}`}</OverflowAwareText>
        <OverflowAwareText>{`${deliveredLoadsString} ${t('order.form.loads')}`}</OverflowAwareText>
      </FlexColumn>
    </Column>
  );
}

export const TruckAndTrailerColumn = observer(({ job }: JobsTableColumnProps) => {
  const { userStore, toasterStore } = useStores();
  const { updateJobEquipment } = useOrdersDispatch();
  const companyId = userStore.currentCompanies[0].id || userStore.userCompany?.id;

  const updateEquipment = useCallback(
    async (equipmentId?: string | null, additionalEquipmentIds?: string[]) => {
      const updatedJob = await updateJobEquipment(
        job.id,
        equipmentId,
        additionalEquipmentIds,
      );

      if (updatedJob) {
        toasterStore.push(
          alert(
            t('dispatch.job.updated', { name: updatedJob?.jobId }),
            AlertTypes.success,
          ),
        );
      }
    },
    [job.id],
  );

  const handleSelectedTruckChange = useCallback((equipmentId?: string) => {
    updateEquipment(equipmentId ?? null);
  }, []);

  const handleSelectedTrailerChange = useCallback((equipmentId?: string) => {
    updateEquipment(undefined, equipmentId ? [equipmentId] : []);
  }, []);

  return (
    <Column columnKey={JobsTableColumnKey.TRUCK_AND_TRAILER}>
      <FlexColumn sx={{ alignItems: 'flex-start' }}>
        <EquipmentSelector
          companyId={companyId}
          equipment={job.equipment}
          job={job}
          onChange={handleSelectedTruckChange}
          placeholder={t('form_fields.truck')}
        />

        <EquipmentSelector
          companyId={companyId}
          equipment={job.additionalEquipment}
          job={job}
          onChange={handleSelectedTrailerChange}
          placeholder={t('form_fields.trailer')}
        />
      </FlexColumn>
    </Column>
  );
});

export function MaterialsColumn({ job }: JobsTableColumnProps) {
  const { numberFormatter } = useCompanyCurrency();

  const material = job.material?.name;
  const qty = job.quantity
    ? `${numberFormatter(job.quantity)} ${job.unitOfMeasure?.name}`
    : null;

  return (
    <Column columnKey={JobsTableColumnKey.MATERIALS}>
      {Boolean(material || qty) && (
        <FlexColumn>
          {qty && (
            <OverflowAwareText fontSize="inherit" component="span">
              {qty}
            </OverflowAwareText>
          )}

          {material && (
            <OverflowAwareText fontSize="inherit" component="span">
              {material}
            </OverflowAwareText>
          )}
        </FlexColumn>
      )}
    </Column>
  );
}

export function WaypointsColumn({ job }: JobsTableColumnProps) {
  const pickUp = extractWaypointData(job.waypoints?.[0]);
  const dropOff = extractWaypointData(job.waypoints?.[1]);

  return (
    <Column columnKey={JobsTableColumnKey.WAYPOINTS}>
      <FlexColumn>
        {[pickUp, dropOff].map((site, index) => (
          <Box display={'flex'} key={index}>
            <GeofenceStartAdornment
              geofenceType={site?.geofenceType}
              isLoading={false}
              sx={{ pr: 0.5 }}
            />
            <OverflowAwareText>{site?.name}</OverflowAwareText>
          </Box>
        ))}
      </FlexColumn>
    </Column>
  );
}

export function JobIdColumn({ job }: JobsTableColumnProps) {
  return (
    <Column columnKey={JobsTableColumnKey.JOB_ID}>
      <OverflowAwareText fontSize="inherit" component="span">
        {job.jobId}
      </OverflowAwareText>
    </Column>
  );
}

interface ActionsColumnProps extends JobsTableColumnProps {
  onCancel: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
  onTextDriver: () => void;
  isOrderEditable?: boolean;
}

export const ActionsColumn = observer(
  ({
    isHovered,
    job,
    onCancel,
    onDuplicate,
    onEdit,
    onTextDriver,
    isOrderEditable,
  }: ActionsColumnProps) => {
    const { userStore } = useStores();
    const {
      canCancelJob,
      canDeleteJob,
      canEdtJob: canEditJob,
    } = userStore.getPermissions();
    const menuHandler = useRef<MenuHandler>(null);

    const isEditable = job.editable && canEditJob;

    const availebleActions = useMemo(() => {
      const actions = [];
      const isCancellable = canCancelJob && job.stateEvents.includes('cancel');
      const hasPhone = Boolean(job.driver?.phone);

      if (job.copyable) {
        actions.push({
          icon: <FileCopyOutlined sx={{ color: theme.brandV2.colors.treadOrange }} />,
          label: t('dispatch.job.clone_job'),
          onClick: onDuplicate,
        });
      }

      if (hasPhone) {
        actions.push({
          icon: <SmsOutlined sx={{ color: theme.brandV2.colors.treadYellowDark }} />,
          label: t('actions.send_text'),
          onClick: onTextDriver,
        });
      }

      if (isCancellable) {
        actions.push({
          icon: <CancelOutlined sx={{ color: theme.brandV2.colors.treadRed }} />,
          label: t('dispatch.job.cancel_job'),
          onClick: onCancel,
        });
      }

      return actions;
    }, [
      canCancelJob,
      canDeleteJob,
      canEditJob,
      isOrderEditable,
      job.driver?.phone,
      job.stateEvents,
      job.copyable,
    ]);

    return (
      <Column columnKey={JobsTableColumnKey.ACTIONS}>
        {isHovered && isEditable && (
          <SmallButton
            color="brandV2Orange"
            variant="text"
            onClick={(event) => {
              event.stopPropagation();
              onEdit();
            }}
            sx={{
              '&.MuiButtonBase-root': {
                border: 'solid 1px transparent',
                '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
              },
            }}
          >
            {t('actions.edit')}
          </SmallButton>
        )}

        {isHovered && availebleActions.length > 0 && (
          <Menu
            ref={menuHandler}
            menuTrigger={
              <SmallButton
                color="brandV2TreadGray3"
                variant="text"
                startIcon={<MoreHoriz />}
                sx={{
                  '&.MuiButtonBase-root': {
                    border: 'solid 1px transparent',
                    borderRadius: '50%',
                    height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    p: 0,
                    width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                    '.MuiButton-startIcon': { mx: 0 },
                  },
                }}
              />
            }
          >
            {availebleActions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={(event) => {
                  event.stopPropagation();
                  action.onClick();
                  menuHandler.current?.onClose?.();
                }}
              >
                <Box alignItems="center" display="flex" width="100%" gap={1}>
                  {React.cloneElement(action.icon, {
                    sx: { ...action.icon.props.sx, fontSize: '16px' },
                  })}

                  <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                    {action.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Column>
    );
  },
);
