import { makeObservable, observable, runInAction } from 'mobx';

import { Pagination, PaginationLink } from '~services/pagination';
import {
  AgaveCustomer,
  AgaveItem,
  AgaveLedgerAccount,
  AgaveVendor,
} from '~src/hooks/useAgave/models';
import { getPageNumber } from '~utils/pagination';

class AgaveIntegrationQuickBooksStore {
  customers: AgaveCustomer[] = [];
  vendors: AgaveVendor[] = [];
  items: AgaveItem[] = [];
  ledgerAccounts: AgaveLedgerAccount[] = [];

  pagination: Pagination = {
    limit: 25,
    after: '',
    before: '',
    page: 1,
  };

  constructor() {
    makeObservable(this, {
      customers: observable,
      vendors: observable,
      items: observable,
      ledgerAccounts: observable,
    });
  }

  setCustomersPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updateCustomersPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setCustomers(customers: AgaveCustomer[]) {
    runInAction(() => {
      this.customers = [...customers];
    });
  }

  updateCustomer(customer: AgaveCustomer) {
    runInAction(() => {
      this.customers = this.customers.map((c) => (c.id === customer.id ? customer : c));
    });
  }

  setVendors(vendors: AgaveVendor[]) {
    runInAction(() => {
      this.vendors = [...vendors];
    });
  }

  updateVendor(vendor: AgaveVendor) {
    runInAction(() => {
      this.vendors = this.vendors.map((v) => (v.id === vendor.id ? vendor : v));
    });
  }

  setItems(items: AgaveItem[]) {
    runInAction(() => {
      this.items = [...items];
    });
  }

  updateItem(item: AgaveItem) {
    runInAction(() => {
      this.items = this.items.map((i) => (i.id === item.id ? item : i));
    });
  }

  setLedgerAccounts(ledgerAccounts: AgaveLedgerAccount[]) {
    runInAction(() => {
      this.ledgerAccounts = [...ledgerAccounts];
    });
  }

  updateLedgerAccount(ledgerAccount: AgaveLedgerAccount) {
    runInAction(() => {
      this.ledgerAccounts = this.ledgerAccounts.map((la) =>
        la.id === ledgerAccount.id ? ledgerAccount : la,
      );
    });
  }
}

export default AgaveIntegrationQuickBooksStore;
