import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import { Nullable } from '~src/types/Nullable';

import { OverflowAwareText } from '../../Order/ordersDispatchStyledComponents';

interface DriverMenuItemProps {
  equipmentId?: Nullable<string>;
  name: string;
  company?: string;
  equipmentType?: Nullable<string>;
}

const DriverMenuItem: React.FC<DriverMenuItemProps> = ({
  equipmentId,
  name,
  company,
  equipmentType,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.brandV2.colors.treadGray8,
        },
        borderRadius: theme.brandV2.borderRadius,
        maxWidth: '320px',
      }}
      gap="4px"
    >
      <Box display="flex" justifyContent="flex-start" gap="4px">
        <Text
          sx={{
            maxWidth: company ? '130px' : '280px',
          }}
          color={theme.brandV2.colors.treadBlack}
          fontWeight={'600'}
        >
          {name}
        </Text>

        {company && (
          <>
            <Typography
              variant="body2"
              color={`${theme.brandV2.colors.treadGray3}`}
              sx={{
                textAlign: 'center',
              }}
            >
              •
            </Typography>
            <Text>{company}</Text>
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-start" gap="4px">
        {equipmentId && (
          <>
            <Text
              color={theme.brandV2.colors.treadBlack}
              fontWeight={'600'}
              sx={{
                maxWidth: equipmentType ? '130px' : '100%',
              }}
            >
              {equipmentId}
            </Text>
          </>
        )}

        {equipmentType && (
          <>
            {equipmentId && (
              <Text
                sx={{
                  textAlign: 'center',
                }}
              >
                •
              </Text>
            )}
            <Text sx={{ maxWidth: '180px' }}>{equipmentType}</Text>
          </>
        )}
      </Box>
    </Box>
  );
};

interface TextProps {
  color?: string;
  fontWeight?: string;
  sx?: SxProps;
}

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  sx,
  fontWeight,
  color,
}) => {
  const theme = useTheme();
  return (
    <OverflowAwareText
      fontWeight={fontWeight}
      sx={{
        color: color || theme.brandV2.colors.treadGray3,
        maxWidth: '130px',
        width: 'inherit',
        minWidth: '10px',
        ...sx,
      }}
      variant="body2"
    >
      {children}
    </OverflowAwareText>
  );
};

export { DriverMenuItem };
