import dayjs from 'dayjs';
import { t } from 'i18next';
import { get } from 'lodash';
import * as yup from 'yup';
export const EmptyContactItem = {
  id: '',
  isDeleted: false,
  name: '',
  phone: '',
  type: {
    id: '',
    name: '',
  },
};
export const EmptyPhaseItem = {
  id: '',
  isDeleted: false,
  name: '',
  code: '',
};
export const EmptyProjectMaterialTotalItem = {
  id: '',
  isDeleted: false,
  material: {
    id: '',
    name: '',
  },
  totalQuantity: '',
  unitOfMeasure: {
    id: '',
    name: '',
  },
};
import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
} from '~constants/regexConst';
import { CompanyBasic } from '~hooks/useCompany';
import { Project } from '~hooks/useProjects';
import { RateDetailsDTO } from '~pages/Sales/Orders/NewOrderFormComponents/schema';
import { Nullable } from '~types/Nullable';

import { fileAttachmentSchema } from '../../../formsShared/components/FileAttachments';

export const projectFormSchema = yup.object().shape({
  id: yup.string(),
  company: yup
    .object()
    .shape({
      legalName: yup.string(),
      id: yup.string(),
    })
    .required(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('project.form.project_name'),
    })}`,
  ),
  effectiveAt: yup.string().notRequired(),
  expiresAt: yup
    .string()
    .notRequired()
    .test(
      'is-before-date',
      `${t('form_validation_errors.date_before', {
        field: 'date',
        date: 'effective at',
      })}`,
      (value, context) => {
        if (!context.parent.effectiveAt) return true;
        return (
          context.parent.effectiveAt && dayjs(value).isAfter(context.parent.effectiveAt)
        );
      },
    ),
  externalId: yup.string(),
  notes: notesSchema,
  projectNotes: notesSchema,
  orderNotes: notesSchema,
  jobNotes: notesSchema,
  internalNotes: notesSchema,
  account: nameIdSchemaRequired,
  poJobNumber: yup.string(),
  state: yup.string(),
  site: nameIdNonRequiredSchema,
  projectMaterialTotals: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          material: nameIdNonRequiredSchema,
          unitOfMeasure: nameIdNonRequiredSchema.when('material', {
            is: (material: { id: string } | undefined) => {
              return Boolean(material?.id);
            },
            then: () => {
              return nameIdSchemaRequired;
            },
          }),
          totalQuantity: yup.string().notRequired(),
          id: yup.string(),
          isDeleted: yup.boolean().notRequired(),
        })
        .notRequired(),
    )
    .notRequired(),
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  phases: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      id: yup.string(),
      code: yup.string(),
      primary: yup.boolean().notRequired(),
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  department: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  service: nameIdNonRequiredSchema,
  serviceClass: nameIdNonRequiredSchema.nullable(),
  jobTime: yup.number().notRequired(),
  quantity: yup
    .number()
    .notRequired()
    .min(
      0,
      `${t('form_validation_errors.min', {
        field: t('form_fields.quantity'),
        count: 0,
      })}`,
    )
    .typeError(
      `${t('form_validation_errors.should_be_number', {
        field: t('form_fields.quantity'),
      })}`,
    ),
  equipmentType: nameIdNonRequiredSchema.nullable(),
  loadsPerTruck: yup.number().notRequired(),
  truckCount: yup.number().notRequired(),
  unitsPerHour: yup.number().notRequired(),
  jobQuantity: yup.string().notRequired(),
  pickUpWayPoint: waypointSchema.notRequired(),
  dropOffWayPoint: waypointSchema.notRequired(),
  fileAttachments: fileAttachmentSchema,
  requester: yup.object().shape({ id: yup.string() }).notRequired(),
});

export const setDefaultProjectValues = (
  defaultProject: Project,
  company?: Nullable<CompanyBasic>,
) => {
  const pickUpWayPoint = defaultProject?.pickUpWayPoints?.[0];
  const dropOffWayPoint = defaultProject?.dropOffWayPoints?.[0];

  return {
    id: defaultProject?.id || '',
    company: defaultProject?.company
      ? {
          legalName: defaultProject?.company?.legalName || '',
          id: defaultProject?.company?.id || '',
        }
      : company || null,
    name: defaultProject?.name || '',
    expiresAt: defaultProject?.expiresAt || '',
    effectiveAt: defaultProject?.effectiveAt || '',
    poJobNumber: defaultProject?.poJobNumber || '',
    account: defaultProject?.account || undefined,
    externalId: defaultProject?.externalId || '',
    notes: get(defaultProject, 'notes', ''),
    projectNotes: defaultProject?.notes || '',
    orderNotes: defaultProject?.orderNotes || '',
    jobNotes: defaultProject?.jobNotes || '',
    internalNotes: defaultProject?.internalNotes || '',
    state: defaultProject?.state || '',
    site: defaultProject?.site || {},
    projectMaterialTotals: defaultProject?.projectMaterialTotals?.length
      ? defaultProject?.projectMaterialTotals.map((single) => {
          return {
            id: single.id || undefined,
            material: single.material || undefined,
            unitOfMeasure: single.unitOfMeasure || undefined,
            isDeleted: false,
            totalQuantity: single.totalQuantity || '',
          };
        })
      : [EmptyProjectMaterialTotalItem],
    allContacts: defaultProject?.allContacts?.length
      ? defaultProject?.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    phases: defaultProject?.phases?.length
      ? defaultProject?.phases.map((phase) => {
          return {
            name: phase.name,
            id: phase.id,
            code: phase.code,
            primary: phase.primary,
            isDeleted: false,
          };
        })
      : [],
    department: defaultProject?.department || {},
    foremen: defaultProject?.foremen || [],
    service: defaultProject?.service,
    serviceClass: defaultProject?.serviceClass,
    jobTime: defaultProject?.jobTime,
    quantity: defaultProject?.quantity,
    equipmentType: defaultProject?.equipmentType,
    loadsPerTruck: defaultProject?.loadsPerTruck,
    truckCount: defaultProject?.truckCount,
    unitsPerHour: defaultProject?.unitsPerHour,
    jobQuantity: defaultProject?.jobQuantity,
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
          },
        }
      : null,
    requester: defaultProject?.requester || {},
  };
};
export type ProjectFormSchemaInterface = yup.InferType<typeof projectFormSchema> & {
  customerRate: RateDetailsDTO['targetRate'];
  customerRateType: RateDetailsDTO['targetRateType'];
  customerRateValue: RateDetailsDTO['targetRateValue'];
  vendorRate: RateDetailsDTO['targetRate'];
  vendorRateType: RateDetailsDTO['targetRateType'];
  vendorRateValue: RateDetailsDTO['targetRateValue'];
  driverRate: RateDetailsDTO['targetRate'];
  driverRateType: RateDetailsDTO['targetRateType'];
  driverRateValue: RateDetailsDTO['targetRateValue'];
};
