import {
  getV1CompaniesCompanyIdOrdersDispatch,
  GetV1CompaniesCompanyIdOrdersDispatchData,
  getV1OrdersDispatch,
  GetV1OrdersDispatchData,
  Job_Read,
  Order_Read,
} from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { extractPagination } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { GetJobsByOrderQueryParams } from '~store/LiveMapStoreNew';

import { Job } from '../useJob';
import { Order } from '../useOrders';

type GetJobsByOrderQueryParamsWithAfterLink = GetJobsByOrderQueryParams & {
  'page[after]'?: string;
};

export const useLiveMapNew = () => {
  const { liveMapStoreNew } = useStores();

  const getCompanyOrders = async () => {
    liveMapStoreNew.ordersFetchStart();

    const { pagination, filters } = liveMapStoreNew;

    const query: GetV1OrdersDispatchData['query'] = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      query['page[before]'] = pagination.before;
    } else if (pagination.after) {
      query['page[after]'] = pagination.after;
    }

    if (filters?.search?.trim().length) {
      query['search[job][datagrid]'] = filters.search.trim();
    }

    if (filters?.customerAccountIds?.length) {
      query['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.dispatchNumbers?.length) {
      query['filter[job][dispatch_numbers]'] = filters.dispatchNumbers;
    }

    if (filters?.driverIds?.length) {
      query['filter[job][driver_ids]'] = filters.driverIds;
    }

    if (filters?.projectsExternalIds?.length) {
      query['filter[job][external_ids]'] = filters.projectsExternalIds;
    }

    if (filters?.projectIds?.length) {
      query['filter[job][project_ids]'] = filters.projectIds;
    }

    if (filters?.vendorAccountIds?.length) {
      query['filter[job][vendor_account_ids]'] = filters.vendorAccountIds;
    }

    if (filters?.orderStates?.length) {
      query['filter[states]'] = filters.orderStates;
    }

    if (filters?.startDate?.length) {
      query['filter[job][start_date]'] = dayjs
        .tz(filters.startDate)
        .startOf('day')
        .toISOString();
    }

    if (filters?.endDate?.length) {
      query['filter[job][end_date]'] = dayjs
        .tz(filters.endDate)
        .endOf('day')
        .toISOString();
    }

    if (filters?.jobStates?.length) {
      query['filter[job][states]'] = filters.jobStates;
    }

    if (filters?.pickUpSites?.length) {
      query['filter[job][pickup_site_ids]'] = filters.pickUpSites;
    }

    if (filters?.dropoffSites?.length) {
      query['filter[job][dropoff_site_ids]'] = filters.dropoffSites;
    }

    if (filters?.requesterIds?.length) {
      query['filter[requester_ids]'] = filters.requesterIds;
    }

    try {
      const response = await getV1OrdersDispatch({ query });
      const orders = response.data.data.map((order) => Order.parse(order));
      const pagination = extractPagination(response);
      liveMapStoreNew.ordersFetchEnd(orders, pagination);
    } catch (error) {
      liveMapStoreNew.ordersFetchEnd([]);
      console.error(error);
      connection.handleRequestError(
        error,
        t('error_messages.orders.failed_to_fetch') as string,
      );
    }
  };

  const getCompanyOrdersByCompanyId = async ({ companyId }: { companyId: string }) => {
    liveMapStoreNew.ordersFetchStart();

    const { pagination, filters } = liveMapStoreNew;

    const query: GetV1CompaniesCompanyIdOrdersDispatchData['query'] = {
      'page[limit]': pagination.limit,
    };

    if (pagination.before) {
      query['page[before]'] = pagination.before;
    } else if (pagination.after) {
      query['page[after]'] = pagination.after;
    }

    if (filters?.search?.trim().length) {
      query['search[job][datagrid]'] = filters.search.trim();
    }

    if (filters?.customerAccountIds?.length) {
      query['filter[job][customer_account_ids]'] = filters.customerAccountIds;
    }

    if (filters?.dispatchNumbers?.length) {
      query['filter[job][dispatch_numbers]'] = filters.dispatchNumbers;
    }

    if (filters?.driverIds?.length) {
      query['filter[job][driver_ids]'] = filters.driverIds;
    }

    if (filters?.projectsExternalIds?.length) {
      query['filter[job][external_ids]'] = filters.projectsExternalIds;
    }

    if (filters?.projectIds?.length) {
      query['filter[job][project_ids]'] = filters.projectIds;
    }

    if (filters?.vendorAccountIds?.length) {
      query['filter[job][vendor_account_ids]'] = filters.vendorAccountIds;
    }

    if (filters?.orderStates?.length) {
      query['filter[states]'] = filters.orderStates;
    }

    if (filters?.startDate?.length) {
      query['filter[job][start_date]'] = dayjs
        .tz(filters.startDate)
        .startOf('day')
        .toISOString();
    }

    if (filters?.endDate?.length) {
      query['filter[job][end_date]'] = dayjs
        .tz(filters.endDate)
        .endOf('day')
        .toISOString();
    }

    if (filters?.jobStates?.length) {
      query['filter[job][states]'] = filters.jobStates;
    }

    if (filters?.pickUpSites?.length) {
      query['filter[job][pickup_site_ids]'] = filters.pickUpSites;
    }

    if (filters?.dropoffSites?.length) {
      query['filter[job][dropoff_site_ids]'] = filters.dropoffSites;
    }

    if (filters?.requesterIds?.length) {
      query['filter[requester_ids]'] = filters.requesterIds;
    }

    try {
      const response = await getV1CompaniesCompanyIdOrdersDispatch({
        path: { 'company-id': companyId },
        query,
      });
      const orders = response.data.data.map((order) => Order.parse(order));
      const pagination = extractPagination(response);
      liveMapStoreNew.ordersFetchEnd(orders, pagination);
    } catch (error) {
      liveMapStoreNew.ordersFetchEnd([]);
      console.error(error);
      connection.handleRequestError(
        error,
        t('error_messages.orders.failed_to_fetch') as string,
      );
    }
  };

  const getJobsByOrder = async (orderId: string) => {
    liveMapStoreNew.orderJobsFetchStart(orderId);

    const getPage = (params: GetJobsByOrderQueryParamsWithAfterLink) => {
      return connection.getPaginated<Job_Read>(
        `${API_VERSION}/orders/${orderId}/jobs`,
        { params },
        t('error_messages.orders.failed_to_fetch_jobs_by_order') as string,
      );
    };

    let jobs: Job[] = [];
    let shouldFetchNextPage = true;

    const params: GetJobsByOrderQueryParamsWithAfterLink = {};

    try {
      while (shouldFetchNextPage) {
        const response = await getPage(params);

        const parsedJobs = response.data.map((job) => Job.parse(job));
        jobs = jobs.concat(parsedJobs);

        shouldFetchNextPage = Boolean(response.pagination.after);

        if (shouldFetchNextPage) {
          params['page[after]'] = response.pagination.after;
        }
      }

      liveMapStoreNew.orderJobsFetchEnd(orderId, jobs);
    } catch (error) {
      liveMapStoreNew.orderJobsFetchEnd(orderId, []);
      console.error(error);
      throw new Error('Unable to fetch jobs by order');
    }
  };

  const subscribeToJobsRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyJobUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Job_Read }) => {
            const job = Job.parse(data);

            liveMapStoreNew.upsertJob(job);
          },
        },
      );
    }

    return subscription;
  };

  const subscribeToOrdersRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyOrderUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Order_Read }) => {
            const order = Order.parse(data);

            liveMapStoreNew.orderUpdateEnd(order);
          },
        },
      );
    }

    return subscription;
  };

  return {
    getCompanyOrders,
    getCompanyOrdersByCompanyId,
    getJobsByOrder,
    subscribeToJobsRTU,
    subscribeToOrdersRTU,
  };
};
