import { JobState } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { OrderStatusFilter } from '~constants/enums';
import { Job } from '~hooks/useJob';
import { JobEventType } from '~hooks/useJob/useJob';
import { dateFormat } from '~utils/dateTime';

import {
  activeOrderStates,
  doneOrderStates,
  externalOrderStates,
  inProgressOrderStates,
  pendingOrderStates,
} from './constants/orderStates';

export function datetimeToFormattedDateAndTime(datetime: Dayjs) {
  const time = dateFormat(datetime, 'h:mm a');

  const isSameYear = dayjs().year() === datetime.year();
  const format = `MMM DD${isSameYear ? '' : " 'YY"}`;
  const date = dateFormat(datetime, format);

  return { time, date };
}

export function getJobNextEvents(job: Job, canEditJob: boolean, canDeleteJob: boolean) {
  const { editable, stateEvents, status, driver, vendorJobAssignment } = job;
  const states: JobEventType[] = [];

  const canEdit = editable && canEditJob;

  if (canEdit) {
    const isRejected = status === JobState.REJECTED;

    (stateEvents as JobEventType[]).forEach((jobEvent) => {
      // @todo We don't want any job to fall within any of the following states because they are
      // To be deprecated soon. Once that happen, this block should be removed.
      if (['flag', 'sign_off'].includes(jobEvent)) {
        return;
      }

      if (['complete', 'cancel', 'assign'].includes(jobEvent)) {
        const isCancellable = jobEvent === 'cancel';

        if (isRejected && isCancellable) {
          states.push(jobEvent);
        }

        return;
      }

      states.push(jobEvent);
    });
  }

  const isAssigned = Boolean(driver || vendorJobAssignment);
  const isCompletable = [
    JobState.SENT,
    JobState.ACCEPTED,
    JobState.TO_PICKUP,
    JobState.TO_DROPOFF,
    JobState.ARRIVED_DROPOFF,
    JobState.ARRIVED_DROPOFF,
    JobState.LOADED,
    JobState.UNLOADED,
    JobState.IN_REVIEW,
  ].includes(status);
  const canComplete = canEdit && isAssigned && isCompletable;

  if (canComplete) {
    states.push('complete');
  }

  const isCancellable = ![
    JobState.REJECTED,
    JobState.CANCELED,
    JobState.COMPLETED,
  ].includes(status);
  const canCancel = editable && canDeleteJob && isCancellable;

  if (canCancel) {
    states.push('cancel');
  }

  return states;
}

export function canAcceptJob(job: Job) {
  const isEditable = job.editable;
  const isSent = job.status === JobState.SENT;
  const isUnasigned = !job.driver && !job.vendorJobAssignment;

  return isEditable && isSent && isUnasigned;
}

export const getOrderStatesPerStatus = (status: OrderStatusFilter) => {
  switch (status) {
    case OrderStatusFilter.EXTERNAL:
      return externalOrderStates;
    case OrderStatusFilter.ACTIVE:
      return activeOrderStates;
    case OrderStatusFilter.PENDING:
      return pendingOrderStates;
    case OrderStatusFilter.INPROGRESS:
      return inProgressOrderStates;
    case OrderStatusFilter.DONE:
      return doneOrderStates;
    default:
      return [];
  }
};
