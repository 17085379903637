import {
  Phase_Read_Typeahead,
  Project_Read,
  Project_Read as ProjectProto,
  Project_Read_Typeahead,
  Project_Read_Typeahead_External_Id,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { ProjectStatusFilter } from '~constants/enums';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Phase, Project, ProjectState } from '~hooks/useProjects/models';
import { ProjectFormSchemaInterface } from '~pages/Sales/Projects/projectFormSchema';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~src/services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface UpdateProjectParams {
  project: ProjectFormSchemaInterface;
  callBack?: (project: Project) => void;
}
interface CreateProjectParams {
  project: ProjectFormSchemaInterface;
  callBack?: (project: Project) => void;
}
interface DeleteProjectParams {
  id: string;
  callBack?: () => void;
}
interface GetProjectsTypeaheadProps {
  callback?: (project: Project[]) => void;
  companyId: string;
  accountId: string;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetProjectsTypeaheadQueryProps {
  'filter[account_id]': string;
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}

interface GetProjectsByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  searchQuery?: string;
  filterParams?: { states: (ProjectStatusFilter | null)[] };
}

interface GetProjectsByCompanyIdTypeaheadProps {
  callback?: (project: Project[]) => void;
  companyId: string;
  accountId?: string;
  states?: ProjectState[];
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetProjectPhaseNameTypeaheadProps {
  projectId: string;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetProjectsByCompanyIdTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'filter[states]': ProjectState[];
  'filter[account_id]': string;
  'page[after]': string;
}

interface GetProjectPhaseNameTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'filter[states]': ProjectState[];
  'filter[account_id]': string;
  'page[after]': string;
}

export const useProjects = () => {
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);
  const [companyRelatedProjects, setCompanyRelatedProjects] = useState<Project[]>([]);
  const { projectsStore, toasterStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();
  const getProjectById = async (id: string) => {
    setIsLoadingProjects(true);
    try {
      const resp = await connection.get<ProjectProto>(
        `${API_VERSION}/projects/${id}`,
        {},
        $t('error_messages.projects.failed_to_fetch_project') as string,
      );
      const project = Project.parse(resp);
      // ProjectsStore.setProject(project);
      return project;
    } finally {
      setIsLoadingProjects(false);
    }
  };
  const createProject = async ({ project, callBack }: CreateProjectParams) => {
    setIsLoadingProjects(true);
    try {
      await connection
        .post<ProjectProto>(
          `${API_VERSION}/projects`,
          Project.deparse(project),
          {},
          $t('error_messages.projects.failed_to_create') as string,
          [422],
        )
        .then((resp) => {
          const projectSaved = Project.parse(resp);
          projectsStore.addAProject(projectSaved);
          callBack?.(projectSaved);
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };
  const updateProject = async ({ project, callBack }: UpdateProjectParams) => {
    setIsLoadingProjects(true);
    try {
      await connection
        .patch<ProjectProto>(
          `${API_VERSION}/projects/${project.id}`,
          Project.deparseUpdate(project),
          {},
          $t('error_messages.projects.failed_to_update') as string,
          [422],
        )
        .then((resp) => {
          const projectSaved = Project.parse(resp);
          projectsStore.updateProject(projectSaved);
          callBack?.(projectSaved);
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };
  const getProjectsByCompanyId = (companyId: string) => {
    setIsLoadingProjects(true);

    try {
      connection
        .get<
          ProjectProto[]
        >(`${API_VERSION}/companies/${companyId}/projects`, {}, $t('error_messages.projects.failed_to_fetch') as string)
        .then((resp: ProjectProto[]) => {
          const formatted = resp.map(Project.parse);
          setCompanyRelatedProjects(formatted);
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getProjectsByCompanyIdPaginated = async ({
    companyId,
    filterParams,
    link,
    searchQuery,
  }: GetProjectsByCompanyIdProps) => {
    setIsLoadingProjects(true);
    let params: PaginationQuery = {
      'page[limit]': projectsStore.projectsPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && projectsStore.projectsPagination[link]) {
      params[`page[${link}]`] = projectsStore.projectsPagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });
    try {
      await connection
        .getPaginated<ProjectProto>(
          `${API_VERSION}/companies/${companyId}/projects`,
          { params },
          $t('error_messages.projects.failed_to_fetch') as string,
        )
        .then(({ data, pagination }) => {
          const formatted = data.map(Project.parse);
          projectsStore.setProjects(formatted);
          projectsStore.setProjectsPagination(pagination);
          projectsStore.updateProjectsPageNumber(link);
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getProjectsByCompanyIdTypeahead = async (
    options: GetProjectsByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetProjectsByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.states) {
      params['filter[states]'] = options.states;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    if (options?.accountId) {
      params['filter[account_id]'] = options.accountId;
    }

    try {
      setIsLoadingProjects(true);
      const { data, pagination } = await connection.getPaginated<Project_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/projects/typeahead`,
        { params },
        $t('error_messages.projects.failed_to_fetch') as string,
      );
      const projects = (data as Project_Read[]).map((project) => Project.parse(project));
      options.callback?.(projects);
      return { data: projects, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getProjectExternalIdsTypeahead = async (
    options: GetProjectsByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetProjectsByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.states) {
      params['filter[states]'] = options.states;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoadingProjects(true);
      const { data, pagination } =
        await connection.getPaginated<Project_Read_Typeahead_External_Id>(
          `${API_VERSION}/projects/external_ids/typeahead`,
          {
            params,
          },
          $t('error_messages.projects.failed_to_fetch_external_ids') as string,
        );
      const projects = (data as Project_Read[]).map((project) => Project.parse(project));
      options.callback?.(projects);
      return { data: projects, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getCompanyProjects = (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoadingProjects(true);
    let params: PaginationQuery = {
      'page[limit]': projectsStore.projectsPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && projectsStore.projectsPagination[link]) {
      params[`page[${link}]`] = projectsStore.projectsPagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });
    try {
      connection
        .getPaginated<ProjectProto>(
          `${API_VERSION}/projects`,
          { params },
          $t('error_messages.projects.failed_to_fetch') as string,
        )
        .then(({ data, pagination }) => {
          const formatted = data.map(Project.parse);
          projectsStore.setProjects(formatted);
          projectsStore.setProjectsPagination(pagination);
          projectsStore.updateProjectsPageNumber(link);
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getCompanyProjectsTypeahead = async (options: GetProjectsTypeaheadProps) => {
    const params: Partial<GetProjectsTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 100,
    };

    if (options.accountId) {
      params['filter[account_id]'] = options.accountId;
    }

    if (options.query) {
      params['search[query]'] = options.query;
    }

    try {
      setIsLoadingProjects(true);
      const response = await connection.get<ProjectProto[]>(
        `${API_VERSION}/companies/${options.companyId}/projects/typeahead`,
        { params },
        $t('error_messages.projects.failed_to_fetch') as string,
      );
      const projects = response.map((project) => Project.parse(project));
      return options.callback?.(projects) ?? projects;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const deleteProject = async ({ id, callBack }: DeleteProjectParams) => {
    setIsLoadingProjects(true);
    try {
      await connection
        .delete(
          `${API_VERSION}/projects/${id}`,
          {},
          $t('error_messages.projects.failed_to_delete') as string,
        )
        .then(() => {
          projectsStore.deleteProject(id);
          callBack?.();
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };
  const activateProject = async (id: string) => {
    setIsLoadingProjects(true);
    try {
      await connection
        .put<ProjectProto>(
          `${API_VERSION}/projects/${id}/activate`,
          {},
          {},
          $t('error_messages.projects.failed_to_activate') as string,
        )
        .then((project) => {
          toasterStore.push(alert(t('project.activated'), AlertTypes.success));

          projectsStore.updateProject(Project.parse(project));
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };
  const deActivateProject = async (id: string) => {
    setIsLoadingProjects(true);
    try {
      await connection
        .put<ProjectProto>(
          `${API_VERSION}/projects/${id}/deactivate`,
          {},
          {},
          $t('error_messages.projects.failed_to_deactivate') as string,
        )
        .then((project) => {
          toasterStore.push(alert(t('project.deactivated'), AlertTypes.success));

          projectsStore.updateProject(Project.parse(project));
        });
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const getProjectPhasesTypeahead = async (
    options: GetProjectPhaseNameTypeaheadProps,
  ) => {
    const params: Partial<GetProjectPhaseNameTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoadingProjects(true);

      const { data, pagination } = await connection.getPaginated<Phase_Read_Typeahead>(
        `${API_VERSION}/projects/${options.projectId}/phases/typeahead`,
        {
          params,
        },
        $t('error_messages.projects.failed_to_fetch_external_ids') as string,
      );
      const projects = data.map((item) => Phase.parse(item));
      return { data: projects, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  return {
    isLoadingProjects,
    getProjectById,
    updateProject,
    deleteProject,
    getCompanyProjects,
    getProjectsByCompanyId,
    getProjectsByCompanyIdPaginated,
    getProjectsByCompanyIdTypeahead,
    getProjectExternalIdsTypeahead,
    companyRelatedProjects,
    createProject,
    activateProject,
    deActivateProject,
    getCompanyProjectsTypeahead,
    getProjectPhasesTypeahead,
  } as const;
};
