import {
  Identifier,
  patchV1SitesId,
  postV1Sites,
  Site_Create,
  Site_Update,
} from '@treadinc/horizon-api-spec';
import { convertLength } from '@turf/helpers';
import { t } from 'i18next';
import { useState } from 'react';

import { Site } from '~hooks/useSites/models';
import connection from '~services/connectionModule';
import { useStores } from '~store';

import { SiteFormV2Fields } from './types';

export const useSiteFormV2 = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { companyAssetsStore, ordersStore } = useStores();

  const createNewSite = async (data: SiteFormV2Fields) => {
    setIsUpdating(true);
    // The Site model is not well-typed and has a lot of 'any' types. This is a temporary solution.
    const payload = Site.deparse(data) as Site_Create;

    /*
     * The Site parse and deparse methods are littered with 'any' and hard to work with. When we don't have to support these variants,
     * we should move this logic into the Site model and fix the types.
     */
    if (data.drawingCoordinates?.length) {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: 'polygon',
          geojson: {
            type: 'Polygon',
            coordinates: [
              data.drawingCoordinates.map((coordinate) => [
                coordinate.lng,
                coordinate.lat,
              ]),
            ],
          },
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Circle') {
      const [lat, lon] = data.latLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: 'circle',
          circle_center: {
            lat: lat,
            lon: lon,
          },
          circle_radius: convertLength(Number(data.radius), 'feet', 'meters'),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Equipment') {
      const [lat, lon] = data.latLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          type: 'circle',
          circle_center: {
            lat: lat,
            lon: lon,
          },
          circle_radius: convertLength(Number(data.radius), 'feet', 'meters'),
        },
        moving_geofence: true,
      };
      payload['moving_site'] = {
        equipment_id: data.equipment?.id ?? '',
      };
    }
    try {
      const resp = await postV1Sites({ body: payload });
      const site = Site.parse(resp.data.data);
      companyAssetsStore.addSite(site);
      setIsUpdating(false);
      return site;
    } catch (error) {
      setIsUpdating(false);
      connection.handleRequestError(
        error,
        t('error_messages.sites.failed_to_create') as string,
      );
    }
  };

  const updateSite = async (data: SiteFormV2Fields & { id: string }) => {
    setIsUpdating(true);

    const payload = Site.deparseUpdate(data) as Site_Update;

    // Keeping for historical reasons, but this suggests deparsing improvements are needed.
    // Do not include an empty address.id in the update request.
    if (payload.address && !(payload.address as Identifier).id) {
      (payload.address as any).id = undefined;
    }

    /*
     * The Site parse and deparse methods are littered with 'any' and hard to work with. When we don't have to support these variants,
     * we should move this logic into the Site model and fix the types.
     */
    if (data.drawingCoordinates?.length) {
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: 'polygon',
          geojson: {
            type: 'Polygon',
            coordinates: [
              data.drawingCoordinates.map((coordinate) => [
                coordinate.lng,
                coordinate.lat,
              ]),
            ],
          },
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Circle') {
      const [lat, lon] = data.latLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: 'circle',
          circle_center: {
            lat: lat,
            lon: lon,
          },
          circle_radius: convertLength(Number(data.radius), 'feet', 'meters'),
        },
        moving_geofence: false,
      };
    }
    if (data.geofenceType?.name === 'Equipment') {
      const [lat, lon] = data.latLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      payload['next_billion_geofence'] = {
        geofence: {
          name: data.name ?? '',
          id: data.geofenceId ?? undefined,
          type: 'circle',
          circle_center: {
            lat: lat,
            lon: lon,
          },
          circle_radius: convertLength(Number(data.radius), 'feet', 'meters'),
        },
        moving_geofence: true,
      };
      payload['moving_site'] = {
        equipment_id: data.equipment?.id,
      };
    }
    if (data.geofenceType?.name === 'None') {
      payload['next_billion_geofence'] = data.geofenceId
        ? {
            //@ts-ignore update the types here
            id: data.geofenceId,
            _destroy: 1,
          }
        : undefined;
    }
    try {
      const resp = await patchV1SitesId({ path: { id: data.id }, body: payload });
      const site = Site.parse(resp.data.data);
      companyAssetsStore.updateSite(site);
      ordersStore.setOrderUpdatesOccurred(true);
      setIsUpdating(false);
      return site;
    } catch (error) {
      setIsUpdating(false);
      connection.handleRequestError(
        error,
        t('error_messages.sites.failed_to_update') as string,
      );
    }
  };

  return {
    createNewSite,
    isUpdating,
    updateSite,
  };
};
