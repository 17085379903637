import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { LngLat } from '@nbai/nbmap-gl';
import { GoogleMap, LoadScript, TrafficLayer } from '@react-google-maps/api';
import { isNumber } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';

export enum GoogleMapViews {
  HYBRID = 'hybrid',
  ROADMAP = 'roadmap',
}
// Custom control so we can override the default map type control in Google Maps
function CustomControl({
  map,
  callback,
  changeView,
  view,
}: {
  map: google.maps.Map;
  callback: (x: boolean) => void;
  changeView: (view: GoogleMapViews) => void;
  view: GoogleMapViews;
}) {
  useEffect(() => {
    if (map) {
      // Create a container for the custom map type control
      const mapTypeControlDiv = document.createElement('div');
      mapTypeControlDiv.style.margin = '10px';

      // Style for the control container
      const controlUI = document.createElement('div');
      controlUI.style.display = 'inline-flex';
      controlUI.style.backgroundColor = 'white';
      controlUI.style.border = '0px solid #e5e7eb';
      controlUI.style.borderRadius = '4px';
      controlUI.style.boxSizing = 'inherit';
      controlUI.style.cursor = 'pointer';
      mapTypeControlDiv.appendChild(controlUI);

      // Create buttons for each map type
      const mapTypes = [
        { type: 'roadmap', label: 'Default' },
        { type: 'hybrid', label: 'Satellite' },
        { type: 'roadmap', label: 'Traffic' },
      ];

      mapTypes.forEach(({ type, label }) => {
        const notSelected = label === 'Default' || type !== view;
        const button = document.createElement('div');
        button.style.display = 'inline-flex';
        button.style.fontFamily = 'Roboto,Arial,sans-serif';
        button.style.fontWeight = '500';
        button.style.backgroundColor = notSelected ? 'white' : 'rgba(0, 0, 0, 0.08)';
        button.style.color = notSelected ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.87)';
        button.style.fontSize = '0.8125rem';
        button.style.lineHeight = '1.75';
        button.style.padding = '7px'; // Add padding to buttons
        button.style.textAlign = 'center';
        button.style.cursor = 'pointer';
        button.style.border = '1px solid rgba(0, 0, 0, 0.12)';
        button.style.borderRadius = '4px'; // Round button corners
        button.style.borderTopLeftRadius = label === 'Default' ? '4px' : '0px'; // Round top left corner of the first button
        button.style.borderBottomLeftRadius = label === 'Default' ? '4px' : '0px'; // Round bottom left corner of the first button
        button.style.borderTopRightRadius = label === 'Traffic' ? '4px' : '0px'; // Round top right corner of the second button
        button.style.borderBottomRightRadius = label === 'Traffic' ? '4px' : '0px'; // Round bottom right corner of the second button
        button.style.borderLeftWidth = label === 'Satellite' ? '0px' : '1px'; // Remove left border from middle button
        button.style.borderRightWidth = label === 'Satellite' ? '0px' : '1px'; // Remove left border from middle button

        button.addEventListener('mouseenter', () => {
          button.style.backgroundColor = 'rgb(0 0 0 / 5%)';
        });

        button.addEventListener('mouseleave', () => {
          button.style.backgroundColor =
            type === 'roadmap' ? 'white' : 'rgba(0, 0, 0, 0.08)';
        });
        button.innerHTML = label;

        button.addEventListener('click', () => {
          if (label === 'Default' && map) {
            callback(false);
            changeView(GoogleMapViews.HYBRID);
          } else if (label === 'Traffic' && map) {
            callback(true);
            changeView(GoogleMapViews.ROADMAP);
          } else if (label === 'Satellite' && map) {
            callback(true);
            changeView(GoogleMapViews.HYBRID);
          }
        });
        controlUI.appendChild(button);
      });

      // Add custom controls to the map
      map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(
        mapTypeControlDiv,
      );

      // Cleanup on unmount
      return () => {
        if (map) {
          const controls = map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT];
          if (controls && controls.getLength() > 0) {
            controls.removeAt(0);
          }
        }
      };
    }
  }, [map, view]);

  return null;
}

const GoogleBaseMap = ({
  center,
  zoom,
  setShowGoogleMap,
  googleMapView,
  setGoogleMapView,
  sx,
}: {
  center: LngLat;
  zoom: number;
  setShowGoogleMap: (show: boolean) => void;
  googleMapView: GoogleMapViews;
  setGoogleMapView: (view: GoogleMapViews) => void;
  sx?: SxProps<Theme>;
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null);
  const key = import.meta.env.TREAD__GOOGLE_MAPS_API_KEY;

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapInstance(map);

    if (googleMapView === GoogleMapViews.ROADMAP) {
      mapRef.current?.setMapTypeId('roadmap');
    } else {
      mapRef.current?.setMapTypeId('hybrid');
    }
  };
  const onUnmount = useCallback(() => {
    setMapInstance(null);
  }, []);

  return (
    <LoadScript googleMapsApiKey={key}>
      <GoogleMap
        mapContainerStyle={sx as React.CSSProperties}
        center={{
          lat: isNumber(center.lat) ? center.lat : parseFloat(center.lat),
          lng: isNumber(center.lng) ? center.lng : parseFloat(center.lng),
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapTypeId: googleMapView === GoogleMapViews.ROADMAP ? 'roadmap' : 'hybrid',
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: false,
          mapTypeControlOptions: {
            position: window?.google?.maps.ControlPosition.BOTTOM_LEFT,
          },
          zoomControlOptions: {
            position: window?.google?.maps.ControlPosition.TOP_RIGHT,
          },
          fullscreenControlOptions: {
            position: window?.google?.maps.ControlPosition.BOTTOM_RIGHT,
          },
          disableDefaultUI: true,
        }}
      >
        {googleMapView === GoogleMapViews.ROADMAP && <TrafficLayer />}
        {mapInstance && (
          <CustomControl
            map={mapInstance}
            callback={setShowGoogleMap}
            changeView={setGoogleMapView}
            view={googleMapView}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleBaseMap;
