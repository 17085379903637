import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FileAttachment_Read, JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Drawer } from '~components/Drawer/src/ui';
import { NotSpecified } from '~components/Helpers/NotSpecified';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { OrderUnitOfMeasure } from '~constants/enums';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useFileAttachment } from '~hooks/useFileAttachment';
import { Job, useJob } from '~hooks/useJob';
import { JobLoadCycle, JobLoadSummary } from '~hooks/useJob/models';
import { Ticket, TicketEventType, useTickets } from '~hooks/useTickets';
import { TicketFormDTO } from '~pages/Approvals/ApprovalsComponents/ticketFormSchema';
import { TicketDetails } from '~pages/Approvals/TicketsReviewDataGrid/TicketDetails';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';

import LoadsTable from './LoadsTable';

interface Props {
  details: Job;
  reload: () => void;
  isEditing?: boolean;
}

export default function Loads({ details, reload, isEditing = false }: Props) {
  const { toasterStore, userStore } = useStores();
  const {
    createTicket,
    deleteTicketImageById,
    getAllTicketsByJob,
    updateState,
    updateTicketById,
    uploadTicketImageById,
  } = useTickets();
  const [selectedTicket, setSelectedTicket] = useState<{
    isDrawerOpen: boolean;
    loadId?: string;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const imagePendingToBeUploaded = useRef<File>();

  const userPermissions = userStore.getPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [jobLoads, setJobLoads] = useState<JobLoadSummary[]>([]);
  const [jobTickets, setJobTickets] = useState<Ticket[]>([]);
  const { removeLoad, addLoad, getJobById, getJobLoadCycles, getJobLoads, approveLoad } =
    useJob();
  const { getAllFileAttachmentsById, deleteFileAttachmentById } = useFileAttachment();
  const [cycles, setCycles] = useState<Array<JobLoadCycle>>([]);

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const saveTicket = async (ticketDto: TicketFormDTO) => {
    let ticket: Ticket;
    const isUpdate = Boolean(ticketDto.id);

    if (isUpdate) {
      ticket = await updateTicketById({
        id: String(ticketDto.id),
        data: {
          quantity: ticketDto.quantity,
          unitOfMeasure: ticketDto.unitOfMeasure,
          material: ticketDto.material.id ? { id: ticketDto.material.id } : undefined,
          ticketNumber: ticketDto.ticketNumber,
          imageUrl: ticketDto.imageUrl,
        },
      });
    } else {
      ticket = await createTicket({
        data: {
          job_id: ticketDto.jobId,
          load_id: selectedTicket.loadId,
          material_id: ticketDto.material.id || undefined,
          quantity: ticketDto.quantity,
          ticket_number: ticketDto.ticketNumber,
          unit_of_measure: ticketDto.unitOfMeasure.id,
        },
      });

      if (imagePendingToBeUploaded.current) {
        ticket = await uploadTicketImageById({
          id: ticket.id,
          file: imagePendingToBeUploaded.current,
        });
      }
    }

    if (ticket) {
      const message = isUpdate ? 'approvals.ticket_updated' : 'approvals.ticket_created';

      toasterStore.push(alert(t(message), AlertTypes.success));
      setSelectedTicket((state) => ({ ...state, ticket }));
      reload();
    }

    return ticket;
  };

  const handleFlagTicket = async (ticket: Ticket) => {
    ticket.flagged = !ticket.flagged;

    const updatedTicket = await updateTicketById({ id: ticket.id, data: ticket });

    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
    reload();

    return updatedTicket;
  };

  const handleSelectedTicketChange = useCallback((loadId: string, ticket?: Ticket) => {
    setSelectedTicket({ isDrawerOpen: true, loadId, ticket });
  }, []);

  const handleRemoveLoad = useCallback(
    async (loadId: string) => {
      await removeLoad(loadId);
      await getJobById(details.id);

      reload();
      toasterStore.push(alert(t('loads.load_removed'), AlertTypes.success));
    },
    [details.id, reload],
  );

  const onAddNew = () => {
    addLoad(details.id, {
      quantity: 1,
      unitOfMeasure: {
        id: OrderUnitOfMeasure.LOAD,
        name: OrderUnitOfMeasure.LOAD,
      } as ItemNameAndId,
    })
      .then(() => {
        toasterStore.push(alert(t('loads.load_added'), AlertTypes.success));
      })
      .then(() => {
        getJobById(details.id);
      })
      .then(() => {
        reload();
      });
  };

  useEffect(() => {
    function getInitialData() {
      const loadCyclesRequest = getJobLoadCycles(details.id).then((res) => {
        setCycles(res);
      });

      const jobLoadsRequest = getJobLoads(details.id).then((response) => {
        setJobLoads(response);
      });

      const jobTicketsRequest = getAllTicketsByJob(details.id).then((response) => {
        setJobTickets(response);
      });

      return Promise.all([loadCyclesRequest, jobLoadsRequest, jobTicketsRequest]);
    }

    setIsLoading(true);
    getInitialData().then(() => setIsLoading(false));
  }, [details]);

  useEffect(() => {
    if (!selectedTicket.isDrawerOpen) {
      imagePendingToBeUploaded.current = undefined;

      // remove added attachments if the drawer was closed without saving the ticket
      const shouldRemoveAttachments = Boolean(
        !selectedTicket.ticket?.id && selectedTicket.loadId,
      );

      if (shouldRemoveAttachments) {
        getAllFileAttachmentsById(
          String(selectedTicket.loadId),
          FileAttachment_Read.file_attachable_type.LOAD,
        ).then((attachments) => {
          attachments.map((attachment) => deleteFileAttachmentById(attachment.id));
        });
      }
    }
  }, [selectedTicket.isDrawerOpen, selectedTicket.loadId, selectedTicket.ticket?.id]);

  const allLoadsApprovable = useMemo(() => {
    return !jobLoads.length
      ? false
      : jobLoads.every((load) => {
          return load.approvable;
        });
  }, [jobLoads]);

  const onApproveAllLoads = () => {
    Promise.all(
      jobLoads.map((load) => {
        if (load.approvable) {
          return approveLoad(load.id);
        }
        return null;
      }),
    ).then(() => {
      toasterStore.push(alert(t('approvals.loads_approved'), AlertTypes.success));
      reload();
    });
  };

  return (
    <>
      <Box>
        {/* header */}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            pr: 1.5,
            pl: 0.5,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {t('approvals.driver_day.loads')}
          </Typography>
          {allLoadsApprovable && (
            <SmallButton
              color="brandV2Yellow"
              sx={{
                '&.MuiButtonBase-root': {
                  height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                },
              }}
              onClick={onApproveAllLoads}
              disabled={isEditing}
            >
              {t('approvals.driver_day.approve_loads')}
            </SmallButton>
          )}
        </Box>

        {jobLoads.length ? (
          <LoadsTable
            cycles={cycles}
            jobTickets={jobTickets}
            loads={jobLoads}
            onLoadCardClick={handleSelectedTicketChange}
            onRemoveLoadClick={handleRemoveLoad}
            reload={reload}
            sx={{ mt: 2 }}
            isEditing={isEditing}
          />
        ) : (
          <NotSpecified />
        )}

        {userPermissions.canCreateLoad &&
          ![JobState.REJECTED, JobState.COMPLETED, JobState.CANCELED].includes(
            details.status,
          ) && (
            <Box mt={2}>
              <SmallButton
                onClick={onAddNew}
                color={'brandV2Yellow'}
                disabled={isLoading || isEditing}
              >
                {`+ ${t('loads.add')}`}
              </SmallButton>
            </Box>
          )}
      </Box>

      <Drawer
        anchor="right"
        onClose={() => setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }))}
        open={selectedTicket.isDrawerOpen}
        variant="temporary"
      >
        <TicketDetails
          job={details}
          loadId={selectedTicket.loadId}
          ticket={selectedTicket.ticket}
          onFlag={handleFlagTicket}
          onApprove={async (ticket) => {
            const approvedTicket = await doAction(ticket.id, 'approve');

            if (approvedTicket) {
              setSelectedTicket((state) => ({ ...state, ticket: approvedTicket }));
              reload();
            }
          }}
          onRemoveImage={async (ticket) => {
            await deleteTicketImageById({ id: ticket.id });
            const newTicket = ticket;
            newTicket.imageUrl = '';
            setSelectedTicket((state) => ({ ...state, ticket: newTicket }));
          }}
          onUploadImage={async (ticket, image) => {
            if (ticket) {
              const updatedTicket = await uploadTicketImageById({
                id: ticket.id,
                file: image,
              });

              if (updatedTicket) {
                setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
              }
            } else {
              imagePendingToBeUploaded.current = image;
            }
          }}
          onChange={saveTicket}
          onClose={() => {
            setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }));
          }}
        />
      </Drawer>
    </>
  );
}
