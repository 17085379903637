import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { WaypointType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { useEquipment } from '~hooks/useEquipment';
import { useMaterials } from '~hooks/useMaterials';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useServices } from '~hooks/useServices';
import { Site, useSites } from '~hooks/useSites';
import { createSitesInline } from '~pages/Sales/Orders/helpers';
import { NewOrderForm, NewOrderFormHandler } from '~pages/Sales/Orders/NewOrderForm';
import { OrderForm as LegacyOrderForm } from '~pages/Sales/Orders/OrderForm';
import { OrderFormSchemaInterface } from '~pages/Sales/Orders/orderFormSchema';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

type AddDefaultSiteParams = {
  siteId: string;
  waypointType: WaypointType;
};

type NewDefaultSitesState = {
  pickUp: AddDefaultSiteParams[];
  dropOff: AddDefaultSiteParams[];
};

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteParams>;

interface OrderFormProps {
  isOpen: boolean;
  newOrderFormFeatureFlagEnabled?: boolean;
  onClose: () => void;
  order?: Order;
}

const OrderForm = observer(
  ({ isOpen, newOrderFormFeatureFlagEnabled, onClose, order }: OrderFormProps) => {
    const isEditing = Boolean(order?.id);

    const formRef = useRef<NewOrderFormHandler>(null);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [newDefaultSites, setNewDefaultSites] = useState<NewDefaultSitesState>({
      pickUp: [],
      dropOff: [],
    });
    const { addSiteToOrderDefaultSites, createNewSite } = useSites();
    const { userStore, ordersDispatchStore, companyAssetsStore, toasterStore } =
      useStores();
    const { createOrder, updateOrder } = useOrdersDispatch();

    const companyId = userStore.userCompany?.id;
    const [companyOptions, setCompanyOptions] = useState<CompanyBasic[]>([]);
    const { getAllUserAvailableCompanies, isLoadingCompanies } = useCompany();

    const equipment = companyAssetsStore.equipment;
    const { getEquipmentByCompanyId, isLoading: isLoadingEquipment } = useEquipment();

    const materials = companyAssetsStore.allMaterials;
    const { getAllMaterials, isLoading: isLoadingMaterials } = useMaterials();

    const services = companyAssetsStore.services;
    const { getAllServices, isLoading: isLoadingServices } = useServices();

    const isLoading =
      isLoadingCompanies || isLoadingEquipment || isLoadingMaterials || isLoadingServices;

    const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
    const cancelClose = () => {
      setShowConfirmCloseDialog(false);
    };
    const confirmClose = () => {
      onClose();
      setShowConfirmCloseDialog(false);
    };

    const onOrderCreated = useCallback(
      async (orderId: string) => {
        const sites = [...newDefaultSites.pickUp, ...newDefaultSites.dropOff];

        if (sites.length > 0) {
          await sites.reduce(
            async (acc, site) => {
              await acc;
              return await addSiteToOrderDefaultSites({
                orderId,
                siteId: site.siteId,
                waypointType: site.waypointType,
              });
            },
            Promise.resolve({} as Site),
          );
        }

        formRef.current?.fileAttachmentsOnSubmit(orderId);
      },
      [newDefaultSites.pickUp, newDefaultSites.dropOff],
    );

    const handleClose = useCallback(() => {
      setNewDefaultSites({ pickUp: [], dropOff: [] });
      onClose();
    }, [onClose]);

    const handleCancel = useCallback(() => {
      formRef.current?.fileAttachmentsOnClose();
      if (formIsDirty) {
        setShowConfirmCloseDialog(true);
      } else {
        handleClose();
      }
    }, []);

    const handleFormStateChange = useCallback(
      ({ isDirty, siteId, waypointType }: NewOrderFormStateChange) => {
        setFormIsDirty(isDirty);

        if (siteId) {
          if (waypointType === WaypointType.PICKUP) {
            setNewDefaultSites((sites) => ({
              ...sites,
              pickUp: _.uniqBy([...sites.pickUp, { siteId, waypointType }], 'siteId'),
            }));
          }

          if (waypointType === WaypointType.DROP_OFF) {
            setNewDefaultSites((sites) => ({
              ...sites,
              dropOff: _.uniqBy([...sites.dropOff, { siteId, waypointType }], 'siteId'),
            }));
          }
        }
      },
      [],
    );

    const saveOrder = useCallback(
      async (formData: OrderFormSchemaInterface) => {
        let newOrderId = '';
        const newFormData = (await createSitesInline(
          formData,
          createNewSite,
          userStore.userCompany?.siteRadius,
        )) as OrderFormSchemaInterface;

        if (isEditing) {
          const updatedOrder = await updateOrder(newFormData);
          newOrderId = updatedOrder.orderId;

          formRef.current?.updateProjectDefaultSites();
          formRef.current?.fileAttachmentsOnSubmit(newOrderId);
        } else {
          const createdOrder = await createOrder(newFormData);

          if (createdOrder?.id) {
            newOrderId = createdOrder.orderId;
            await onOrderCreated(createdOrder.id);
          }
        }

        toasterStore.push(
          alert(
            isEditing
              ? t('order.order_updated', { name: newOrderId })
              : t('order.order_created', { name: newOrderId }),
            AlertTypes.success,
          ),
        );
        handleClose();
      },
      [isEditing, onOrderCreated, handleClose],
    );

    const handleOrderFormSubmit = useCallback(() => {
      // @ts-ignore Submit handler is not properly typed in the forms
      formRef.current?.submit((data: OrderFormSchemaInterface) => {
        saveOrder(data);
      });
    }, [saveOrder]);

    useEffect(() => {
      if (!companyId) {
        return;
      }

      if (!companyOptions.length)
        getAllUserAvailableCompanies({
          id: companyId,
          callBack: (companies) => setCompanyOptions(companies),
        });

      if (!equipment?.length) {
        getEquipmentByCompanyId({ companyId });
      }

      if (!materials?.length) {
        getAllMaterials();
      }

      if (!services?.length) {
        getAllServices();
      }
    }, [
      companyId,
      companyOptions.length,
      equipment?.length,
      materials?.length,
      services?.length,
    ]);

    return (
      <>
        <Dialog
          maxWidth="lg"
          open={isOpen}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            if (isActionClicked(reason)) {
              handleClose();
            }
          }}
        >
          <DialogHeader
            closeCallBack={handleCancel}
            title={
              <>
                <Typography component="span" variant="h5">
                  {order ? t('order.update_order') : t('order.create_order')}
                </Typography>

                {order && <Typography>{order.orderId}</Typography>}
              </>
            }
          />

          <DialogContent
            key={order?.id}
            sx={{ backgroundColor: theme.palette.grey[100] }}
          >
            {newOrderFormFeatureFlagEnabled ? (
              <NewOrderForm
                ref={formRef}
                company={companyOptions.length === 1 ? companyOptions[0] : null}
                defaultOrder={order ?? null}
                onFormStateChange={handleFormStateChange}
              />
            ) : (
              <LegacyOrderForm
                ref={formRef}
                company={companyOptions.length === 1 ? companyOptions[0] : null}
                defaultOrder={order ?? null}
                onFormStateChange={handleFormStateChange}
              />
            )}
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <LoadingSpinner isVisible={isLoading} />
              </Box>
            )}
          </DialogContent>

          <DialogActions
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'flex-start',
              m: 0,
              p: 2,
            }}
          >
            <LoadingButton
              color="primary"
              disabled={ordersDispatchStore.isSavingOrder || !formIsDirty}
              loading={ordersDispatchStore.isSavingOrder}
              loadingPosition="start"
              onClick={handleOrderFormSubmit}
              startIcon={<></>}
              sx={ordersDispatchStore.isSavingOrder ? { pl: 5, pr: 2 } : { px: 2 }}
              type="button"
              variant="contained"
            >
              {t(`actions.${order ? 'submit' : 'create'}`)}
            </LoadingButton>

            <Button
              color="secondary"
              disabled={ordersDispatchStore.isSavingOrder}
              onClick={handleCancel}
              sx={{ mr: 2, px: 2 }}
              variant="outlined"
            >
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmCloseDialog}
          onClose={(_: never, reason: DialogCloseReasonType) => {
            isActionClicked(reason) && cancelClose();
          }}
        >
          <DialogHeader
            closeCallBack={cancelClose}
            title={t(`project.confirm_exit_title`)}
          />
          <DialogContent>{t(`project.confirm_exit_text`)}</DialogContent>
          <DialogActions>
            <Button onClick={cancelClose} color="secondary" variant="outlined">
              {t(`actions.no`)}
            </Button>
            <Button onClick={confirmClose} color="primary">
              {t(`actions.exit`)}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

export default OrderForm;
