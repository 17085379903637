import { useCallback } from 'react';

import useLiveMapFilters from '~hooks/useLiveMap/useLiveMapFilters';
import useCalendarDispatchFilters from '~pages/Dispatch/hooks/useCalendarDispatchFilters';
import useOrdersDispatchFilters from '~pages/Dispatch/hooks/useOrdersDispatchFilters';
import { NewDispatchView } from '~pages/Dispatch/NewDispatch';
import { useCompanySelectorVisibility } from '~pages/Settings/useCompanySelectorVisibility';
import { routes } from '~router';
import { useStores } from '~store';

const useCompanySelector = () => {
  const { userStore } = useStores();
  const { handleDispatchOrdersFiltersReset } = useOrdersDispatchFilters();
  const { handleDispatchCalendarFiltersReset } = useCalendarDispatchFilters();
  const { handleLiveMapFiltersReset } = useLiveMapFilters();

  const routesWithQueryParams = [
    {
      path: routes.dispatch.base,
      queryParams: [
        {
          key: 'view',
          values: [NewDispatchView.ORDERS, NewDispatchView.CALENDAR],
        },
      ],
    },
    { path: `${routes.sales.base}/${routes.sales.projects}` },
    { path: `${routes.sales.base}/${routes.sales.orders}` },
    { path: `${routes.live}` },
    { path: `${routes.settings.base}/${routes.settings.generalSettings}` },
    { path: `${routes.settings.base}/${routes.settings.userManagement}` },
    { path: `${routes.settings.base}/${routes.settings.drivers}` },
    { path: `${routes.settings.base}/${routes.settings.accounts}` },
    { path: `${routes.settings.base}/${routes.settings.customers}` },
    { path: `${routes.settings.base}/${routes.settings.vendors}` },
    { path: `${routes.settings.base}/${routes.settings.equipmentTypeManagement}` },
    { path: `${routes.settings.base}/${routes.settings.equipmentManagement}` },
    { path: `${routes.settings.base}/${routes.settings.materialsManagement}` },
    { path: `${routes.settings.base}/${routes.settings.ratesManagement}` },
    { path: `${routes.settings.base}/${routes.settings.addOnCharges}` },
    { path: `${routes.settings.base}/${routes.settings.sitesManagement}` },
    { path: `${routes.settings.base}/${routes.settings.departmentsManagement}` },
    { path: `${routes.settings.base}/${routes.settings.serviceClassesManagement}` },
    { path: `${routes.reports}` },
  ];

  const hasChildrenCompanies = userStore.userCompanies.length > 1;

  const showCompanySelector =
    useCompanySelectorVisibility(routesWithQueryParams) && hasChildrenCompanies;

  const handleCompanySelectorChange = useCallback(() => {
    handleDispatchOrdersFiltersReset();
    handleDispatchCalendarFiltersReset();
    handleLiveMapFiltersReset();
  }, [
    handleDispatchOrdersFiltersReset,
    handleDispatchCalendarFiltersReset,
    handleLiveMapFiltersReset,
  ]);

  return {
    showCompanySelector,
    handleCompanySelectorChange: showCompanySelector
      ? handleCompanySelectorChange
      : undefined,
  };
};

export default useCompanySelector;
