import { yupResolver } from '@hookform/resolvers/yup';
import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import { convertLength } from '@turf/helpers';
import { t } from 'i18next';
import { get } from 'lodash';
import {
  ComponentProps,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { GeofenceType, MarkerType } from '~components/Maps/v2/constants';
import { MapV2 } from '~components/Maps/v2/MapV2';
import { AddressOptionForNewSite } from '~components/Order/SiteSelection/types';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import {
  data as enums,
  geofenceNoneOption,
  geofenceOptions,
  GeoFenceTypes,
} from '~constants/enums';
import { latLonSchema } from '~constants/regexConst';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic } from '~hooks/useCompany';
import { useEquipment } from '~hooks/useEquipment';
import { useGeocode } from '~hooks/useGeocode';
import { Geofence, Site } from '~hooks/useSites';
import { useStores } from '~store';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

import { SiteFormV2Fields } from './types';

const gap = 2;

const geofenceToItemNameAndId = (geofence: Geofence) => {
  const { geofenceType, movingGeofence } = geofence;
  geofence?.movingGeofence;
  if (movingGeofence) {
    return ItemNameAndId.parse({
      name: t('geofence.equipment'),
      id: GeoFenceTypes.EQUIPMENT,
    });
  }
  return geofenceOptions.find((item) => item.id === geofenceType);
};

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  if (fieldName?.includes('address')) {
    fieldName = 'address';
  }
  switch (fieldName) {
    case 'address':
      return {
        field: 'address',
        message: defaultMessage,
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

const formSchema = yup.object().shape({
  company: yup
    .object()
    .shape({
      legalName: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  name: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.name'),
    })}`,
  ),
  siteType: yup.string().required(
    `${t('form_validation_errors.required', {
      field: t('form_fields.site_type'),
    })}`,
  ),
  externalId: yup.string().notRequired(),
  geofenceType: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  geofenceId: yup.string().notRequired(),
  radius: yup.number().when('geofenceType.id', {
    is: (type: string) => ['circle', 'equipment'].includes(type),
    then: (schema) => {
      return schema.required().min(1).max(50000).integer();
    },
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  equipment: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
    })
    .when('geofenceType.id', {
      is: 'equipment',
      then: (schema) => {
        return schema.required();
      },
      otherwise: (schema) => {
        return schema.notRequired().nullable();
      },
    }),
  notes: yup.string().notRequired(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      placeId: yup.string(),
    })
    .notRequired(),
  latLon: latLonSchema.required(),
  drawingCoordinates: yup
    .array()
    .of(
      yup.object().shape({
        lat: yup
          .number()
          .required('Latitude is required')
          .typeError('Latitude must be a number'),
        lng: yup
          .number()
          .required('Longitude is required')
          .typeError('Longitude must be a number'),
      }),
    )
    .required('Coordinates are required'),
});

interface SiteFormRef {
  submit: (callback: () => void) => void;
  resetForm: (callback?: () => void) => void;
}

interface SiteFormProps {
  defaultSite?: Nullable<Site>;
  addressOption?: Nullable<AddressOptionForNewSite>;
  company?: Nullable<CompanyBasic>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  isInline?: boolean;
  errors?: ModelError_Item[];
}

export const SiteFormV2 = forwardRef(function SiteFormV2(
  {
    addressOption,
    defaultSite,
    onFormStateChange,
    company,
    errors: errorsProp,
  }: SiteFormProps,
  ref: Ref<SiteFormRef>,
) {
  const { getPlaces } = useGeocode({ addressProvider: 'google_maps_geocoder' });
  const { getEquipmentByCompanyId } = useEquipment();

  const { companyAssetsStore } = useStores();
  const equipmentOptions = companyAssetsStore.equipment;

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<SiteFormV2Fields>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      company: company ? { legalName: company.legalName, id: company.id } : null,
      name: defaultSite?.name ?? null,
      siteType: defaultSite?.siteType ?? null,
      externalId: defaultSite?.externalId ?? null,
      geofenceType: defaultSite?.nextBillionGeofence
        ? geofenceToItemNameAndId(defaultSite.nextBillionGeofence)
        : null,
      geofenceId: defaultSite?.nextBillionGeofence?.id ?? null,
      radius: defaultSite?.nextBillionGeofence?.circleRadius
        ? Math.round(
            Number(
              convertLength(
                defaultSite?.nextBillionGeofence?.circleRadius,
                'meters',
                'feet',
              ),
            ),
          )
        : null,
      equipment:
        equipmentOptions.find(
          (item) => item.id === defaultSite?.movingSite?.equipment_id,
        ) ?? null,
      notes: defaultSite?.notes ?? null,
      address: defaultSite?.address ?? null,
      latLon: defaultSite?.latLon ?? '',
      drawingCoordinates: [],
    },
    delayError: 500,
  });

  const watchedLatLon = watch('latLon');
  const watchedGeofenceType = watch('geofenceType');
  const geoCodedAddress = watch('address');
  const watchedRadius = watch('radius');
  const watchedDrawingCoordinates = watch('drawingCoordinates');

  const refreshPosition = useCallback(() => {
    if (geoCodedAddress) {
      setValue('latLon', `${geoCodedAddress?.lat},${geoCodedAddress?.lng}`);
    } else if (defaultSite?.latLon) {
      setValue('latLon', defaultSite?.latLon);
    } else {
      setValue('latLon', '');
    }
  }, [geoCodedAddress, setValue]);

  useEffect(() => {
    refreshPosition();
  }, [geoCodedAddress]);

  useEffect(() => {
    // If the form state changes, we need to notify the parent component. This is from the first SiteForm, but
    // we should look into improving this.
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  useEffect(() => {
    if (company?.id) {
      getEquipmentByCompanyId({ companyId: company.id, shared: false });
    }
  }, [company?.id]);

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: newErrorField.message,
          });
        }
      }
    }
  }, [errorsProp]);

  useEffect(() => {
    // If the geofence type changes, we need to clear the drawing coordinates
    if (
      watchedGeofenceType?.id !== GeoFenceTypes.POLYGON &&
      watchedDrawingCoordinates.length
    ) {
      setValue('drawingCoordinates', []);
    }
    if (
      watchedGeofenceType?.id !== GeoFenceTypes.CIRCLE &&
      watchedGeofenceType?.id !== GeoFenceTypes.EQUIPMENT &&
      !!watchedRadius
    ) {
      setValue('radius', null);
    }
    if (
      watchedGeofenceType?.id === GeoFenceTypes.POLYGON &&
      defaultSite?.nextBillionGeofence?.geojson?.coordinates.length
    ) {
      /*
       * I don't have time to fix it in this pass, but the model definition is incorrect
       * as an always empty list, so we cast here.
       */
      const coordinatesArr = defaultSite.nextBillionGeofence.geojson.coordinates as [
        number,
        number,
      ][][];
      const coordinates = coordinatesArr[0] ?? [];
      setValue(
        'drawingCoordinates',
        coordinates.map((coordinate) => ({
          lat: coordinate[1],
          lng: coordinate[0],
        })),
      );
    }
  }, [watchedGeofenceType, defaultSite]);

  const center = useMemo(() => {
    if (addressOption?.lat && addressOption?.lng) {
      return {
        lat: addressOption.lat,
        lng: addressOption.lng,
      };
    }
    if (defaultSite?.lat && defaultSite?.lng) {
      return {
        lat: defaultSite.lat,
        lng: defaultSite.lng,
      };
    }
  }, [addressOption?.lng, addressOption?.lat, defaultSite?.lng, defaultSite?.lat]);

  const locationMarkerAsArray: ComponentProps<typeof MapV2>['markers'] = useMemo(() => {
    if (
      watchedLatLon.split(',').length === 2 &&
      watchedLatLon.split(',').every((v) => !isNaN(parseFloat(v.trim())))
    ) {
      const [lat, lon] = watchedLatLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      return [
        {
          id: 'lat-lon-marker',
          lat,
          lng: lon,
          type: MarkerType.site,
          geofenceType: GeofenceType.circle,
          radius: watchedRadius
            ? convertLength(Number(watchedRadius), 'feet', 'meters')
            : 0,
        },
      ];
    }
    return undefined;
  }, [watchedLatLon, watchedRadius]);

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap }}>
      <CompanySelectorFormField
        control={control}
        disabled={!!defaultSite?.company?.id}
        errors={errors}
        name={'company'}
        sx={{ gridColumn: '1 / -1' }}
      />
      <Box
        sx={{
          alignContent: 'start',
          columnGap: gap,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <TextFormField
          control={control}
          errors={errors}
          isRequired={true}
          label={`${t('form_fields.name')}`}
          name="name"
          sx={{ mr: 2 }}
        />
        <AutocompleteFormField
          control={control}
          errors={errors}
          getLabel={(item) => item}
          getValue={(item) => item}
          isRequired={true}
          label={`${t('form_fields.site_type')}`}
          list={enums.site_type.values}
          name="siteType"
        />
        <TextFormField
          control={control}
          errors={errors}
          isRequired={false}
          label={`${t('form_fields.id')}`}
          name="externalId"
        />
        <Typography sx={{ gridColumn: '1 / -1', my: 2 }}>
          {t('form_fields.primary_geo_fence')}
        </Typography>
        <AutocompleteFormField
          control={control}
          disabled={watchedLatLon.length === 0}
          errors={errors}
          getLabel={(item) => item?.name}
          getValue={(item) => item?.id}
          label={`${t('form_fields.geo_fence_type')}`}
          list={[...geofenceOptions, geofenceNoneOption]}
          name="geofenceType"
        />
        {watchedGeofenceType?.id === GeoFenceTypes.POLYGON && (
          <Typography
            sx={{
              color: 'grey.600',
              mt: 1,
            }}
          >
            {t('form_fields.geo_fence_type_hint')}
          </Typography>
        )}
        {watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT && (
          <AutocompleteFormField
            control={control}
            errors={errors}
            name={`equipment`}
            getValue={(item) => item._id}
            getLabel={(item) => item._name || ''}
            label={`${t('form_fields.available_equipment')}`}
            list={equipmentOptions}
            clearable={true}
            noOptionsText={t('form_fields.no_options')}
          />
        )}
        {(watchedGeofenceType?.id === GeoFenceTypes.CIRCLE ||
          watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT) && (
          <TextFormField
            control={control}
            errors={errors}
            name="radius"
            type="number"
            label={`${t('form_fields.radius')} (${t('units_of_distance.feet')})`}
          />
        )}
        <TextFormField
          control={control}
          errors={errors}
          label={`${t('form_fields.notes')}`}
          multiline={true}
          name="notes"
          rows={4}
          sx={{ gridColumn: '1 / -1', mt: 2 }}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap,
          gridTemplateColumns: '1fr auto auto',
          gridTemplateRows: 'auto 500px',
        }}
      >
        <AutocompleteAsyncFormField
          asyncCallback={getPlaces}
          clearable={true}
          control={control}
          debounceTime={500}
          errors={errors}
          getLabel={(item) => get(item, 'streetAddress', '')}
          getValue={(item) => get(item, 'placeId', '')}
          placeholder={`${t('common.address_autocomplete_hint')}`}
          label={`${t('form_fields.address')}`}
          name="address"
        />
        <TextFormField
          control={control}
          disabled={false}
          errors={errors}
          isRequired={true}
          label={`${t('form_fields.coordinates')}`}
          name="latLon"
          placeholder={`${t('form_fields.lat_lon_placeholder')}`}
          type="text"
        />
        <BasicTooltip title={t('administration.site.set_position_from_address')}>
          <Button
            color="inherit"
            size="small"
            onClick={refreshPosition}
            sx={{ p: 0, mt: '18px', height: 40 }}
          >
            <Refresh sx={{ width: 14, height: 14 }} />
          </Button>
        </BasicTooltip>
        <Box sx={{ gridColumn: '1 / -1' }}>
          <MapV2
            center={center}
            markers={locationMarkerAsArray}
            drawingMode={watchedGeofenceType?.id === GeoFenceTypes.POLYGON}
            drawingCoordinates={watchedDrawingCoordinates}
            emptyText={`${t('navigation.choose_address')}`}
            onPolygonEdit={(newCoordinates) => {
              setValue('drawingCoordinates', newCoordinates);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});
