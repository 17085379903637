import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DialogHeader } from '~components/Dialog/DialogHeader';
import { TextFormField } from '~components/FormFields/TextFormField';
import { useJob } from '~hooks/useJob';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

interface BulkEditJobNotesDialogProps {
  isOpen: boolean;
  jobIds: string[];
  onClose: () => void;
}

const notesSchema = yup.object().shape({
  notes: yup.string().trim().min(1).required(),
});

type NotesDTO = yup.InferType<typeof notesSchema>;

export default function BulkEditJobNotesDialog({
  isOpen,
  jobIds,
  onClose,
}: BulkEditJobNotesDialogProps) {
  const { bulkEditJobNotes, isUpdating } = useJob();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<NotesDTO>({
    resolver: yupResolver(notesSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { notes: '' },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      handleSubmit(async ({ notes }) => {
        await bulkEditJobNotes({ data: { notes }, job_ids: jobIds });
        onClose();
      })();
    },
    [jobIds, onClose],
  );

  useEffect(() => {
    if (isOpen) {
      setValue('notes', '');
    }
  }, [isOpen, setValue]);

  return (
    <Dialog
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <DialogHeader
          closeCallBack={onClose}
          title={
            <Typography component="span" variant="h5">
              {t('dispatch.order.bulk_update_job_notes_header')}
            </Typography>
          }
        />

        <DialogContent>
          <TextFormField
            control={control}
            errors={errors}
            isRequired
            multiline
            name="notes"
            placeholder={`${t('dispatch.order.add_note')}`}
            rows={5}
          />
        </DialogContent>

        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            color="primary"
            disabled={isUpdating}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            type="submit"
            variant="contained"
          >
            {t('actions.save')}
          </LoadingButton>

          <Button
            color="secondary"
            disabled={isUpdating}
            onClick={onClose}
            sx={{ mr: 2, px: 2 }}
            variant="outlined"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
