import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import WarningRounded from '@mui/icons-material/WarningRounded';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { OrderState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo, useRef } from 'react';
import React from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import {
  Column,
  FlexColumn,
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Order } from '~hooks/useOrders';
import { WayPoint } from '~hooks/useSites';
import { doneOrderStates } from '~pages/Dispatch/constants/orderStates';
import { GeofenceStartAdornment } from '~pages/Settings/Administration/Sites/components/GeofenceStartAdornment';
import { rootStore, useStores } from '~store';
import theme from '~theme/AppTheme';
import { isJobAssignable } from '~utils/jobs/job-utils';

import OrderStatusTransitioner from './OrderStatusTransitioner';

export enum OrdersDispatchColumnKey {
  CHECKBOX = 'checkbox',
  DISPATCH = 'dispatch',
  COMPANY = 'company',
  TRUCKS_AND_TYPES = 'trucksandtypes',
  FOR = 'for',
  START = 'start',
  STATUS = 'status',
  WAYPOINTS = 'waypoints',
  MATERIAL = 'material',
  CYCLE = 'cycle',
  ALERTS = 'alerts',
  ACTIONS = 'actions',
}

export const orderDispatchColumns: Record<
  OrdersDispatchColumnKey,
  { title: ReactNode; size: string; sx?: SxProps; condition?: () => boolean }
> = {
  [OrdersDispatchColumnKey.CHECKBOX]: {
    title: '',
    size: '20px',
    sx: { px: 0 },
  },
  [OrdersDispatchColumnKey.DISPATCH]: {
    title: t('dispatch.dispatch_v2.dispatch_ext_id'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.COMPANY]: {
    title: t('dispatch.dispatch_v2.company'),
    size: '2fr',
    condition: () => rootStore?.userStore?.userCompanies?.length > 1 || false,
  },
  [OrdersDispatchColumnKey.FOR]: {
    title: t('dispatch.dispatch_v2.for_and_customer'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.TRUCKS_AND_TYPES]: {
    title: t('dispatch.dispatch_v2.trucks_and_type'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.START]: {
    title: t('dispatch.dispatch_v2.start'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.CYCLE]: {
    title: t('dispatch.dispatch_v2.cycle_time'),
    size: '80px',
  },
  [OrdersDispatchColumnKey.STATUS]: {
    title: t('form_fields.status'),
    size: '105px',
    sx: { display: 'flex', gap: theme.spacing(0.5), px: 0 },
  },
  [OrdersDispatchColumnKey.WAYPOINTS]: {
    title: t('dispatch.dispatch_v2.pick_up_and_drop_off'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.MATERIAL]: {
    title: t('dispatch.dispatch_v2.material_delivered'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ALERTS]: {
    title: t('actions.alerts'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ACTIONS]: {
    title: '',
    size: '120px',
    sx: { display: 'flex', justifyContent: 'flex-end', px: 0 },
  },
} as const;

export const makeOrderDispatchCardTemplateColumns = (
  isSidebarCollapsed: boolean = false,
) => {
  return Object.entries(orderDispatchColumns)
    .filter(([, { condition }]) => !condition || condition())
    .map(([key, { size }]) => {
      if (isSidebarCollapsed && key === OrdersDispatchColumnKey.CHECKBOX) {
        return '0px';
      }

      return size;
    })
    .join(' ');
};

interface OrderDispatchColumnProps {
  order: Order;
}

interface CheckboxColumnProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckedStateChange?: () => void;
}

export function CheckboxColumn({
  isChecked,
  isDisabled,
  onCheckedStateChange,
}: CheckboxColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.CHECKBOX}>
      <Checkbox
        size="small"
        sx={{
          '&.MuiCheckbox-root': {
            color: theme.brandV2.colors.treadGray6,
            mt: '-10px',
            p: 0,
          },
          '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
          '&.Mui-disabled': { pointerEvents: 'auto' },
        }}
        disabled={Boolean(isDisabled)}
        checked={Boolean(isChecked)}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onCheckedStateChange?.();
        }}
      />
    </Column>
  );
}

export function DispatchColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.DISPATCH}>
      <FlexColumn>
        <OverflowAwareText fontWeight={700} minHeight="18px">
          {order.dispatchNumber}
        </OverflowAwareText>

        <OverflowAwareText>{order.orderId}</OverflowAwareText>

        {order.service?.name && (
          <OverflowAwareText>{order.service.name}</OverflowAwareText>
        )}
      </FlexColumn>
    </Column>
  );
}
export function CompanyColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.COMPANY}>
      <FlexColumn>
        <OverflowAwareText fontWeight={700} minHeight="18px">
          {order.company?.legalName}
        </OverflowAwareText>
      </FlexColumn>
    </Column>
  );
}

export function ForColumn({ order }: OrderDispatchColumnProps) {
  const fragments = order.sendingAccounts.length
    ? order.sendingAccounts.map((account) => account.name)
    : [order.account?.name ?? ''];
  const requester = order.requester?.fullName;

  return (
    <Column columnKey={OrdersDispatchColumnKey.FOR}>
      {Boolean(fragments.length) && (
        <FlexColumn>
          {fragments.map((fragment, index) => (
            <OverflowAwareText key={index}>{fragment}</OverflowAwareText>
          ))}

          {requester ? (
            <BasicTooltip
              title={t('dispatch.dispatch_v2.order_requested_by', { name: requester })}
            >
              <OverflowAwareText
                sx={{ ml: '-5px', display: 'flex', alignItems: 'center', gap: '3px' }}
              >
                <PersonOutlineOutlined
                  sx={{ color: theme.brandV2.colors.treadGray4, fontSize: '20px' }}
                />
                {requester}
              </OverflowAwareText>
            </BasicTooltip>
          ) : null}
        </FlexColumn>
      )}
    </Column>
  );
}

export const TrucksAndTypeColumn = ({ order }: OrderDispatchColumnProps) => {
  const trucksCount = order.jobsCount;
  const equipmentType = order.equipmentType?.name;
  return (
    <Column columnKey={OrdersDispatchColumnKey.TRUCKS_AND_TYPES}>
      <FlexColumn>
        <OverflowAwareText>
          {t('dispatch.dispatch_v2.trucks_count', { count: trucksCount })}
        </OverflowAwareText>
        <OverflowAwareText>{equipmentType}</OverflowAwareText>
      </FlexColumn>
    </Column>
  );
};

export function StartColumn({ order }: OrderDispatchColumnProps) {
  let startTime = null;
  let startDate = null;

  if (order.loadAt) {
    startTime = dayjs.tz(order.loadAt).format('hh:mm A');
    startDate = dayjs.tz(order.loadAt).format('DD-MMM-YYYY');
  }

  return (
    <Column columnKey={OrdersDispatchColumnKey.START}>
      {(startTime || startDate) && (
        <FlexColumn>
          {startTime && <OverflowAwareText>{startTime}</OverflowAwareText>}
          {startDate && <OverflowAwareText>{startDate}</OverflowAwareText>}
        </FlexColumn>
      )}
    </Column>
  );
}

interface StatusColumnProps extends OrderDispatchColumnProps {
  onAccept: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
}

type AvailableAction = {
  color: ButtonProps['color'];
  icon?: ReactNode;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

export const StatusColumn = observer(
  ({ onAccept, onOrderStateChange, onReject, order }: StatusColumnProps) => {
    const { ordersDispatchStore, userStore } = useStores();
    const { canDeleteOrder } = userStore.getPermissions();

    const orderCTAs = useMemo(() => {
      const isPendingRequest = order.state === OrderState.PENDING_REQUEST;

      if (isPendingRequest) {
        return [
          {
            color: 'brandV2Green',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.accept'),
            onClick: onAccept,
          } as AvailableAction,
          {
            color: 'brandV2Red',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.decline'),
            onClick: onReject,
          } as AvailableAction,
        ];
      }

      return null;
    }, [order.id, order.state, ordersDispatchStore.acceptingOrRejectingOrderId]);

    const transitionableStates: OrderState[] = useMemo(() => {
      const { editable, state } = order;
      const availableNextStates: OrderState[] = [];

      const isCancellable =
        editable && !doneOrderStates.includes(state) && canDeleteOrder;
      const isCompletable = isCancellable && state !== OrderState.CREATED;

      if (isCancellable) {
        availableNextStates.push(OrderState.CANCELED);
      }

      if (isCompletable) {
        availableNextStates.push(OrderState.COMPLETED);
      }

      return availableNextStates;
    }, [order.editable, order.state, canDeleteOrder]);

    const isTransitioningToStatus = useMemo(() => {
      if (ordersDispatchStore.isCancellingOrder) {
        return OrderState.CANCELED;
      }

      if (ordersDispatchStore.isCompletingOrder) {
        return OrderState.COMPLETED;
      }
    }, [ordersDispatchStore.isCancellingOrder, ordersDispatchStore.isCompletingOrder]);

    return (
      <Column columnKey={OrdersDispatchColumnKey.STATUS}>
        {orderCTAs ? (
          <>
            {orderCTAs.map((cta) => (
              <SmallButton
                key={cta.label}
                color={cta.color}
                disabled={cta.isDisabled}
                onClick={(event) => {
                  event.stopPropagation();
                  cta.onClick();
                }}
                startIcon={cta.icon}
                sx={{
                  '&.MuiButtonBase-root': {
                    height: '24px',
                    px: theme.spacing(0.5),
                    '&.Mui-disabled': { pointerEvents: 'auto' },
                    '.MuiSvgIcon-root': { fontSize: '16px' },
                  },
                }}
              >
                {cta.label}
              </SmallButton>
            ))}
          </>
        ) : (
          <Box>
            <OrderStatusTransitioner
              closeOnTransitionComplete
              isTransitioningToStatus={isTransitioningToStatus}
              onTransitionToStatus={onOrderStateChange}
              order={order}
              transitionableStates={transitionableStates}
            />
          </Box>
        )}
      </Column>
    );
  },
);

export const extractWaypointData = (waypoint?: WayPoint) => {
  if (!waypoint?.siteNested) {
    return null;
  }

  const name = waypoint.siteNested.name;
  const address = waypoint.siteNested.address?.thoroughfare;
  const geofenceType = waypoint.siteNested.nextBillionGeofence?.geofenceType;

  return { name, address, geofenceType };
};

export function WaypointsColumn({ order }: OrderDispatchColumnProps) {
  const pickUp = extractWaypointData(order.waypoints?.[0]);
  const dropOff = extractWaypointData(order.waypoints?.[1]);

  return (
    <Column columnKey={OrdersDispatchColumnKey.WAYPOINTS}>
      {pickUp && dropOff && (
        <FlexColumn>
          {[pickUp, dropOff].map((site, index) => (
            <OverflowAwareText key={index}>
              <Typography component="span" variant="subtitle2" display={'flex'}>
                <GeofenceStartAdornment
                  geofenceType={site.geofenceType}
                  isLoading={false}
                  sx={{ pr: 0.5 }}
                />
                {site.name}
              </Typography>

              {site.address && (
                <Typography
                  color={theme.brandV2.colors.treadGray2}
                  component="span"
                  fontWeight={400}
                  variant="subtitle2"
                >
                  {` ${site.address}`}
                </Typography>
              )}
            </OverflowAwareText>
          ))}
        </FlexColumn>
      )}
    </Column>
  );
}

export function MaterialColumn({ order }: OrderDispatchColumnProps) {
  const { numberFormatter } = useCompanyCurrency();

  const { material, deliveredQuantity, quantity, unitOfMeasure } = order;
  const delivered = `${numberFormatter(deliveredQuantity)} of ${numberFormatter(quantity)} ${unitOfMeasure?.name}`;

  return (
    <Column columnKey={OrdersDispatchColumnKey.MATERIAL}>
      {material?.name && (
        <FlexColumn>
          <OverflowAwareText>{material.name}</OverflowAwareText>
          <OverflowAwareText>{delivered}</OverflowAwareText>
        </FlexColumn>
      )}
    </Column>
  );
}

export function CycleColumn({ order }: OrderDispatchColumnProps) {
  const { loadCycleAvg } = order;

  const hours = Math.floor(
    dayjs.duration({ minutes: loadCycleAvg }).asHours(),
  ).toString();
  const minutes = dayjs
    .duration({ minutes: loadCycleAvg % 60 })
    .asMinutes()
    .toString();
  const formatted = `${hours}hr ${minutes}m`;

  return (
    <Column columnKey={OrdersDispatchColumnKey.CYCLE}>
      {Boolean(loadCycleAvg) && (
        <Box display="flex" justifyContent="center">
          <OverflowAwareText>{formatted}</OverflowAwareText>
        </Box>
      )}
    </Column>
  );
}

interface AlertsColumnProps extends OrderDispatchColumnProps {
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
}

export function AlertsColumn({
  nonRoutableAlertsFeatureFlagEnabled,
  order,
}: AlertsColumnProps) {
  let hasNonRoutableSites = false;

  if (nonRoutableAlertsFeatureFlagEnabled && order.waypoints) {
    hasNonRoutableSites = order.waypoints.some((waypoint) => {
      return !waypoint.siteNested?.routable;
    });
  }

  let alertMessage = '';

  if (hasNonRoutableSites) {
    alertMessage = t('common.address_confirmation_required');
  } else if (order.actionRequired) {
    alertMessage = t('dispatch.order.pending_jobs');
  }

  return (
    <Column columnKey={OrdersDispatchColumnKey.ALERTS}>
      {alertMessage ? (
        <Box display="flex" justifyContent="center">
          <BasicTooltip title={alertMessage}>
            <WarningRounded sx={{ color: theme.brandV2.colors.treadOrange }} />
          </BasicTooltip>
        </Box>
      ) : null}
    </Column>
  );
}

interface ActionsColumnProps extends OrderDispatchColumnProps {
  onBulkAssignMultiple?: () => void;
  onCancel: () => void;
  onCloneOrder: () => void;
  onEdit: () => void;
  onTextAllDrivers: () => void;
}

export const ActionsColumn = observer(
  ({
    onBulkAssignMultiple,
    onCancel,
    onCloneOrder,
    onEdit,
    onTextAllDrivers,
    order,
  }: ActionsColumnProps) => {
    const { userStore, ordersDispatchStore } = useStores();
    const { canEditOrder, canCreateOrder, canCreateJob, canDeleteOrder } =
      userStore.getPermissions();
    const menuHandler = useRef<MenuHandler>(null);

    const { editable, state } = order;

    const isEditable = editable && canEditOrder;
    const isCloneable = editable && canCreateOrder;
    const isDone = doneOrderStates.includes(state);

    const isCancellable = editable && !isDone && canDeleteOrder;
    const canAddNewJobs = editable && !isDone && canCreateJob;
    const canTextDrivers = editable && !isDone;

    const shouldShowBulkAssignMultipleButton = useMemo(() => {
      if (!onBulkAssignMultiple || isDone) {
        return false;
      }

      const orderJobs = ordersDispatchStore.orderJobs.get(order.id) ?? [];
      const orderHasAssignableJobs = orderJobs.some((job) => isJobAssignable(job));

      return orderHasAssignableJobs;
    }, [
      JSON.stringify(ordersDispatchStore.orderJobs.get(order.id)),
      isDone,
      onBulkAssignMultiple,
    ]);

    const availableActions = useMemo(() => {
      const actions = [];

      if (canTextDrivers) {
        actions.push({
          key: 'text_all_drivers',
          icon: <ChatOutlined />,
          label: `${t('dispatch.order.text_all_drivers')}`,
          onClick: onTextAllDrivers,
        });
      }

      if (isCloneable) {
        actions.push({
          key: 'clone_order',
          icon: <FileCopyOutlined />,
          label: `${t('dispatch.order.clone_order')}`,
          onClick: onCloneOrder,
        });
      }

      if (isCancellable) {
        actions.push({
          key: 'cancel_order',
          icon: <CancelOutlined />,
          label: t('dispatch.order.cancel_order'),
          onClick: onCancel,
        });
      }

      return actions;
    }, [isCloneable, canAddNewJobs, canTextDrivers, isCancellable]);

    const [[cancelAction], otherActions] = _.partition(availableActions, (action) => {
      return action.key === 'cancel_order';
    });

    return (
      <Column columnKey={OrdersDispatchColumnKey.ACTIONS}>
        {isEditable && (
          <BasicTooltip title={t('dispatch.order.edit_order')}>
            <Box
              sx={{
                display: 'inline-block',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              }}
            >
              <SmallButton
                color="brandV2Orange"
                variant="text"
                startIcon={<EditOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit();
                }}
                sx={{
                  '&.MuiButtonBase-root': {
                    border: 'solid 1px transparent',
                    borderRadius: '50%',
                    height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    p: 0,
                    width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                    '.MuiButton-startIcon': { mx: 0 },
                  },
                }}
              />
            </Box>
          </BasicTooltip>
        )}

        {shouldShowBulkAssignMultipleButton && (
          <BasicTooltip title={t('dispatch.order.bulk_assign_order')}>
            <Box
              sx={{
                display: 'inline-block',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              }}
            >
              <SmallButton
                color="brandV2Orange"
                variant="text"
                startIcon={<PeopleOutlineOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  onBulkAssignMultiple?.();
                }}
                sx={{
                  '&.MuiButtonBase-root': {
                    border: 'solid 1px transparent',
                    borderRadius: '50%',
                    height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    p: 0,
                    width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                    '.MuiButton-startIcon': { mx: 0 },
                  },
                }}
              />
            </Box>
          </BasicTooltip>
        )}

        {availableActions.length > 0 && (
          <Menu
            ref={menuHandler}
            menuTrigger={
              <SmallButton
                color="brandV2TreadGray3"
                variant="text"
                startIcon={<MoreHoriz />}
                sx={{
                  '&.MuiButtonBase-root': {
                    border: 'solid 1px transparent',
                    borderRadius: '50%',
                    height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    p: 0,
                    width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                    '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                    '.MuiButton-startIcon': { mx: 0 },
                  },
                }}
              />
            }
          >
            {otherActions.map((action) => (
              <MenuItem
                key={action.key}
                onClick={(event) => {
                  event.stopPropagation();
                  action.onClick();
                  menuHandler.current?.onClose?.();
                }}
              >
                <Box alignItems="center" display="flex" width="100%" gap={1}>
                  {React.cloneElement(action.icon, {
                    sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
                  })}

                  <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                    {action.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}

            {Boolean(otherActions.length && cancelAction) && <Divider sx={{ mx: 1 }} />}

            {Boolean(cancelAction) && (
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  cancelAction.onClick();
                  menuHandler.current?.onClose?.();
                }}
              >
                <Box alignItems="center" display="flex" width="100%" gap={1}>
                  {React.cloneElement(cancelAction.icon, {
                    sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
                  })}

                  <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                    {cancelAction.label}
                  </Typography>
                </Box>
              </MenuItem>
            )}
          </Menu>
        )}
      </Column>
    );
  },
);
