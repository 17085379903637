import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid, FixedSizeList, GridOnScrollProps } from 'react-window';

import {
  HEADER_PANEL_Z_INDEX,
  HeaderPanel,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { JobAssignmentType } from '~constants/enums';
import DriverSchedulerStore from '~src/store/DriverSchedulerStore';
import { useStores } from '~store/RootStore';
import { Nullable } from '~types/Nullable';
import { hexToRgba } from '~utils/utilFunctions';

import { AssigneeCard } from './AssigneeCard';
import {
  DUMMY_COLUMNS_SIZE,
  FULL_HOUR_WIDTH,
  INCREMENTS_PER_HOUR,
  JOB_COLUMN_WIDTH,
  STICKY_HEADER_HEIGHT,
} from './constants';
import { GRID_ITEM_WIDTH, HOURS, ROW_HEIGHT } from './constants';
import { DriverSearch } from './DriverSearch';
import { DroppableCell } from './DroppableCell';

const assigneeTypeTabs: TabProps<JobAssignmentType>[] = [
  {
    label: t('dispatch.job.assignment_popover.internal'),
    value: JobAssignmentType.INTERNAL_DRIVER,
  },
  {
    label: t('dispatch.job.assignment_popover.external'),
    value: JobAssignmentType.EXTERNAL_DRIVER,
  },
  {
    label: t('dispatch.job.assignment_popover.vendor'),
    value: JobAssignmentType.VENDOR,
  },
];

const getJobCountForAssignee = (assigneeId: string, store: DriverSchedulerStore) => {
  return Object.keys(store.jobAssignments).filter((key) =>
    key.startsWith(`${assigneeId}:`),
  ).length;
};

const StickyRow = ({ width }: { width: Nullable<string | number> }) => (
  <Box
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 2,
      background: 'white',
      display: 'flex',
      width: width,
    }}
  >
    {HOURS.map((hour, index) => (
      <HeaderPanel
        key={index}
        sx={{
          width: GRID_ITEM_WIDTH * INCREMENTS_PER_HOUR + 1,
          borderBottom: (theme) => `1px solid ${theme.brandV2.colors.treadGray7}`,
          borderRight: (theme) => `1px solid ${theme.brandV2.colors.treadGray7}`,
          textAlign: 'center',
          paddingTop: 1,
          height: STICKY_HEADER_HEIGHT,
        }}
      >
        <Typography variant="subtitle2">{hour}</Typography>
      </HeaderPanel>
    ))}
  </Box>
);

interface InnerElementProps extends BoxProps {
  children: ReactNode;
  style: CSSProperties;
}

const innerElementType = forwardRef<HTMLDivElement, InnerElementProps>(
  ({ children, ...rest }, ref) => (
    <Box ref={ref} {...rest}>
      <StickyRow width={rest.style.width} />
      {children}
    </Box>
  ),
);

innerElementType.displayName = 'innerElementType';

export const TimeSlotCellGrid = observer(() => {
  const { driverSchedulerStore } = useStores();
  const theme = useTheme();
  const driverColumnRef = useRef<FixedSizeList>(null);
  const hoursGridRef = useRef<FixedSizeGrid>(null);
  const driversColumnScrollTop = useRef(0);
  const hoursGridScrollTop = useRef(0);
  const fiveAmColumnIndex = FULL_HOUR_WIDTH * 5;

  const assignees = driverSchedulerStore.filteredAssignees;
  const jobAssignments = useMemo(
    () => JSON.stringify(driverSchedulerStore.jobAssignments),
    [driverSchedulerStore.jobAssignments],
  );

  const onDriverColumnScroll = useCallback(
    ({ scrollOffset }: { scrollOffset: number }) => {
      driversColumnScrollTop.current = scrollOffset;
      const shouldScrollHoursGrid =
        hoursGridRef.current && hoursGridScrollTop.current !== scrollOffset;

      if (shouldScrollHoursGrid) {
        hoursGridRef.current.scrollTo({
          scrollTop: scrollOffset,
        });
      }
    },
    [],
  );

  const onHoursGridScroll = useCallback(({ scrollTop }: GridOnScrollProps) => {
    hoursGridScrollTop.current = scrollTop;
    const shouldScrollDriversColumn =
      driverColumnRef.current && driversColumnScrollTop.current !== scrollTop;

    if (shouldScrollDriversColumn) {
      driverColumnRef.current.scrollTo(scrollTop);
    }
  }, []);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Box display="flex">
          {/* Sticky driver column */}
          <Box
            sx={{
              zIndex: HEADER_PANEL_Z_INDEX + 1,
              height: height,
            }}
          >
            <HeaderPanel
              sx={{
                position: 'sticky',
                backgroundColor: theme.brandV2.colors.treadGray9,
                width: `${JOB_COLUMN_WIDTH}px`,
                height: `${STICKY_HEADER_HEIGHT}px`,
                pl: 2,
                pr: 2,
                borderRight: `1px solid ${theme.brandV2.colors.treadGray7}`,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <OverflowAwareText fontWeight={600}>
                  {t('dispatch.drivers.driver_and_vendor')}
                </OverflowAwareText>

                <Tabs
                  onChange={(assigneeType) => {
                    driverSchedulerStore.setAssigneesFilters({ assigneeType });
                  }}
                  selected={driverSchedulerStore.assigneesFilters.assigneeType}
                  tabs={assigneeTypeTabs}
                  tabSx={{ '&.MuiTab-root': { minWidth: 'auto' } }}
                  sx={{ mt: -0.5 }}
                />

                <DriverSearch />
              </Box>
            </HeaderPanel>

            {/* Drivers list */}
            <Box bgcolor={hexToRgba(theme.brandV2.colors.treadGray8, 1)}>
              <FixedSizeList
                ref={driverColumnRef}
                itemCount={assignees.length}
                itemSize={ROW_HEIGHT}
                width={JOB_COLUMN_WIDTH}
                height={height - STICKY_HEADER_HEIGHT} // Adjusts for sticky header height and hours grid scrollbar
                onScroll={onDriverColumnScroll}
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                }}
              >
                {({ index, style }) => {
                  const assignee = assignees[index];
                  const jobCount = getJobCountForAssignee(
                    assignee.id,
                    driverSchedulerStore,
                  );

                  return (
                    <AssigneeCard
                      key={assignee.id}
                      assignee={assignee}
                      style={style}
                      jobCount={jobCount}
                    />
                  );
                }}
              </FixedSizeList>
            </Box>
          </Box>

          {/* Main grid with sticky header */}
          <Box
            sx={{
              height: height,
              position: 'relative',
              width: width + DUMMY_COLUMNS_SIZE,
            }}
          >
            <Box
              sx={{
                bottom: 0,
                left: DUMMY_COLUMNS_SIZE * -1,
                position: 'absolute',
                right: DUMMY_COLUMNS_SIZE * -1,
                top: 0,
              }}
            >
              <FixedSizeGrid
                key={jobAssignments}
                ref={hoursGridRef}
                columnCount={HOURS.length * INCREMENTS_PER_HOUR}
                columnWidth={GRID_ITEM_WIDTH}
                height={height}
                innerElementType={innerElementType}
                rowCount={assignees.length}
                rowHeight={ROW_HEIGHT}
                width={width - JOB_COLUMN_WIDTH + DUMMY_COLUMNS_SIZE}
                onScroll={onHoursGridScroll}
                initialScrollLeft={fiveAmColumnIndex}
              >
                {({ columnIndex, rowIndex, style }) => {
                  const adjustedStyle = {
                    ...style,
                    top: (Number(style.top) || 0) + STICKY_HEADER_HEIGHT, // Adjusting for sticky header height
                  };
                  const assigneeId = driverSchedulerStore.filteredAssignees[rowIndex]?.id;
                  const job =
                    driverSchedulerStore.jobAssignments[`${assigneeId}:${columnIndex}`] ??
                    null;
                  return (
                    <DroppableCell
                      assigneeId={assigneeId}
                      columnIndex={columnIndex}
                      jobId={job ? job.id : null}
                      style={adjustedStyle}
                    />
                  );
                }}
              </FixedSizeGrid>
            </Box>
          </Box>
        </Box>
      )}
    </AutoSizer>
  );
});
