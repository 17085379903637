import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AppliedFiltersChips, {
  AppliedFiltersChipsCollectedData,
} from '~components/Filters/AppliedFiltersChips';
import FilterMenuItem, {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { SelectedFilter } from '~constants/enums';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import useFetchers, {
  FetchDriversFunction,
  FetchFunction,
} from '../../../../components/Filters/useFetchers';
import useCalendarDispatchFilters, {
  calendarDispatchFilterKeyBySelectedFilter,
} from '../../hooks/useCalendarDispatchFilters';

type DriversRequestCursors = {
  sharedDriversCursor?: string;
  nonSharedDriversCursor?: string;
};

type TypeaheadedFilterState = {
  cursor?: string | DriversRequestCursors;
  loading: FilterMenuItemLoadingReason | false;
  nonSharedDriversCursor?: string;
  options: FilterMenuOption[];
  searchValue: string;
  sharedDriversCursor?: string;
};

type NextPageCursorsState = Partial<
  Record<SelectedFilter, TypeaheadedFilterState['cursor']>
>;

const CalendarFilters = observer(() => {
  const [isOpen, setiIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();

  const { userStore, calendarDispatchStore } = useStores();
  const companyId = getEffectiveUserCompanyId(userStore);

  const {
    appliedFilters,
    updateAppliedFilters,
    availableOptions,
    updateAvailableOptions,
    appliedFilterChipsCollectedData,
    updateAppliedFilterChipsCollectedData,
  } = useCalendarDispatchFilters();

  const isJobStatesFilterSelected = selectedFilter === SelectedFilter.JOB_STATES;

  const [nextPageCursors, setNextPageCursors] = useState<NextPageCursorsState>({});
  const fetchers = useFetchers(companyId);

  const fetchersBySelectedFilter: Partial<
    Record<SelectedFilter, FetchFunction | FetchDriversFunction>
  > = useMemo(
    () => ({
      [SelectedFilter.CUSTOMERS]: fetchers.fetchCustomers,
      [SelectedFilter.DISPATCH_NUMBERS]: fetchers.fetchOrdersDispatchNumbers,
      [SelectedFilter.DRIVERS]: fetchers.fetchDrivers,
      [SelectedFilter.DROP_OFF_SITES]: fetchers.fetchSites,
      [SelectedFilter.PICK_UP_SITES]: fetchers.fetchSites,
      [SelectedFilter.PROJECTS]: fetchers.fetchProjects,
      [SelectedFilter.PROJECTS_EXTERNAL_IDS]: fetchers.fetchProjectExternalIds,
      [SelectedFilter.REQUESTERS]: fetchers.fetchRequesters,
      [SelectedFilter.VENDORS]: fetchers.fetchVendors,
    }),
    [fetchers],
  );

  const filterChipsData = useMemo(() => {
    const appliedFilters = _.pick(
      calendarDispatchStore.filters,
      Object.values(calendarDispatchFilterKeyBySelectedFilter),
    );

    const collectedData = Object.entries(appliedFilterChipsCollectedData).reduce(
      (obj, [key, collectedValues]) => {
        const filter = calendarDispatchFilterKeyBySelectedFilter[key as SelectedFilter];

        if (filter) {
          obj[filter] = collectedValues;
        }

        return obj;
      },
      {} as AppliedFiltersChipsCollectedData,
    );

    return { appliedFilters, collectedData };
  }, [JSON.stringify(calendarDispatchStore.filters), appliedFilterChipsCollectedData]);

  const hasFiltersApplied =
    Object.values(filterChipsData.appliedFilters).filter((value) => {
      return Boolean(value);
    }).length > 0;

  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  const handleRemoveAllFilters = useCallback((filters: string | string[]) => {
    const filtersArray = Array.isArray(filters) ? filters : [filters];
    const filtersObject = Object.fromEntries(
      filtersArray.map((filter) => [filter, undefined]),
    );

    calendarDispatchStore.setFilters(filtersObject, true);
  }, []);

  const prepareForNewPageRequest = (key: SelectedFilter) => {
    const isDriversFilter = key === SelectedFilter.DRIVERS;
    const hasAnyDriversNextPageCursors =
      'sharedDriversCursor' in availableOptions.drivers ||
      'nonSharedDriversCursor' in availableOptions.drivers;
    const allDriversPagesFetched =
      _.isUndefined(availableOptions.drivers.sharedDriversCursor) &&
      _.isUndefined(availableOptions.drivers.nonSharedDriversCursor);

    // We know that all records has been fetched if the cursor is explictly "undefined"
    const hasNextPageCursor = isDriversFilter
      ? hasAnyDriversNextPageCursors
      : 'cursor' in availableOptions[key];

    if (!hasNextPageCursor) {
      return;
    }

    const allPagesFetched = isDriversFilter
      ? allDriversPagesFetched
      : _.isUndefined(availableOptions[key].cursor);

    if (allPagesFetched) {
      return;
    }

    updateAvailableOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: FilterMenuItemLoadingReason.INFINITE_SCROLL,
      },
    }));
  };

  const applySearchResponse = (key: SelectedFilter, data: FilterMenuOption[]) => {
    updateAvailableOptions((state) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        options: _.uniqBy([...state[key].options, ...data], (i) => i.value),
      },
    }));

    updateAppliedFilterChipsCollectedData(key, data);
  };

  const getFetchMoreCallback = useCallback(() => {
    if (!selectedFilter) {
      return undefined;
    }

    const isDriversFilter = selectedFilter === SelectedFilter.DRIVERS;

    if (!isDriversFilter) {
      const allPagesFetched =
        'cursor' in availableOptions[selectedFilter] &&
        _.isUndefined(availableOptions[selectedFilter].cursor);

      if (allPagesFetched) {
        return undefined;
      }

      return () => {
        updateAvailableOptions((state) => ({
          ...state,
          [selectedFilter]: {
            ...state[selectedFilter],
            cursor: nextPageCursors[selectedFilter],
          },
        }));
      };
    }

    const allPagesFetched =
      'sharedDriversCursor' in availableOptions.drivers &&
      _.isUndefined(availableOptions.drivers.sharedDriversCursor) &&
      'nonSharedDriversCursor' in availableOptions.drivers &&
      _.isUndefined(availableOptions.drivers.nonSharedDriversCursor);

    if (allPagesFetched) {
      return undefined;
    }

    return () => {
      updateAvailableOptions((state) => ({
        ...state,
        drivers: {
          ...state.drivers,
          sharedDriversCursor: (nextPageCursors.drivers as DriversRequestCursors)
            .sharedDriversCursor,
          nonSharedDriversCursor: (nextPageCursors.drivers as DriversRequestCursors)
            .nonSharedDriversCursor,
        },
      }));
    };
  }, [selectedFilter, availableOptions]);

  const fetch = (filter: SelectedFilter) => {
    const fetcher = fetchersBySelectedFilter[filter];

    if (fetcher && availableOptions[filter].loading) {
      if (filter === SelectedFilter.DRIVERS) {
        const { searchValue, sharedDriversCursor, nonSharedDriversCursor } =
          availableOptions[filter];

        (fetcher as FetchDriversFunction)(
          searchValue,
          sharedDriversCursor,
          nonSharedDriversCursor,
        ).then((response) => {
          setNextPageCursors((state) => ({
            ...state,
            [filter]: {
              sharedDriversCursor: response.nextSharedDriversPageCursor,
              nonSharedDriversCursor: response.nextNonSharedDriversPageCursor,
            },
          }));
          applySearchResponse(filter, response.data);
        });
      } else {
        const { searchValue, cursor } = availableOptions[filter];

        (fetcher as FetchFunction)(searchValue, cursor as string).then((response) => {
          setNextPageCursors((state) => ({
            ...state,
            [filter]: response.nextPageCursor,
          }));
          applySearchResponse(filter, response.data);
        });
      }
    }
  };

  useEffect(() => {
    fetch(SelectedFilter.CUSTOMERS);
  }, [availableOptions.customers.loading]);

  useEffect(() => {
    fetch(SelectedFilter.DISPATCH_NUMBERS);
  }, [availableOptions.dispatchNumbers.loading]);

  useEffect(() => {
    fetch(SelectedFilter.DROP_OFF_SITES);
  }, [availableOptions.dropOffSites.loading]);

  useEffect(() => {
    fetch(SelectedFilter.DRIVERS);
  }, [availableOptions.drivers.loading]);

  useEffect(() => {
    fetch(SelectedFilter.PICK_UP_SITES);
  }, [availableOptions.pickUpSites.loading]);

  useEffect(() => {
    fetch(SelectedFilter.PROJECTS);
  }, [availableOptions.projects.loading]);

  useEffect(() => {
    fetch(SelectedFilter.PROJECTS_EXTERNAL_IDS);
  }, [availableOptions.projectsExternalIds.loading]);

  useEffect(() => {
    fetch(SelectedFilter.REQUESTERS);
  }, [availableOptions.requesters.loading]);

  useEffect(() => {
    fetch(SelectedFilter.VENDORS);
  }, [availableOptions.vendors.loading]);

  useEffect(() => {
    if (selectedFilter) {
      prepareForNewPageRequest(selectedFilter);
    }
  }, [
    selectedFilter,
    availableOptions.customers.cursor,
    availableOptions.dispatchNumbers.cursor,
    availableOptions.drivers.nonSharedDriversCursor,
    availableOptions.drivers.sharedDriversCursor,
    availableOptions.dropOffSites.cursor,
    availableOptions.pickUpSites.cursor,
    availableOptions.projects.cursor,
    availableOptions.projectsExternalIds.cursor,
    availableOptions.requesters.cursor,
    availableOptions.vendors.cursor,
  ]);

  useEffect(() => {
    if (selectedFilter && !isJobStatesFilterSelected) {
      updateAvailableOptions((state) => ({
        ...state,
        [selectedFilter]: {
          loading: false,
          options: [],
          searchValue: state[selectedFilter].searchValue,
        },
      }));

      applyDebouncedFilter(() => {
        updateAvailableOptions((state) => ({
          ...state,
          [selectedFilter]: {
            loading: FilterMenuItemLoadingReason.SEARCH_VALUE,
            options: [],
            searchValue: appliedFilters[selectedFilter].search,
          },
        }));
      });
    }

    return () => {
      applyDebouncedFilter.cancel();
    };
  }, [
    selectedFilter,
    isJobStatesFilterSelected,
    appliedFilters.customers.search,
    appliedFilters.dispatchNumbers.search,
    appliedFilters.drivers.search,
    appliedFilters.dropOffSites.search,
    appliedFilters.pickUpSites.search,
    appliedFilters.projects.search,
    appliedFilters.projectsExternalIds.search,
    appliedFilters.requesters.search,
    appliedFilters.vendors.search,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
    }
  }, [isOpen]);

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setiIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            startIcon={<FilterList />}
            sx={{
              '&.MuiButton-root': {
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                ...(hasFiltersApplied
                  ? {
                      borderBottomLeftRadius: theme.brandV2.borderRadius,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: theme.brandV2.borderRadius,
                      borderTopRightRadius: 0,
                    }
                  : { borderRadius: theme.brandV2.borderRadius }),
              },
            }}
          >
            {t('common.filters')}
          </SmallButton>
        }
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={appliedFilters[selectedFilter].search}
            onSearchValueChange={
              isJobStatesFilterSelected
                ? undefined
                : (value) => {
                    updateAppliedFilters((state) => ({
                      ...state,
                      [selectedFilter]: {
                        ...state[selectedFilter],
                        search: value,
                      },
                    }));
                  }
            }
            loadingReason={availableOptions[selectedFilter].loading || undefined}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.${_.snakeCase(selectedFilter)}`) })}`}
            options={availableOptions[selectedFilter].options}
            selectedOptions={
              calendarDispatchStore.filters[
                calendarDispatchFilterKeyBySelectedFilter[selectedFilter]
              ] ?? []
            }
            onSelectedOptionsChange={(selectedOptions) => {
              calendarDispatchStore.setFilters(
                {
                  [calendarDispatchFilterKeyBySelectedFilter[selectedFilter]]:
                    selectedOptions.length > 0 ? selectedOptions : undefined,
                },
                true,
              );
            }}
            onFetchMore={getFetchMoreCallback()}
          />
        )}

        {!selectedFilter &&
          Object.values(SelectedFilter).map((filter) => (
            <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  color={theme.brandV2.colors.treadBlack}
                  fontSize="12px"
                  fontWeight={600}
                >
                  {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {(
                    calendarDispatchStore.filters[
                      calendarDispatchFilterKeyBySelectedFilter[filter]
                    ] ?? []
                  ).length > 0 && (
                    <Check
                      sx={{ fontSize: '16px', color: theme.brandV2.colors.treadOrange }}
                    />
                  )}

                  <ChevronRight sx={{ fontSize: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>

      <AppliedFiltersChips
        {...filterChipsData}
        onRemoveFilter={handleRemoveAllFilters}
        onRemoveAllFilters={handleRemoveAllFilters}
      />
    </Box>
  );
});

export default CalendarFilters;
