import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/system';
import { t } from 'i18next';

import { JobLoadCycle, JobLoadSummary } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import LoadCard from '../Cards/LoadCard';

const LOADS_TABLE_LAYOUT: SxProps = {
  alignItems: 'flex-start',
  display: 'grid',
  gap: 0.5,
  gridTemplateColumns: '23px 70px 64px 1fr repeat(3, 1fr) 82px 16px',
};

const getCycleTime = (cycles: JobLoadCycle[], load: JobLoadSummary) => {
  const cycle = cycles.find((cycle) => cycle.fromLoadId === load.id);

  return cycle?.timeMinutes ?? 0;
};

interface LoadsTableProps {
  cycles: JobLoadCycle[];
  jobTickets: Ticket[];
  loads: JobLoadSummary[];
  onLoadCardClick: (loadId: string, ticket?: Ticket) => void;
  onRemoveLoadClick: (loadId: string) => void;
  reload: () => void;
  sx?: SxProps;
  isEditing?: boolean;
}

export default function LoadsTable({
  cycles,
  jobTickets,
  loads,
  onLoadCardClick,
  onRemoveLoadClick,
  reload,
  sx,
  isEditing = false,
}: LoadsTableProps) {
  const { userStore } = useStores();
  const userPermissions = userStore.getPermissions();

  return (
    <Box sx={sx}>
      <Box
        sx={{
          ...LOADS_TABLE_LAYOUT,
          borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
          pb: 1,
        }}
      >
        <TableHeader sx={{ justifySelf: 'center' }}>{t('loads.number')}</TableHeader>
        <TableHeader>{t('loads.status_and_cycle')}</TableHeader>
        <TableHeader sx={{ justifySelf: 'center' }}>{t('loads.alerts')}</TableHeader>
        <TableHeader>{t('loads.ticket')}</TableHeader>
        <TableHeader>{t('loads.ticket_time')}</TableHeader>
        <TableHeader>{t('loads.pickup_entry')}</TableHeader>
        <TableHeader>{t('loads.dropoff_entry')}</TableHeader>
        <TableHeader />
        <TableHeader />
      </Box>

      {loads.map((load, index) => {
        const currentTicket = jobTickets.find(({ loadId }) => loadId === load.id);
        const cycleTime = getCycleTime(cycles, load);

        return (
          <LoadCard
            key={load.id}
            cycleTime={cycleTime}
            index={index}
            isDeletable={userPermissions.canDeleteLoad}
            isLast={index === loads.length - 1}
            load={load}
            onClick={() => onLoadCardClick(load.id, currentTicket)}
            onRemoveLoad={() => onRemoveLoadClick(load.id)}
            reload={reload}
            ticket={currentTicket}
            sx={LOADS_TABLE_LAYOUT}
            isEditing={isEditing}
          />
        );
      })}
    </Box>
  );
}

const TableHeader = styled(Typography)<TypographyProps>(() => ({
  color: theme.brandV2.colors.treadBlack,
  fontSize: '12px',
  fontWeight: 600,
}));
