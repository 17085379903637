import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Mail from '@mui/icons-material/Mail';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Sms from '@mui/icons-material/Sms';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  AuthMethod,
  CompanyShareableType,
  ModelError_Item,
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import {
  UserForm,
  UserFormHandler,
  UserFormStateChangeProps,
  UserValidationDTO,
} from '~components/UserForm/UserForm';
import UserNotificationsPreferenceForm, {
  UserNotificationsPreferenceDTO,
  UserNotificationsPreferenceHandler,
} from '~components/UserForm/UserNotificationsPreferenceForm';
import { UserTypesFilter } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useCompany } from '~hooks/useCompany';
import { CompanyShare } from '~hooks/useCompanyShares';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Driver, useDrivers } from '~hooks/useDrivers';
import { User, useUserRoles, useUsers } from '~hooks/useUsers';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat } from '~utils/dateTime';
import { getConsolidatedEquipmentName } from '~utils/drivers/driver-utils';
import { isActionClicked } from '~utils/utilFunctions';

interface InvitesToSendState {
  email: { isDisabled: boolean; shouldSend: boolean };
  sms: { isDisabled: boolean; shouldSend: boolean };
}

interface DialogState {
  isOpen: boolean;
  availableAuthMethods: AuthMethod[];
}

export const UserManagementDataGrid = observer(() => {
  const theme = useTheme();
  const { treatments, isReady } = useSplitTreatments({
    names: [FeatureFlags.simplifiedSettingsTabs],
  });
  const simplifiedSettingsTabsFeatureFlag =
    treatments[FeatureFlags.simplifiedSettingsTabs];
  const location = useLocation();
  const isDriversPage = location.pathname.includes('drivers');

  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    availableAuthMethods: [],
  });

  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [selectedDriver, setSelectedDriver] = useState<Driver | undefined>();
  const [userFormIsDirty, setUserFormIsDirty] = useState(false);
  const [
    userNotificationsPreferenceFormIsDirty,
    setUserNotificationsPreferenceFormIsDirty,
  ] = useState(false);

  const formIsDirty = userFormIsDirty || userNotificationsPreferenceFormIsDirty;

  const [invitesToSend, setInvitesToSend] = useState<InvitesToSendState>();
  const [companyShares, setCompanyShares] = useState<CompanyShare[]>([]);
  const { userStore, toasterStore, companyAssetsStore } = useStores();
  const {
    getCompanyUsers,
    getUserById,
    deleteUser,
    updateUser,
    createUser,
    sendInvitation,
    isLoadingAllUsers,
    isUpdating,
  } = useUsers();
  const { getUserRoles } = useUserRoles();
  const { getCompanyById } = useCompany();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const {
    getDriverById,
    getDriversByCompanyId,
    isLoading: isLoadingDrivers,
  } = useDrivers();
  const {
    getCompanySharesByCompanyId,
    createCompanyShareByCompanyId,
    deleteCompanyShare,
  } = useCompanyShares();
  const isLoading = isLoadingAllUsers || isLoadingDrivers;
  const [userTypeFilter, setUserTypeFilter] = useState<UserTypesFilter>(
    UserTypesFilter.USERS,
  );
  const isShowingUsers = userTypeFilter === UserTypesFilter.USERS;
  const isExternalDriver = userTypeFilter === UserTypesFilter.EXTERNAL_DRIVERS;
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  useEffect(() => {
    getUserRoles();
  }, []);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const loadingDriversRef = useRef(isLoadingDrivers);
  useEffect(() => {
    loadingDriversRef.current = isLoadingDrivers;
  }, [isLoadingDrivers]);

  useEffect(() => {
    if (selectedId) {
      getCompanySharesByCompanyId({
        companyId,
        company_shareable_type: 'User',
        company_shareable_id: selectedId,
        sender_company_id: companyId,
        callBack: setCompanyShares,
      });
    }
  }, [companyId, selectedId]);

  const userRolesOptions = userStore.userRoles;
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };

  const onChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as 'email' | 'sms';
    const checked = event.target.checked;

    setInvitesToSend((state) => {
      const newState = _.cloneDeep(state);

      if (newState) {
        newState[name] = {
          ...newState[name],
          shouldSend: checked,
        };
      }

      return newState;
    });
  }, []);

  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const updateUserDialogRef = useRef<UserFormHandler>(null);
  const userNotificationsPreferenceRef = useRef<UserNotificationsPreferenceHandler>(null);

  const handleClose = useCallback(() => {
    setDialogState((state) => ({ ...state, isOpen: false }));
    setSelectedId(null);
    setSelectedUser(undefined);
    setSelectedDriver(undefined);
    setCompanyShares([]);
    setAsyncErrors([]);
  }, []);

  const editRow = async (id: string) => {
    setSelectedId(id);
    let fetchedUser: User | Driver | undefined;

    if (isExternalDriver || isDriversPage) {
      fetchedUser = await getDriverById(id);
      setSelectedDriver(fetchedUser as Driver);
      setDialogState({ isOpen: true, availableAuthMethods: [] });
    } else {
      fetchedUser = await getUserById(id);
      setSelectedUser(fetchedUser as User);

      if (fetchedUser?.company?.id) {
        await getCompanyById({
          id: String(fetchedUser?.company?.id),
          callBack: (company) => {
            setDialogState({ isOpen: true, availableAuthMethods: company.authMethods });

            if ((fetchedUser as User)?.isActiveMember) {
              setInvitesToSend(undefined);
            } else {
              setInvitesToSend({
                email: { isDisabled: true, shouldSend: false },
                sms: { isDisabled: true, shouldSend: false },
              });
            }
          },
        });
      }
    }
  };

  const createNew = async () => {
    setSelectedId(null);

    await getCompanyById({
      id: String(companyId),
      callBack: (company) => {
        setDialogState({ isOpen: true, availableAuthMethods: company.authMethods });
        setInvitesToSend({
          email: { isDisabled: true, shouldSend: false },
          sms: { isDisabled: true, shouldSend: false },
        });
      },
    });
  };

  const deleteUserCallback = () => {
    if (selectedId) {
      deleteUser(selectedId, () => {
        modalDialogRef.current?.close();
        toasterStore.push(alert($t('user.user_deleted'), AlertTypes.success));
      });
    }
  };

  const resendInvite = (rowId: string, method: 'email' | 'sms') => {
    if (rowId) {
      sendInvitation(rowId, method).then(() => {
        toasterStore.push(alert($t('user.invitation_sent'), AlertTypes.success));
      });
    }
  };

  const onSuccess = (user?: User) => {
    handleClose();
    if (!isShowingUsers) {
      getDriversByType();
    }
    const name = `${user?.firstName} ${user?.lastName}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('user.user_updated', { name })
          : $t('user.user_created', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSubmitCallback = async (
    data: UserValidationDTO,
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !invitesToSend?.email.isDisabled && invitesToSend?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !invitesToSend?.sms.isDisabled && invitesToSend?.sms.shouldSend,
    );

    if (selectedId) {
      try {
        if (!selectedDriver) {
          await updateUser(
            { id: selectedId, ...data } as User,
            userNotificationsPreferenceData,
          );
        }
        const companySharesPresentOnModalOpen = companyShares;
        const companySharesPresentOnSubmit = data.companyShares;

        // Generates a list of company shares created this session that we need to create
        const companySharesToCreate = companySharesPresentOnSubmit?.filter(
          (submittedCompanyShare) =>
            !companySharesPresentOnModalOpen.some(
              (existingCompanyShare) =>
                existingCompanyShare.receiverCompany.id === submittedCompanyShare.id,
            ),
        );

        // Generates a list of company shares that were removed this session that we need to delete
        const companySharesToDelete = companySharesPresentOnModalOpen.filter(
          (existingCompanyShare) =>
            companySharesPresentOnSubmit &&
            !companySharesPresentOnSubmit.some(
              (submittedCompanyShare) =>
                existingCompanyShare.receiverCompany.id === submittedCompanyShare.id,
            ),
        );

        if (companySharesToCreate && companySharesToCreate.length) {
          companySharesToCreate.forEach((companyShare) => {
            createCompanyShareByCompanyId({
              companyId,
              company_shareable_id: selectedId,
              receiver_company_id: companyShare.id as string,
              company_shareable_type: 'User' as CompanyShareableType,
            });
          });
        }

        if (companySharesToDelete && companySharesToDelete.length) {
          companySharesToDelete.forEach((companyShare) => {
            deleteCompanyShare(companyShare.id as string);
          });
        }
        if (shouldSendEmailInvite) {
          await sendInvitation(selectedId, 'email');
        }

        if (shouldSendSmsInvite) {
          await sendInvitation(selectedId, 'sms');
        }

        onSuccess({ id: selectedId, ...data } as User);
      } catch (error) {
        const e = error as AxiosError<ModelError_Response>;

        setAsyncErrors(e.response?.data.error.errors || []);
        throw new Error('Failed to update user');
      }
    } else {
      try {
        const newUser = await createUser(data as User, {
          email: shouldSendEmailInvite,
          sms: shouldSendSmsInvite,
        });
        const submittedCompanyShares = data.companyShares;

        if (newUser && submittedCompanyShares && submittedCompanyShares.length) {
          submittedCompanyShares.forEach((companyShare) => {
            createCompanyShareByCompanyId({
              companyId,
              receiver_company_id: companyShare.id as string,
              company_shareable_id: newUser.id as string,
              company_shareable_type: 'User' as CompanyShareableType,
            });
          });
        }
        onSuccess(data as User);
      } catch (error) {
        const e = error as AxiosError<ModelError_Response>;

        setAsyncErrors(e.response?.data.error.errors || []);
        throw new Error('Failed to create user');
      }
    }
  };

  const onSubmitForm = async () => {
    const userData = await new Promise<UserValidationDTO | undefined>((resolve) => {
      updateUserDialogRef.current?.submit((data) => resolve(data));
    });

    const userNotificationsPreferenceData = await new Promise<
      UserNotificationsPreferenceDTO | undefined
    >((resolve) => {
      if (selectedUser?.id && userNotificationsPreferenceRef.current) {
        userNotificationsPreferenceRef.current?.onSubmit?.((data) => resolve(data));
      } else {
        resolve(undefined);
      }
    });

    if (userData) {
      onSubmitCallback(userData, userNotificationsPreferenceData);
    }
  };

  const onFormStateChange = ({
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => {
    setUserFormIsDirty(isDirty);

    if (invitesToSend) {
      setInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }
  };

  const onUserNotificationsPreferenceFormStateChange = useCallback(
    ({ isDirty }: FormStateChangeProps) => {
      setUserNotificationsPreferenceFormIsDirty(isDirty);
    },
    [],
  );

  const rows = useMemo(() => {
    if (isDriversPage) {
      return companyAssetsStore.drivers;
    }

    return isShowingUsers ? userStore.users : companyAssetsStore.drivers;
  }, [
    userStore.users,
    companyAssetsStore.drivers,
    userTypeFilter,
    isDriversPage,
    isShowingUsers,
  ]);

  const totalUserCount = useMemo(() => userStore.users.length, [userStore.users]);
  const totalDriverCount = useMemo(
    () => companyAssetsStore.drivers.length,
    [companyAssetsStore.drivers],
  );

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const isViewingChildCompanyExternalDrivers =
    (userTypeFilter === UserTypesFilter.EXTERNAL_DRIVERS || isDriversPage) &&
    !userStore.isCurrentCompanyActive;

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'lastName',
          headerName: $t('form_fields.last_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'firstName',
          headerName: $t('form_fields.first_name'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const user = params.row as User;
            return `${user.firstName}`;
          },
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return (params.row as User).company?.legalName || '';
          },
        },
        {
          field: 'roles',
          headerName: isShowingUsers
            ? $t('form_fields.role')
            : $t('form_fields.equipment'),
          flex: 1,
          height: 'auto',
          valueGetter: (params: GridValueGetterParams) => {
            if (isShowingUsers) {
              const user = params.row as User;

              return user.userRoles?.map((role) => role.name).join(', ');
            }

            const driver = params.row as Driver;

            return getConsolidatedEquipmentName(driver);
          },
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'phone',
          headerName: $t('form_fields.phone'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          flex: 1,
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography variant="body2">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'another.action.navigation',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => {
            return isShowingUsers ? (
              <HeaderNavigation
                count={totalUserCount}
                loading={loadingRef.current}
                pagination={userStore.userManagementPagination}
                callback={getUsersByCompany}
                altText={`${$t('actions.actions')}`}
                searchQuery={searchValue}
              />
            ) : (
              <HeaderNavigation
                count={totalDriverCount}
                loading={loadingDriversRef.current}
                pagination={companyAssetsStore.driversPagination}
                callback={getDriversByType}
                altText={`${$t('actions.actions')}`}
                searchQuery={searchValue}
              />
            );
          },
          renderCell: (params: GridValueGetterParams) => {
            const options = [];

            if (
              !isExternalDriver &&
              !isDriversPage &&
              !params.row.isActiveMember &&
              params.row.email
            ) {
              options.push({
                title: t('user.resend_email_invitation'),
                icon: <Mail />,
                callBack: () => resendInvite(params.row.id, 'email'),
              });
            }

            if (
              !isExternalDriver &&
              !isDriversPage &&
              !params.row.isActiveUser &&
              params.row.phone
            ) {
              options.push({
                title: t('user.resend_sms_invitation'),
                icon: <Sms />,
                callBack: () => resendInvite(params.row.id, 'sms'),
              });
            }

            return (
              <>
                {userPermissions.canEditUser && !isViewingChildCompanyExternalDrivers && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions, isShowingUsers],
  );

  const initialState = useMemo(
    () => ({
      columns: {},
    }),
    [],
  );

  const userTypeFilters = useMemo(() => {
    const filters = [
      {
        value: UserTypesFilter.USERS,
        label: 'user.users',
      },
      {
        value: UserTypesFilter.INTERNAL_DRIVERS,
        label: 'user.internal_drivers',
      },
    ];

    if (
      !isDriversPage &&
      isReady &&
      simplifiedSettingsTabsFeatureFlag.treatment !== 'on'
    ) {
      filters.push({
        value: UserTypesFilter.EXTERNAL_DRIVERS,
        label: 'user.external_drivers',
      });
    }

    return filters;
  }, [isReady, simplifiedSettingsTabsFeatureFlag.treatment, isDriversPage]);

  const getDriversByType = (link?: PaginationLink) => {
    getDriversByCompanyId({
      companyId,
      link,
      shared: isExternalDriver,
      searchQuery: searchValue,
    });
  };

  const getUsersByCompany = (link?: PaginationLink) => {
    getCompanyUsers({
      companyId,
      limit: userStore.userManagementPagination.limit,
      link,
    });
  };

  useEffect(() => {
    if (isDriversPage) {
      getDriversByCompanyId({
        companyId,
        shared: true,
      });
    } else if (isShowingUsers) {
      getCompanyUsers({
        companyId,
      });
    } else {
      getDriversByType();
    }
  }, [isShowingUsers, searchValue, userTypeFilter, companyId, isDriversPage]);

  return (
    <>
      <Box data-test-id={'users-data-grid'} width={'100%'}>
        {rows && columns && (
          <Box sx={{ mr: -3 }}>
            {!isDriversPage ? (
              <Box sx={{ mb: 2 }}>
                <StatusTabFilters
                  value={userTypeFilter}
                  onChange={(tab) => setUserTypeFilter(tab)}
                  filters={userTypeFilters}
                />
              </Box>
            ) : (
              <PageTitle text={`${t('administration.drivers.title')}`} sx={{ mb: 2 }} />
            )}
            <DataGrid
              id="users-grid"
              sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
              columns={columns}
              rows={rows as unknown as Record<string, User | Driver>[]}
              loading={isLoading}
              initialState={initialState}
              onChangeFilter={onChangeQuickFilter}
              headerActionsComponent={
                <>
                  {userPermissions.canCreateUser && (
                    <Box display={'flex'}>
                      <Button
                        size="medium"
                        color="primary"
                        onClick={isDriversPage ? undefined : createNew} // TODO: Wire up the new driver form as part of TRE-6002
                        startIcon={<Add />}
                        disabled={!companyId}
                      >
                        {t(isDriversPage ? 'user.create_driver' : 'user.create_user')}
                      </Button>
                    </Box>
                  )}
                </>
              }
            />
          </Box>
        )}
      </Box>

      <Dialog
        open={dialogState.isOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`user.${selectedId ? 'update_user' : 'create_user'}`)}
        />
        <DialogContent sx={{ my: 4 }}>
          <UserForm
            ref={updateUserDialogRef}
            editable={true}
            defaultUser={selectedId ? selectedUser : null}
            defaultDriver={selectedDriver}
            onFormStateChange={onFormStateChange}
            userRolesOptions={userRolesOptions}
            availableAuthMethods={dialogState.availableAuthMethods}
            allowSharing={true}
            companyShares={companyShares}
            errors={asyncErrors}
          />

          {selectedUser?.id && (
            <UserNotificationsPreferenceForm
              key={selectedUser?.id}
              ref={userNotificationsPreferenceRef}
              editable
              onFormStateChange={onUserNotificationsPreferenceFormStateChange}
              user={selectedUser}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {!selectedDriver && invitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeInvite}
                      checked={invitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeInvite}
                      checked={invitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!formIsDirty || isUpdating}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.${selectedId ? 'update' : 'create'}`)}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        title={$t('user.delete_user_title')}
        content={$t('user.delete_user_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteUserCallback}
        loading={isUpdating}
      />
    </>
  );
});
