import { t } from 'i18next';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  nameIdTypeSchema,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
} from '~constants/regexConst';
import { CompanyBasic } from '~hooks/useCompany';
import { Order } from '~hooks/useOrders';
import { RateBasicWithValue } from '~hooks/useRates';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';

import { fileAttachmentSchema } from '../../../formsShared/components/FileAttachments';

export const orderFormSchema = yup.object().shape({
  id: yup.string(),
  company: yup.object().when('id', {
    // @ts-ignore
    is: '',
    then: () => {
      yup.object().shape({
        legalName: yup.string().required(),
        id: yup.string().required(),
      });
    },
    otherwise: () => {
      yup.object().notRequired();
    },
  }),
  loadAt: yup.string().required(),
  orderId: yup.string(),
  notes: notesSchema,
  truckCount: yup.string(),
  truckDelay: yup.string(),
  unitOfMeasure: nameIdSchemaRequired,
  account: nameIdSchemaRequired,
  project: nameIdNonRequiredSchema.nullable(),
  material: yup.object().required(),
  equipmentType: yup.object().notRequired(),
  service: nameIdTypeSchema.required(),
  quantity: yup
    .number()
    .required()
    .min(
      0,
      `${t('form_validation_errors.min', {
        field: t('form_fields.quantity'),
        count: 0,
      })}`,
    )
    .typeError(
      `${t('form_validation_errors.should_be_number', {
        field: t('form_fields.quantity'),
      })}`,
    ),
  state: yup.string(),
  pickUpWayPoint: waypointSchema,
  dropOffWayPoint: waypointSchema,
  phase: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    })
    .notRequired(),
  phaseName: yup.string().notRequired(),
  customerRate: yup
    .object()
    .shape({
      id: yup.string().notRequired(),
      name: yup.string().notRequired(),
      type: yup.string().notRequired(),
      rate: yup.string().notRequired(),
    })
    .notRequired(),
  customerRateType: yup.string().nullable(),
  customerRateValue: yup.string().nullable(),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  department: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .notRequired(),
  haulerRateType: yup.lazy((value) => {
    if (typeof value === 'object') {
      return yup.object().shape({ value: yup.string() }).notRequired();
    } else {
      return yup.string().notRequired();
    }
  }),
  fileAttachments: fileAttachmentSchema,
});
export const setDefaultOrderValues = (defaultOrder: Order, company?: CompanyBasic) => {
  const pickUpWayPoint = defaultOrder?.pickUpWayPoints?.[0];
  const dropOffWayPoint = defaultOrder?.dropOffWayPoints?.[0];
  return {
    id: defaultOrder?.id || '',
    company: defaultOrder?.company ? company : null,
    loadAt: defaultOrder?.loadAt || '',
    notes: defaultOrder?.notes || '',
    state: defaultOrder?.state || '',
    truckCount: defaultOrder?.orderId ? defaultOrder?.jobsCount : '',
    truckDelay: defaultOrder?.truckDelay || '',
    loadsPerTruck: defaultOrder?.loadsPerTruck || '',
    unitOfMeasure: {
      name: defaultOrder?.unitOfMeasure?.name || '',
      id: defaultOrder?.unitOfMeasure?.id || '',
    },
    account: {
      name: defaultOrder?.account?.name || '',
      id: defaultOrder?.account?.id || '',
    },
    orderId: defaultOrder?.orderId || '',
    project: {
      name: defaultOrder?.project?.name || '',
      id: defaultOrder?.project?.id || '',
      externalId: defaultOrder?.project?.externalId || '',
    },
    externalId: defaultOrder?.project?.externalId || null,
    material: defaultOrder?.material || null,
    equipmentType: defaultOrder?.equipmentType || null,
    service: defaultOrder?.service || null,
    quantity: defaultOrder?.quantity || '',
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
          },
        }
      : null,
    allContacts: defaultOrder?.allContacts?.length
      ? defaultOrder.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    phase: {
      id: defaultOrder?.phase?.id || '',
      name: defaultOrder?.phase?.name || '',
      code: defaultOrder?.phase?.code || '',
    },
    phaseCode: defaultOrder?.phase?.code || '',
    customerRate: defaultOrder?.customerRate?.id?.length
      ? ({
          id: defaultOrder?.customerRate?.id || '',
          name: defaultOrder?.customerRate?.name || '',
          type: defaultOrder?.customerRate?.type || '',
          rate: defaultOrder?.customerRate?.rate || '',
        } as unknown as RateBasicWithValue)
      : null,
    //@ts-ignore
    customerRateValue: defaultOrder?.customerRate?.rate || null,
    customerRateType: defaultOrder?.customerRate?.readableTypeValue || null,
    department: defaultOrder?.department || {},
    foremen: defaultOrder?.foremen || [],
    haulerRateType: defaultOrder?.haulerRateType,
  };
};
export type OrderFormSchemaInterface = yup.InferType<typeof orderFormSchema>;
