import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { AgaveLinkedAccount } from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import * as colsDef from '~pages/Settings/Integrations/quickBooksDataGridColumnsDefinition';
import { routes } from '~router';
import { useStores } from '~store';

const LINKED_ACCOUNT_ID_PARAM = 'linked_account_id';
enum QuickBooksTab {
  Customer = 'customer',
  Vendors = 'vendors',
  Items = 'items',
  Ledger = 'ledger',
}

const TAB_COLUMNS_MAP: Record<QuickBooksTab, GridColDef[]> = {
  [QuickBooksTab.Customer]: [
    colsDef.customerNameColDef,
    colsDef.customerAddressColDef,
    colsDef.customerStatusColDef,
    colsDef.customerTreadNameColDef,
  ],
  [QuickBooksTab.Vendors]: [
    colsDef.vendorNameColDef,
    colsDef.vendorAddressColDef,
    colsDef.vendorStatusColDef,
    colsDef.vendorTreadNameColDef,
  ],
  [QuickBooksTab.Items]: [
    colsDef.itemNameColDef,
    colsDef.itemTypeColDef,
    colsDef.itemStatusColDef,
    colsDef.itemUnitPriceColDef,
    colsDef.itemUnitCostColDef,
    colsDef.itemServiceColDef,
    colsDef.itemAddOnColDef,
  ],
  [QuickBooksTab.Ledger]: [
    colsDef.ledgerAccountNameColDef,
    colsDef.ledgerAccountTypeColDef,
    colsDef.ledgerAccountStatusColDef,
    colsDef.ledgerAccountColDef,
  ],
};

const QUICK_BOOKS_TAB_FILTERS = [
  {
    label: t('administration.integration.agave.quick_books_online.tabs.customer'),
    value: QuickBooksTab.Customer,
  },
  {
    label: t('administration.integration.agave.quick_books_online.tabs.vendors'),
    value: QuickBooksTab.Vendors,
  },
  {
    label: t('administration.integration.agave.quick_books_online.tabs.items'),
    value: QuickBooksTab.Items,
  },
  {
    label: t('administration.integration.agave.quick_books_online.tabs.ledger'),
    value: QuickBooksTab.Ledger,
  },
];

const AgaveQuickBooksGrid = observer(() => {
  const theme = useTheme();

  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<QuickBooksTab>(QuickBooksTab.Customer);
  const [linkedAccount, setLinkedAccount] = useState<AgaveLinkedAccount | null>(null);

  const {
    getCustomers,
    enqueueImport,
    getLinkedAccounts,
    getVendors,
    getItems,
    getLedgerAccounts,
  } = useAgave();
  const { agaveIntegrationQuickBooksStore } = useStores();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const linkedAccountId = searchParams.get(LINKED_ACCOUNT_ID_PARAM);

    const fetchData = async () => {
      if (!linkedAccountId) {
        return;
      }

      const response = await getLinkedAccounts({ limit: 10, page: 1 });
      const linkedAccount = response?.data?.find((la) => la.id === linkedAccountId);
      if (linkedAccount) {
        setLinkedAccount(linkedAccount);
      }
    };
    fetchData();
  }, [location.search]);

  const reloadData = useCallback(async () => {
    if (!linkedAccount?.id) {
      return;
    }

    try {
      setFetchingData(true);

      // load customers
      const customers = await getCustomers(
        linkedAccount.id,
        agaveIntegrationQuickBooksStore.pagination,
      );
      agaveIntegrationQuickBooksStore.setCustomers(customers?.data || []);

      // load vendors
      const vendors = await getVendors(
        linkedAccount.id,
        agaveIntegrationQuickBooksStore.pagination,
      );
      agaveIntegrationQuickBooksStore.setVendors(vendors?.data || []);

      // load items
      const items = await getItems(
        linkedAccount.id,
        agaveIntegrationQuickBooksStore.pagination,
      );
      agaveIntegrationQuickBooksStore.setItems(items?.data || []);

      // load ledger
      const ledger = await getLedgerAccounts(
        linkedAccount.id,
        agaveIntegrationQuickBooksStore.pagination,
      );
      agaveIntegrationQuickBooksStore.setLedgerAccounts(ledger?.data || []);
    } catch (error) {
      // TODO: Show inline error
    } finally {
      setFetchingData(false);
    }
  }, [linkedAccount?.id, agaveIntegrationQuickBooksStore.pagination]);

  const importData = useCallback(async () => {
    if (!linkedAccount?.id) {
      return;
    }

    setFetchingData(true);

    await enqueueImport(linkedAccount.id);
    reloadData();

    setFetchingData(false);
  }, [linkedAccount?.id, reloadData]);

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  const rows = useMemo(() => {
    let filteredRows: any[] = [];
    switch (selectedTab) {
      case QuickBooksTab.Customer:
        filteredRows = agaveIntegrationQuickBooksStore.customers.map((row) => ({
          id: row.id,
          account: row,
          linkedAccountId: linkedAccount?.id,
        }));
        break;
      case QuickBooksTab.Vendors:
        filteredRows = agaveIntegrationQuickBooksStore.vendors.map((row) => ({
          id: row.id,
          vendor: row,
          linkedAccountId: linkedAccount?.id,
        }));
        break;
      case QuickBooksTab.Items:
        filteredRows = agaveIntegrationQuickBooksStore.items.map((row) => ({
          id: row.id,
          item: row,
          linkedAccountId: linkedAccount?.id,
        }));
        break;
      case QuickBooksTab.Ledger:
        filteredRows = agaveIntegrationQuickBooksStore.ledgerAccounts.map((row) => ({
          id: row.id,
          ledgerAccount: row,
          linkedAccountId: linkedAccount?.id,
        }));
        break;
      default:
        filteredRows = [];
    }
    return filteredRows;
  }, [
    agaveIntegrationQuickBooksStore.customers,
    agaveIntegrationQuickBooksStore.vendors,
    agaveIntegrationQuickBooksStore.items,
    agaveIntegrationQuickBooksStore.ledgerAccounts,
    selectedTab,
    linkedAccount?.id,
  ]);

  return (
    <Box maxWidth="1000px">
      <Button
        color="secondary"
        component={Link}
        startIcon={<ArrowBackIosNew fontSize="small" />}
        to={`/${routes.settings.base}/${routes.settings.integrations.base}/${routes.settings.integrations.agave.base}`}
        variant="text"
      >
        {t('administration.integration.agave.quick_books_online.back_button')}
      </Button>

      <CardContent
        sx={{
          paddingX: 0,
          paddingBottom: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">
            {t(`administration.integration.agave.quick_books_online.name`)}
          </Typography>
        </Box>
      </CardContent>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <StatusTabFilters
          value={selectedTab}
          onChange={setSelectedTab}
          filters={QUICK_BOOKS_TAB_FILTERS}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: '6px', display: 'flex', alignItems: 'center' }}>
            {fetchingData && (
              <Box sx={{ mr: '8px' }}>
                <LoadingSpinner isVisible={true} loadingIndicatorSize={16} />
              </Box>
            )}
            <Typography variant="body1" color={theme.brandV2.colors.treadGray3}>
              {fetchingData
                ? t(
                    'administration.integration.agave.quick_books_online.last_sync_loading',
                  )
                : t('administration.integration.agave.quick_books_online.last_sync', {
                    time: linkedAccount?.synchronizedAt
                      ? dayjs(linkedAccount.synchronizedAt).format(
                          "h:mm A ddd MMM-DD 'YY",
                        )
                      : '',
                    interpolation: { escapeValue: false },
                  })}
            </Typography>
          </Box>
          <Button
            color="primary"
            disabled={fetchingData}
            onClick={importData}
            size="medium"
          >
            {t('administration.integration.agave.quick_books_online.refresh_button')}
          </Button>
        </Box>
      </Box>

      {selectedTab === QuickBooksTab.Ledger && (
        <Alert variant="outlined" severity="info" sx={{ my: 2 }}>
          {t(
            'administration.integration.agave.quick_books_online.ledger_selection_instructions',
          )}
        </Alert>
      )}

      <DataGrid
        id="agave-management-grid"
        columns={TAB_COLUMNS_MAP[selectedTab]}
        hideToolbar={true}
        disableColumnFilter
        hideQuickFilter
        loading={fetchingData}
        rows={rows}
        sx={{
          '& .MuiDataGrid-root': {
            padding: '0px',
          },
        }}
      />
    </Box>
  );
});

export default AgaveQuickBooksGrid;
