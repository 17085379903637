import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SvgIcon from '@mui/icons-material/CancelOutlined';
import Check from '@mui/icons-material/Check';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { createElement, useEffect, useMemo, useState } from 'react';

import { Job, JobTimeline as JobTimelineClass, useJob } from '~hooks/useJob';
import theme from '~theme/AppTheme';

import {
  EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT,
  EventTimeline,
  EventTimelineConnector,
  EventTimelineContent,
  EventTimelineContentContainer,
  EventTimelineDot,
  EventTimelineDotProps,
  EventTimelineIcon,
  EventTimelineItem,
  EventTimelinePrimaryText,
  EventTimelineSecondaryText,
  EventTimelineSeparator,
} from '../EventTimeline/EventTimeline';

export const LOAD_IN_PROGRESS_JOB_STATES = [
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.LOAD_COMPLETED,
];

type ObjectChangeEntry = [unknown, unknown];

enum JobTimelineEventType {
  LOAD_CHANGE = 'load_change',
  STATE_CHANGE = 'state_change',
}

type JobTimelineEntry = { type: JobTimelineEventType; timeline: JobTimelineClass };

interface JobTimelineComponentProps {
  isExpanded?: boolean;
  isGrouped?: boolean;
  isLast?: boolean;
  loadNumber?: number;
  onExpand?: () => void;
  timeline: JobTimelineClass;
}

const componentsByJobTimelineEventType: Record<
  JobTimelineEventType,
  React.JSXElementConstructor<JobTimelineComponentProps>
> = {
  [JobTimelineEventType.LOAD_CHANGE]: LoadChangeTimelineItem,
  [JobTimelineEventType.STATE_CHANGE]: StateChangeTimelineItem,
};

const iconsByJobState: Partial<
  Record<JobState | 'unassigned', { icon: typeof SvgIcon; color: string }>
> = {
  [JobState.CANCELED]: { icon: CancelOutlined, color: theme.brandV2.colors.treadRed },
  [JobState.REJECTED]: { icon: CancelOutlined, color: theme.brandV2.colors.treadRed },
};

const guessChangeType = (changes: {
  [key: string]: ObjectChangeEntry;
}): JobTimelineEventType | null => {
  const [previousId, currentId] = changes.id ?? [null, null];
  const [previousState, currentState] = changes.state ?? [null, null];

  if (!previousId && currentId) {
    return JobTimelineEventType.STATE_CHANGE;
  }

  if (previousState && currentState === JobState.CREATED) {
    return JobTimelineEventType.STATE_CHANGE;
  }

  if (previousState !== currentState) {
    if (currentState && LOAD_IN_PROGRESS_JOB_STATES.includes(currentState as JobState)) {
      return JobTimelineEventType.LOAD_CHANGE;
    }

    return JobTimelineEventType.STATE_CHANGE;
  }

  return null;
};

const groupEvents = (timeline: JobTimelineClass[]) => {
  const prunedTimeline = timeline
    .map((item) => ({ type: guessChangeType(item.changes), timeline: item }))
    .filter(
      (item): item is { type: JobTimelineEventType; timeline: JobTimelineClass } => {
        return item.type !== null;
      },
    );

  let currentLoadIndex = 0;
  const groupedTimeline = prunedTimeline.reduce(
    (acc, event) => {
      if (event.type === JobTimelineEventType.STATE_CHANGE) {
        acc.push({
          createdAt: event.timeline.createdAt,
          type: event.type,
          entries: event,
        });

        return acc;
      }

      const state = event.timeline.changes['state']?.[1];
      const isToPickupChange = state === JobState.TO_PICKUP;

      if (isToPickupChange) {
        currentLoadIndex = acc.length;
        acc.push({
          createdAt: event.timeline.createdAt,
          type: event.type,
          entries: [event],
        });
      } else if (Array.isArray(acc[currentLoadIndex].entries)) {
        (acc[currentLoadIndex].entries as JobTimelineEntry[]).push(event);
      }

      return acc;
    },
    [] as Array<{
      createdAt: Dayjs;
      type: JobTimelineEventType;
      entries: JobTimelineEntry | JobTimelineEntry[];
    }>,
  );

  return groupedTimeline.reverse().map((item) => {
    if (Array.isArray(item.entries)) {
      item.entries.reverse();
    }

    return { type: item.type, entries: item.entries };
  });
};

interface JobTimelineProps {
  job: Job;
  sx?: SxProps;
}

export default function JobTimeline({ job, sx }: JobTimelineProps) {
  const { getJobTimeline } = useJob();
  const [timeline, setTimeline] = useState<JobTimelineClass[]>([]);
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const events = useMemo(() => groupEvents(timeline), [timeline]);
  let loadNumber = events.filter((event) => Array.isArray(event.entries)).length + 1;

  useEffect(() => {
    getJobTimeline(job.id).then((timeline) => {
      setTimeline(timeline);
    });
  }, [job.id, job.status]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <EventTimeline>
        {events.map((item, index) => {
          const itemIsAGroupOfLoadEvents = Array.isArray(item.entries);

          if (itemIsAGroupOfLoadEvents) {
            const entries = item.entries as JobTimelineEntry[];
            loadNumber = loadNumber - 1;

            return entries.map((entry, entryIndex) => {
              const isFirst = entryIndex === 0;
              const isExpanded = expandedIndexes.includes(index);
              const isExpansible = Boolean(isFirst && entries.length > 1);

              if (isFirst || isExpanded) {
                return createElement(componentsByJobTimelineEventType[item.type], {
                  key: `${index}-${entryIndex}`,
                  isExpanded: isExpanded,
                  isGrouped: entryIndex !== 0,
                  isLast: index === events.length - 1,
                  loadNumber,
                  timeline: entry.timeline,
                  onExpand: isExpansible
                    ? () => {
                        setExpandedIndexes((currentExpandedIndexes) => {
                          const newCurrentExpandedIndexes = [...currentExpandedIndexes];
                          const indexPositionInList = newCurrentExpandedIndexes.findIndex(
                            (i) => i === index,
                          );

                          if (indexPositionInList > -1) {
                            newCurrentExpandedIndexes.splice(indexPositionInList, 1);
                          } else {
                            newCurrentExpandedIndexes.push(index);
                          }

                          return newCurrentExpandedIndexes;
                        });
                      }
                    : undefined,
                });
              }

              return null;
            });
          }

          const entry = item.entries as JobTimelineEntry;

          return createElement(componentsByJobTimelineEventType[item.type], {
            key: index,
            isLast: index === events.length - 1,
            timeline: entry.timeline,
          });
        })}
      </EventTimeline>
    </Box>
  );
}

function StateChangeTimelineItem({ isLast, timeline }: JobTimelineComponentProps) {
  const state = useMemo(() => {
    const [previousId, currentId] = timeline.changes.id ?? [null, null];
    const [previousState, currentState] = timeline.changes.state ?? [null, null];
    const [previousDriver, currentDriver] = timeline.changes.driver_id ?? [null, null];

    if (!previousId && currentId) {
      return JobState.CREATED;
    }

    if (!previousDriver && currentDriver) {
      return JobState.ASSIGNED;
    }

    if (previousState && currentState === JobState.CREATED) {
      return 'unassigned';
    }

    return currentState as JobState;
  }, [timeline.changes]);
  const icon = iconsByJobState[state];
  const dotProps = useMemo(() => {
    const props: EventTimelineDotProps = {
      shape: 'dot',
      innerColor: theme.brandV2.colors.treadGreen,
    };

    if ([JobState.CREATED, JobState.COMPLETED].includes(state as JobState)) {
      props.shape = 'icon';
    } else if ([JobState.CANCELED, JobState.REJECTED].includes(state as JobState)) {
      props.innerColor = theme.brandV2.colors.treadRed;
    } else if (state === 'unassigned') {
      props.shape = 'square';
      props.innerColor = theme.brandV2.colors.treadRed;
    }

    return props;
  }, [state]);
  const isCreated = state === JobState.CREATED;
  const isCompleted = state === JobState.COMPLETED;

  return (
    <EventTimelineItem>
      <EventTimelineSeparator>
        <EventTimelineDot innerColor={dotProps.innerColor} shape={dotProps.shape}>
          {isCreated && (
            <EventTimelineIcon
              icon={FiberManualRecord}
              sx={{ color: dotProps.innerColor, width: '8px', height: '8px' }}
            />
          )}

          {isCompleted && (
            <EventTimelineIcon
              icon={Check}
              sx={{ color: dotProps.innerColor, width: '12px', height: '12px' }}
            />
          )}
        </EventTimelineDot>

        {!isLast && <EventTimelineConnector />}
      </EventTimelineSeparator>

      <EventTimelineContentContainer hasDotIconAtLeft={isCreated || isCompleted}>
        <EventTimelineContent
          date={timeline.createdAt}
          sx={{ ...(isCreated || isCompleted ? { pt: '4px' } : {}) }}
        >
          <EventTimelinePrimaryText sx={{ color: icon?.color }}>
            {icon &&
              createElement(icon.icon, {
                sx: { color: icon.color, fontSize: '16px', mt: '-2px', mr: 0.25 },
              })}

            {t(`job_events.${state}`)}
          </EventTimelinePrimaryText>

          <EventTimelineSecondaryText>
            {timeline.createdByName}
          </EventTimelineSecondaryText>
        </EventTimelineContent>
      </EventTimelineContentContainer>
    </EventTimelineItem>
  );
}

function LoadChangeTimelineItem({
  isExpanded,
  isGrouped,
  isLast,
  loadNumber,
  onExpand,
  timeline,
}: JobTimelineComponentProps) {
  const state = useMemo(() => {
    const [previousId, currentId] = timeline.changes.id ?? [null, null];
    const [previousState, currentState] = timeline.changes.state ?? [null, null];
    const [previousDriver, currentDriver] = timeline.changes.driver_id ?? [null, null];

    if (!previousId && currentId) {
      return JobState.CREATED;
    }

    if (!previousDriver && currentDriver) {
      return JobState.ASSIGNED;
    }

    if (previousState === JobState.ASSIGNED && currentState === JobState.CREATED) {
      return 'unassigned';
    }

    return currentState as JobState;
  }, [timeline.changes]);
  const isLoadCompleted = state === JobState.LOAD_COMPLETED;
  const icon = iconsByJobState[state];
  const dotProps = useMemo(() => {
    const props: EventTimelineDotProps = {
      shape: 'icon',
      innerColor: theme.brandV2.colors.treadGray7,
    };

    if (isGrouped && isLast) {
      props.shape = 'dot';
      props.innerColor = theme.brandV2.colors.treadGray7;
    } else if (isGrouped) {
      props.shape = 'empty';
    }

    return props;
  }, [isGrouped, isLast]);

  return (
    <EventTimelineItem>
      <EventTimelineSeparator>
        <EventTimelineDot innerColor={dotProps.innerColor} shape={dotProps.shape}>
          {!isGrouped && (
            <EventTimelineIcon
              icon={ExpandMoreOutlined}
              onClick={onExpand}
              sx={{
                cursor: 'pointer',
                transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform .2s linear',
              }}
            />
          )}
        </EventTimelineDot>

        {!isLast && <EventTimelineConnector />}
      </EventTimelineSeparator>

      <EventTimelineContentContainer
        hasDotIconAtLeft={!isGrouped}
        sx={{ ...(isGrouped ? { pl: '18px' } : {}) }}
      >
        <EventTimelineContent
          {...(isGrouped
            ? { date: timeline.createdAt }
            : { label: `${t('status.load')} ${loadNumber}`, sx: { pt: '4px' } })}
        >
          {isGrouped ? (
            <>
              <EventTimelinePrimaryText sx={{ color: icon?.color }}>
                {icon &&
                  createElement(icon.icon, {
                    sx: { color: icon.color, fontSize: '16px', mt: '-2px', mr: 0.25 },
                  })}

                {t(`job_status.${state}`)}
              </EventTimelinePrimaryText>
              <EventTimelineSecondaryText>
                {timeline.createdByName}
              </EventTimelineSecondaryText>
            </>
          ) : (
            <EventTimelinePrimaryText>
              {isLoadCompleted ? t('status.delivered') : t(`job_status.${state}`)}
              <EventTimelineSecondaryText component="span">{` • ${dayjs.tz(timeline.createdAt).format(EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT)}`}</EventTimelineSecondaryText>
            </EventTimelinePrimaryText>
          )}
        </EventTimelineContent>
      </EventTimelineContentContainer>
    </EventTimelineItem>
  );
}
